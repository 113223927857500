
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Dropdown, Button, Card, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as XLSX from 'xlsx';
import config from '../../components/config/config';
import { logActivity } from '../ActivityLogs/ActivityLogger';


const AddStudent = () => {
  const [file, setFile] = useState(null);
  const [branches, setBranches] = useState([]);
  const [userBranches, setUserBranches] = useState([]); // Branches available to the user
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [user, setUser] = useState(null);
  const [showProfile, setShowProfile] = useState(false);
  const [profilePicture, setProfilePicture] = useState(null); // State for the file
  const [isBranchesLoaded, setIsBranchesLoaded] = useState(false);
  const [studentData, setStudentData] = useState({
    currentCountry: '',
    registrationNo: '',
    name: '',
    facebookName: '',
    nationality: '',
    age: '',
    gender: '',
    interestedJob: '',
    classAttendRecord: '',
    counseling: '',
    counselingDate: '',
    admissionDate: '',
    graduationDate: '',
    visaStatus: '',
    visaExpireDate: '',
    educationalBackgroundHome: '',
    educationalBackgroundJapan: '',
    collegeName: '',
    mobileNumber: '',
    japaneseTestLevel: '',
    englishTestLevel: '',
    othersLicenses: '',
    academicTranscriptCheck: '',
    nearestCity: '',
    desiredCountry: '',
    desiredSalary: '',
    experienceDetails: '',
    classAttendDay: '',
    counsellorStaff: '',
    referrerPerson: '',
    emergencyContact: '',
    jobOfferDate: '',
    jobOfferCompany: '',
    teacherSalesStaff: '',
    studentDocForVisa: '',
    lawyerName: '',
    lawyerRefDate: '',
    visaApplyDate: '',
    visaResultDate: '',
    reapplyDate: '',
    wizAcademyGradDate: '',
    japanEntryDate: '',
    companyEntryDate: '',
    admissionFee: '',
    tuitionFee: '',
    tuitionPaymentDate: '',
    dueTuitionFee: '',
    duePaymentDate: '',
    dropoutDate: '',
    refundFee: '',
    dropoutReason: '',
    snsReviewMarketing: '',
    residenceCardReceiveDate: '',
    visaExpiredDate: '',
    others: '',
    lawyerFeeFromCompany: '',
    lawyerFeePaidDate: '',
    invoiceDate: '',
    depositDate: '',
    payeeName: '',
    dueInvoiceDate: '',
    dueDepositDate: '',
    duePayeeName: '',
    
    branch: '',
    profilePicture: null,
    
  });



  const [alert, setAlert] = useState({ show: false, message: '', type: '' });

  const handleChange = (e) => {
    setStudentData({ ...studentData, [e.target.name]: e.target.value,});
  };

  
  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await fetch(`${config.API_BASE_URL}/api/branch`);
        if (!response.ok) throw new Error('Failed to fetch branches');
        const data = await response.json();
        setBranches(data);
        setIsBranchesLoaded(true); // Set branches loaded to true
        console.log('Branches fetched:', data); // Debugging
      } catch (error) {
        console.error('Error fetching branches:', error);
      }
    };

    fetchBranches();
  }, []);

  // Fetch user details using JWT token
  useEffect(() => {
    const fetchUser = async () => {
      try {
        if (!isBranchesLoaded) return; // Wait until branches are loaded

        const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
        if (!token) {
          console.error('No token found'); // Debugging
          // Redirect to login if token is missing
          return;
        }

        const response = await fetch(`${config.API_BASE_URL}/api/profile`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          const errorMessage = await response.text(); // Get the error message from the response
          throw new Error(`Failed to fetch user data: ${response.status} - ${errorMessage}`);
        }

        const data = await response.json();
        setUser(data);
        console.log('User fetched:', data); // Debugging

        // Determine branches available to the user based on role
        const assignedBranch = branches.find(branch => branch._id === data.branchId);
        if (data.role === 'Admin') {
          setUserBranches(branches); // Admin sees all branches
        } else {
          setUserBranches(assignedBranch ? [assignedBranch] : []); // Non-admin sees only their branch
        }

        // Set the selected branch to the user's branch
        setSelectedBranch(assignedBranch);

        console.log('User branches available:', userBranches); // Debugging
      } catch (error) {
        console.error('Error fetching user data:', error);
        // Redirect to login on error
      }
    };

    fetchUser();
  }, [isBranchesLoaded, branches]);

  const handleBranchChange = (e) => {
    const selectedBranchId = e.target.value; // Get the branch _id from the dropdown value
    setSelectedBranch(selectedBranchId); // Update the selected branch state
    setStudentData({ ...studentData, branch: selectedBranchId }); // Set the branch _id in studentData
  };
  

  // const handleBranchChange = (branch) => {
  //   setSelectedBranch(branch);
  // };



  // const handleFileChange = (e) => {
  //   const inputName = e.target.name;
  //   if (inputName === 'excelImport') {
  //     setFile(e.target.files[0]);
  //   }
  // };


// const handleProfilePictureChange = (e) => {
//   setStudentData({
//     ...studentData,
//     profilePicture: e.target.files[0], // Get the first file from input
//   }); // Store the profile picture file
// };


// const handleFileChange = (e) => {
//   setFile(e.target.files[0]); // Store the Excel file
// };
  const handleFileChange = (e) => {
    const inputName = e.target.name;
    if (inputName === 'profilePicture') {
      setStudentData({
    ...studentData,
    profilePicture: e.target.files[0], // Get the first file from input
  }); // Store the file itself
    } else if (inputName === 'excelImport') {
      setFile(e.target.files[0]);
    }
  };
  const handleSubmit = async (e) => {
  e.preventDefault(); // Prevent default form submission
  const formData = new FormData();

  // Append all student data to FormData


  Object.keys(studentData).forEach(key => {
  // Skip appending profilePicture if it exists
  if (key === 'profilePicture') {
    return;
  }
  formData.append(key, studentData[key]);
});

// Append profile picture if it exists
if (studentData.profilePicture) {
console.log("File exist");
 console.log("File exists:", studentData.profilePicture); // Log the file object
  formData.append('profilePicture', studentData.profilePicture); // This ensures it's appended only once
}
else{
console.log("It doesnt");}
  // Object.keys(studentData).forEach(key => {
  //   formData.append(key, studentData[key]);
  // });

  // // Append profile picture
  // if (studentData.profilePicture) {
  //   formData.append('profilePicture', studentData.profilePicture); // Ensure it's being appended
  // }

  // Now perform the fetch as described before
  try {
    const response = await fetch('http://localhost:5001/api/students', {
      method: 'POST',
      body: formData,
      headers: {
        'Accept': 'application/json', // Expecting JSON response
      },
    });

    // Error handling and response parsing as shown earlier...
  } catch (error) {
    console.error('Error:', error);
    setAlert({ show: true, message: error.message || 'Failed to submit student data.', type: 'danger' });
  }
};

  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   const formData = new FormData();
  //   for (const key in studentData) {
  //     formData.append(key, studentData[key]);
  //   }

  //  formData.append('profilePicture', studentData.profilePicture); 

  //   try {
  //     const response = await fetch('http://localhost:5001/api/students', {
  //       method: 'POST',
  //       body: formData,
  //     });

  //     if (!response.ok) {
  //       throw new Error('Network response was not ok');
  //     }

  //     const result = await response.json();
  //     console.log(result);
  //     setAlert({ show: true, message: 'Student data submitted successfully!', type: 'success' });
  //     await logActivity(`${user.username}`, 'Add', `Added student: ${studentData.name}`);
  //     setStudentData({ /* reset to initial state */ });
      
  //   } catch (error) {
  //     console.error('Error:', error);
  //     setAlert({ show: true, message: 'Failed to submit student data.', type: 'danger' });
  //   }
  // };
  const handleImport = async () => {
    if (!file) {
      setAlert({ show: true, message: 'Please select a file to import.', type: 'danger' });
      return;
    }

    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);
    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];
    const jsonData = XLSX.utils.sheet_to_json(sheet);

    // Here we convert jsonData into a FormData object for sending to the server
    const formData = new FormData();
    formData.append('students', JSON.stringify(jsonData)); // Pass the JSON data as a string

    try {
      const response = await fetch('http://localhost:5001/api/students/import', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to import data.');
      }

      setAlert({ show: true, message: 'Students imported successfully!', type: 'success' });
    } catch (error) {
      console.error(error);
      setAlert({ show: true, message: 'Error importing students.', type: 'danger' });
    }
  };
  

 
  return (
    <Container className="my-5">
      <Card>
        <Card.Body>
          <Card.Title className="text-center mb-4">Admission Form</Card.Title>

          {alert.show && (
            <Alert variant={alert.type} onClose={() => setAlert({ ...alert, show: false })} dismissible>
              {alert.message}
            </Alert>
          )}

          <Form onSubmit={handleSubmit}>
            <Row className="mb-4">
              <Col xs={12}>
                <h5 className="text-primary">Student Information</h5>
              </Col>

              <Row className="mb-4">
              <Col xs={12}>
                <Form.Group>
                  <Form.Label>Import Students from Excel</Form.Label>
                  <Form.Control type="file" name="excelImport" accept=".xlsx, .xls" onChange={handleFileChange} />
                  <Button variant="secondary" onClick={handleImport} className="mt-2">Import</Button>
                </Form.Group>
              </Col>
            </Row>
              {/* Student Information Fields */}
              <Col xs={12} sm={6}>
                <Form.Group controlId="formCurrentCountry">
                  <Form.Label>Current Stay Country Name</Form.Label>
                  <Form.Control type="text" name="currentCountry" value={studentData.currentCountry} onChange={handleChange} required />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formRegistrationNo">
                  <Form.Label>Registration No</Form.Label>
                  <Form.Control type="text" name="registrationNo" value={studentData.registrationNo} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" name="name" value={studentData.name} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formFacebookName">
                  <Form.Label>Facebook Name</Form.Label>
                  <Form.Control type="text" name="facebookName" value={studentData.facebookName} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formNationality">
                  <Form.Label>Nationality</Form.Label>
                  <Form.Control type="text" name="nationality" value={studentData.nationality} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formAge">
                  <Form.Label>Age</Form.Label>
                  <Form.Control type="number" name="age" value={studentData.age} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formGender">
                  <Form.Label>Gender</Form.Label>
                  <Form.Control type="text" name="gender" value={studentData.gender} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formInterestedJob">
                  <Form.Label>Interested Job</Form.Label>
                  <Form.Control type="text" name="interestedJob" value={studentData.interestedJob} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formClassAttendRecord">
                  <Form.Label>Class Attend Record</Form.Label>
                  <Form.Control type="text" name="classAttendRecord" value={studentData.classAttendRecord} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formCounseling">
                  <Form.Label>Counseling (Face to face / Online)</Form.Label>
                  <Form.Control type="text" name="counseling" value={studentData.counseling} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formCounselingDate">
                  <Form.Label>Counseling Date</Form.Label>
                  <Form.Control type="date" name="counselingDate" value={studentData.counselingDate} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formAdmissionDate">
                  <Form.Label>Admission Date</Form.Label>
                  <Form.Control type="date" name="admissionDate" value={studentData.admissionDate} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formGraduationDate">
                  <Form.Label>Graduation Date</Form.Label>
                  <Form.Control type="date" name="graduationDate" value={studentData.graduationDate} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formVisaStatus">
                  <Form.Label>Visa Status</Form.Label>
                  <Form.Control type="text" name="visaStatus" value={studentData.visaStatus} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formVisaExpireDate">
                  <Form.Label>Visa Expire Date</Form.Label>
                  <Form.Control type="date" name="visaExpireDate" value={studentData.visaExpireDate} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formEducationalBackgroundHome">
                  <Form.Label>Educational Background from Home Country</Form.Label>
                  <Form.Control type="text" name="educationalBackgroundHome" value={studentData.educationalBackgroundHome} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formEducationalBackgroundJapan">
                  <Form.Label>Educational Background from Japan</Form.Label>
                  <Form.Control type="text" name="educationalBackgroundJapan" value={studentData.educationalBackgroundJapan} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formCollegeName">
                  <Form.Label>Current/Graduated College Name</Form.Label>
                  <Form.Control type="text" name="collegeName" value={studentData.collegeName} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formMobileNumber">
                  <Form.Label>Mobile Number</Form.Label>
                  <Form.Control type="text" name="mobileNumber" value={studentData.mobileNumber} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formJapaneseTestLevel">
                  <Form.Label>Japanese Language Test Level</Form.Label>
                  <Form.Control type="text" name="japaneseTestLevel" value={studentData.japaneseTestLevel} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formEnglishTestLevel">
                  <Form.Label>English Test Level</Form.Label>
                  <Form.Control type="text" name="englishTestLevel" value={studentData.englishTestLevel} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formOthersLicenses">
                  <Form.Label>Others Licenses</Form.Label>
                  <Form.Control type="text" name="othersLicenses" value={studentData.othersLicenses} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formAcademicTranscriptCheck">
                  <Form.Label>Academic Transcript Check</Form.Label>
                  <Form.Control type="text" name="academicTranscriptCheck" value={studentData.academicTranscriptCheck} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formNearestCity">
                  <Form.Label>Nearest City/Station</Form.Label>
                  <Form.Control type="text" name="nearestCity" value={studentData.nearestCity} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formDesiredCountry">
                  <Form.Label>Desired Country/Location</Form.Label>
                  <Form.Control type="text" name="desiredCountry" value={studentData.desiredCountry} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formDesiredSalary">
                  <Form.Label>Desired Salary</Form.Label>
                  <Form.Control type="text" name="desiredSalary" value={studentData.desiredSalary} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formExperienceDetails">
                  <Form.Label>Experience Details</Form.Label>
                  <Form.Control type="text" name="experienceDetails" value={studentData.experienceDetails} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formClassAttendDay">
                  <Form.Label>Class Attend Day</Form.Label>
                  <Form.Control type="text" name="classAttendDay" value={studentData.classAttendDay} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formCounsellorStaff">
                  <Form.Label>Counsellor Staff</Form.Label>
                  <Form.Control type="text" name="counsellorStaff" value={studentData.counsellorStaff} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formReferrerPerson">
                  <Form.Label>Referrer Person</Form.Label>
                  <Form.Control type="text" name="referrerPerson" value={studentData.referrerPerson} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formEmergencyContact">
                  <Form.Label>Emergency Contact Number and Name</Form.Label>
                  <Form.Control type="text" name="emergencyContact" value={studentData.emergencyContact} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formJobOfferDate">
                  <Form.Label>Job Offer Date</Form.Label>
                  <Form.Control type="date" name="jobOfferDate" value={studentData.jobOfferDate} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formJobOfferCompany">
                  <Form.Label>Job Offer Company Name</Form.Label>
                  <Form.Control type="text" name="jobOfferCompany" value={studentData.jobOfferCompany} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formTeacherSalesStaff">
                  <Form.Label>Teacher/Sales Staff</Form.Label>
                  <Form.Control type="text" name="teacherSalesStaff" value={studentData.teacherSalesStaff} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formStudentDocForVisa">
                  <Form.Label>Student Document for Visa Apply</Form.Label>
                  <Form.Control type="text" name="studentDocForVisa" value={studentData.studentDocForVisa} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formLawyerName">
                  <Form.Label>Lawyer Name</Form.Label>
                  <Form.Control type="text" name="lawyerName" value={studentData.lawyerName} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formLawyerRefDate">
                  <Form.Label>Lawyer Refer Date</Form.Label>
                  <Form.Control type="date" name="lawyerRefDate" value={studentData.lawyerRefDate} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formVisaApplyDate">
                  <Form.Label>Visa Apply Date</Form.Label>
                  <Form.Control type="date" name="visaApplyDate" value={studentData.visaApplyDate} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formVisaResultDate">
                  <Form.Label>Visa Result Date (Approval/Reject)</Form.Label>
                  <Form.Control type="date" name="visaResultDate" value={studentData.visaResultDate} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formReapplyDate">
                  <Form.Label>Re-apply Date</Form.Label>
                  <Form.Control type="date" name="reapplyDate" value={studentData.reapplyDate} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formWizAcademyGradDate">
                  <Form.Label>Wiz Academy Graduation Date</Form.Label>
                  <Form.Control type="date" name="wizAcademyGradDate" value={studentData.wizAcademyGradDate} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formJapanEntryDate">
                  <Form.Label>Japan Entry Date</Form.Label>
                  <Form.Control type="date" name="japanEntryDate" value={studentData.japanEntryDate} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formCompanyEntryDate">
                  <Form.Label>Company Entry Date</Form.Label>
                  <Form.Control type="date" name="companyEntryDate" value={studentData.companyEntryDate} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formAdmissionFee">
                  <Form.Label>Admission Fee</Form.Label>
                  <Form.Control type="number" name="admissionFee" value={studentData.admissionFee} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formTuitionFee">
                  <Form.Label>Tuition Fee</Form.Label>
                  <Form.Control type="number" name="tuitionFee" value={studentData.tuitionFee} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formTuitionPaymentDate">
                  <Form.Label>Tuition Fee Payment Date</Form.Label>
                  <Form.Control type="date" name="tuitionPaymentDate" value={studentData.tuitionPaymentDate} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formDueTuitionFee">
                  <Form.Label>Due Tuition Fee</Form.Label>
                  <Form.Control type="number" name="dueTuitionFee" value={studentData.dueTuitionFee} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formDuePaymentDate">
                  <Form.Label>Due Tuition Fee Payment Date</Form.Label>
                  <Form.Control type="date" name="duePaymentDate" value={studentData.duePaymentDate} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formDropoutDate">
                  <Form.Label>Dropout Date</Form.Label>
                  <Form.Control type="date" name="dropoutDate" value={studentData.dropoutDate} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formRefundFee">
                  <Form.Label>Refund Fee</Form.Label>
                  <Form.Control type="number" name="refundFee" value={studentData.refundFee} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formDropoutReason">
                  <Form.Label>Dropout Reason</Form.Label>
                  <Form.Control type="text" name="dropoutReason" value={studentData.dropoutReason} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formSnsReviewMarketing">
                  <Form.Label>SNS Review/Marketing</Form.Label>
                  <Form.Control type="text" name="snsReviewMarketing" value={studentData.snsReviewMarketing} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formResidenceCardReceiveDate">
                  <Form.Label>Residence Card Receive Date</Form.Label>
                  <Form.Control type="date" name="residenceCardReceiveDate" value={studentData.residenceCardReceiveDate} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formVisaExpiredDate">
                  <Form.Label>Visa Expired Date</Form.Label>
                  <Form.Control type="date" name="visaExpiredDate" value={studentData.visaExpiredDate} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formOthers">
                  <Form.Label>Others</Form.Label>
                  <Form.Control type="text" name="others" value={studentData.others} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formLawyerFeeFromCompany">
                  <Form.Label>Lawyer Fee From Company</Form.Label>
                  <Form.Control type="number" name="lawyerFeeFromCompany" value={studentData.lawyerFeeFromCompany} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formLawyerFeePaidDate">
                  <Form.Label>Lawyer Fee Paid Date From Company</Form.Label>
                  <Form.Control type="date" name="lawyerFeePaidDate" value={studentData.lawyerFeePaidDate} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formInvoiceDate">
                  <Form.Label>Invoice Date</Form.Label>
                  <Form.Control type="date" name="invoiceDate" value={studentData.invoiceDate} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formDepositDate">
                  <Form.Label>Deposit Date From Company</Form.Label>
                  <Form.Control type="date" name="depositDate" value={studentData.depositDate} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formPayeeName">
                  <Form.Label>Payee Name</Form.Label>
                  <Form.Control type="text" name="payeeName" value={studentData.payeeName} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formDueInvoiceDate">
                  <Form.Label>Due Invoice Date</Form.Label>
                  <Form.Control type="date" name="dueInvoiceDate" value={studentData.dueInvoiceDate} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formDueDepositDate">
                  <Form.Label>Due Deposit Date From Company</Form.Label>
                  <Form.Control type="date" name="dueDepositDate" value={studentData.dueDepositDate} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Col xs={12} sm={6}>
                <Form.Group controlId="formDuePayeeName">
                  <Form.Label>Due Payee Name</Form.Label>
                  <Form.Control type="text" name="duePayeeName" value={studentData.duePayeeName} onChange={handleChange} />
                </Form.Group>
              </Col>
              <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="formProfilePicture">
                <Form.Label>Profile Picture</Form.Label>
                <Form.Control
                  type="file"
                  name = "profilePicture"
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </Form.Group>
            </Row>

              <Form.Group className="mb-3">
  <Form.Label>Select Branch</Form.Label>
  <Form.Select value={selectedBranch ? selectedBranch : ''} onChange={handleBranchChange}>
    <option value="">Select Branch</option>
    {userBranches.length > 0 ? (
      userBranches.map((branch) => (
        <option key={branch._id} value={branch._id}>
          {branch.name}
        </option>
      ))
    ) : (
      <option disabled>No branches available</option>
    )}
  </Form.Select>
</Form.Group>


           
              
              <Col xs={12} className="text-center">
                <Button variant="primary" type="submit">Submit</Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default AddStudent;


// import React, { useState } from 'react';
// import { Container, Row, Col, Form, Button, Card, Alert } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';

// const AddStudent = () => {
//   const [studentData, setStudentData] = useState({
//     name: '',
//     picture: '',
//     registrationNo: '',
//     admissionDate: '',
//     selectedClass: '',
//     discount: '',
//     mobileNo: '',
//     dob: '',
//     gender: '',
//     idMark: '',
//     bloodGroup: '',
//     disease: '',
//     birthId: '',
//     cast: '',
//     prevSchool: '',
//     prevId: '',
//     additionalNote: '',
//     orphan: '',
//     osc: '',
//     religion: '',
//     family: '',
//     siblings: '',
//     address: '',
//     fatherName: '',
//     fatherEducation: '',
//     fatherId: '',
//     fatherMobile: '',
//     fatherOccupation: '',
//     fatherProfession: '',
//     fatherIncome: '',
//     motherName: '',
//     motherEducation: '',
//     motherId: '',
//     motherMobile: '',
//     motherOccupation: '',
//     motherProfession: '',
//     motherIncome: ''
//   });

//   const [alert, setAlert] = useState({ show: false, message: '', type: '' });

//   const handleChange = (e) => {
//     setStudentData({ ...studentData, [e.target.name]: e.target.value });
//   };

//   const handleFileChange = (e) => {
//     setStudentData({ ...studentData, picture: e.target.files[0] });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     // Create a FormData object to handle file uploads
//     const formData = new FormData();
//     for (const key in studentData) {
//       formData.append(key, studentData[key]);
//     }

//     try {
//       const response = await fetch('https://control.novelkoirala.com:5001/api/students', {
//         method: 'POST',
//         body: formData
//       });

//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }

//       const result = await response.json();
//       console.log(result);
//       setAlert({ show: true, message: 'Student data submitted successfully!', type: 'success' });
//       setStudentData({
//         name: '',
//         picture: '',
//         registrationNo: '',
//         admissionDate: '',
//         selectedClass: '',
//         discount: '',
//         mobileNo: '',
//         dob: '',
//         gender: '',
//         idMark: '',
//         bloodGroup: '',
//         disease: '',
//         birthId: '',
//         cast: '',
//         prevSchool: '',
//         prevId: '',
//         additionalNote: '',
//         orphan: '',
//         osc: '',
//         religion: '',
//         family: '',
//         siblings: '',
//         address: '',
//         fatherName: '',
//         fatherEducation: '',
//         fatherId: '',
//         fatherMobile: '',
//         fatherOccupation: '',
//         fatherProfession: '',
//         fatherIncome: '',
//         motherName: '',
//         motherEducation: '',
//         motherId: '',
//         motherMobile: '',
//         motherOccupation: '',
//         motherProfession: '',
//         motherIncome: ''
//       });
      
//     } catch (error) {
//       console.error('Error:', error);
//       setAlert({ show: true, message: 'Failed to submit student data.', type: 'danger' });
//     }
//   };

//   return (
//     <Container className="my-5">
//       <Card>
//         <Card.Body>
//           <Card.Title className="text-center mb-4">Admission Form</Card.Title>

//           {alert.show && <Alert variant={alert.type} onClose={() => setAlert({ ...alert, show: false })} dismissible>
//             {alert.message}
//           </Alert>}

//           <Form onSubmit={handleSubmit}>
//             <Row className="mb-4">
//               {/* Student Information */}
//               <Col xs={12}>
//                 <h5 className="text-primary">Student Information</h5>
//               </Col>
//               <Col xs={12} sm={6}>
//                 <Form.Group controlId="formName">
//                   <Form.Label>Student Name*</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="name"
//                     value={studentData.name}
//                     onChange={handleChange}
//                     required
//                   />
//                 </Form.Group>
//               </Col>
//               <Col xs={12} sm={6}>
//                 <Form.Group controlId="formPicture">
//                   <Form.Label>Picture (Optional)</Form.Label>
//                   <Form.Control
//                     type="file"
//                     name="picture"
//                     onChange={handleFileChange}
//                   />
//                 </Form.Group>
//               </Col>
//               <Col xs={12} sm={6}>
//                 <Form.Group controlId="formRegistrationNo">
//                   <Form.Label>Registration No</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="registrationNo"
//                     value={studentData.registrationNo}
//                     onChange={handleChange}
//                   />
//                 </Form.Group>
//               </Col>
//               <Col xs={12} sm={6}>
//                 <Form.Group controlId="formAdmissionDate">
//                   <Form.Label>Date of Admission*</Form.Label>
//                   <Form.Control
//                     type="date"
//                     name="admissionDate"
//                     value={studentData.admissionDate}
//                     onChange={handleChange}
//                     required
//                   />
//                 </Form.Group>
//               </Col>
//               <Col xs={12} sm={6}>
//                 <Form.Group controlId="formSelectedClass">
//                   <Form.Label>Select Class*</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="selectedClass"
//                     value={studentData.selectedClass}
//                     onChange={handleChange}
//                     required
//                   />
//                 </Form.Group>
//               </Col>
//               <Col xs={12} sm={6}>
//                 <Form.Group controlId="formDiscount">
//                   <Form.Label>Discount In Fee*</Form.Label>
//                   <Form.Control
//                     type="number"
//                     name="discount"
//                     value={studentData.discount}
//                     onChange={handleChange}
//                     required
//                   />
//                 </Form.Group>
//               </Col>
//               <Col xs={12} sm={6}>
//                 <Form.Group controlId="formMobileNo">
//                   <Form.Label>Mobile No. for SMS/WhatsApp</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="mobileNo"
//                     value={studentData.mobileNo}
//                     onChange={handleChange}
//                   />
//                 </Form.Group>
//               </Col>

//               {/* Other Information */}
//               <Col xs={12}>
//                 <h5 className="text-primary">Other Information</h5>
//               </Col>
//               <Col xs={12} sm={6}>
//                 <Form.Group controlId="formDob">
//                   <Form.Label>Date Of Birth</Form.Label>
//                   <Form.Control
//                     type="date"
//                     name="dob"
//                     value={studentData.dob}
//                     onChange={handleChange}
//                   />
//                 </Form.Group>
//               </Col>
//               <Col xs={12} sm={6}>
//                 <Form.Group controlId="formGender">
//                   <Form.Label>Gender</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="gender"
//                     value={studentData.gender}
//                     onChange={handleChange}
//                   />
//                 </Form.Group>
//               </Col>
//               <Col xs={12} sm={6}>
//                 <Form.Group controlId="formIdMark">
//                   <Form.Label>Any Identification Mark?</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="idMark"
//                     value={studentData.idMark}
//                     onChange={handleChange}
//                   />
//                 </Form.Group>
//               </Col>
//               <Col xs={12} sm={6}>
//                 <Form.Group controlId="formBloodGroup">
//                   <Form.Label>Blood Group</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="bloodGroup"
//                     value={studentData.bloodGroup}
//                     onChange={handleChange}
//                   />
//                 </Form.Group>
//               </Col>
//               <Col xs={12} sm={6}>
//                 <Form.Group controlId="formDisease">
//                   <Form.Label>Disease If Any?</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="disease"
//                     value={studentData.disease}
//                     onChange={handleChange}
//                   />
//                 </Form.Group>
//               </Col>
//               <Col xs={12} sm={6}>
//                 <Form.Group controlId="formBirthId">
//                   <Form.Label>Student Birth Form ID / NIC</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="birthId"
//                     value={studentData.birthId}
//                     onChange={handleChange}
//                   />
//                 </Form.Group>
//               </Col>
//               <Col xs={12} sm={6}>
//                 <Form.Group controlId="formCast">
//                   <Form.Label>Cast</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="cast"
//                     value={studentData.cast}
//                     onChange={handleChange}
//                   />
//                 </Form.Group>
//               </Col>
//               <Col xs={12} sm={6}>
//                 <Form.Group controlId="formPrevSchool">
//                   <Form.Label>Previous School</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="prevSchool"
//                     value={studentData.prevSchool}
//                     onChange={handleChange}
//                   />
//                 </Form.Group>
//               </Col>
//               <Col xs={12} sm={6}>
//                 <Form.Group controlId="formPrevId">
//                   <Form.Label>Previous ID / Board Roll No</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="prevId"
//                     value={studentData.prevId}
//                     onChange={handleChange}
//                   />
//                 </Form.Group>
//               </Col>
//               <Col xs={12}>
//                 <Form.Group controlId="formAdditionalNote">
//                   <Form.Label>Any Additional Note</Form.Label>
//                   <Form.Control
//                     as="textarea"
//                     rows={4}
//                     name="additionalNote"
//                     value={studentData.additionalNote}
//                     onChange={handleChange}
//                   />
//                 </Form.Group>
//               </Col>
//               <Col xs={12} sm={6}>
//                 <Form.Group controlId="formOrphan">
//                   <Form.Label>Orphan Student</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="orphan"
//                     value={studentData.orphan}
//                     onChange={handleChange}
//                   />
//                 </Form.Group>
//               </Col>
//               <Col xs={12} sm={6}>
//                 <Form.Group controlId="formOsc">
//                   <Form.Label>OSC</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="osc"
//                     value={studentData.osc}
//                     onChange={handleChange}
//                   />
//                 </Form.Group>
//               </Col>
//               <Col xs={12} sm={6}>
//                 <Form.Group controlId="formReligion">
//                   <Form.Label>Religion</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="religion"
//                     value={studentData.religion}
//                     onChange={handleChange}
//                   />
//                 </Form.Group>
//               </Col>
//               <Col xs={12} sm={6}>
//                 <Form.Group controlId="formFamily">
//                   <Form.Label>Family Background</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="family"
//                     value={studentData.family}
//                     onChange={handleChange}
//                   />
//                 </Form.Group>
//               </Col>
//               <Col xs={12} sm={6}>
//                 <Form.Group controlId="formSiblings">
//                   <Form.Label>Number of Siblings</Form.Label>
//                   <Form.Control
//                     type="number"
//                     name="siblings"
//                     value={studentData.siblings}
//                     onChange={handleChange}
//                   />
//                 </Form.Group>
//               </Col>
//               <Col xs={12} sm={6}>
//                 <Form.Group controlId="formAddress">
//                   <Form.Label>Address</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="address"
//                     value={studentData.address}
//                     onChange={handleChange}
//                   />
//                 </Form.Group>
//               </Col>

//               {/* Father’s Information */}
//               <Col xs={12}>
//                 <h5 className="text-primary">Father's Information</h5>
//               </Col>
//               <Col xs={12} sm={6}>
//                 <Form.Group controlId="formFatherName">
//                   <Form.Label>Father’s Name</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="fatherName"
//                     value={studentData.fatherName}
//                     onChange={handleChange}
//                   />
//                 </Form.Group>
//               </Col>
//               <Col xs={12} sm={6}>
//                 <Form.Group controlId="formFatherEducation">
//                   <Form.Label>Father’s Education</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="fatherEducation"
//                     value={studentData.fatherEducation}
//                     onChange={handleChange}
//                   />
//                 </Form.Group>
//               </Col>
//               <Col xs={12} sm={6}>
//                 <Form.Group controlId="formFatherId">
//                   <Form.Label>Father’s ID</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="fatherId"
//                     value={studentData.fatherId}
//                     onChange={handleChange}
//                   />
//                 </Form.Group>
//               </Col>
//               <Col xs={12} sm={6}>
//                 <Form.Group controlId="formFatherMobile">
//                   <Form.Label>Father’s Mobile No.</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="fatherMobile"
//                     value={studentData.fatherMobile}
//                     onChange={handleChange}
//                   />
//                 </Form.Group>
//               </Col>
//               <Col xs={12} sm={6}>
//                 <Form.Group controlId="formFatherOccupation">
//                   <Form.Label>Father’s Occupation</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="fatherOccupation"
//                     value={studentData.fatherOccupation}
//                     onChange={handleChange}
//                   />
//                 </Form.Group>
//               </Col>
//               <Col xs={12} sm={6}>
//                 <Form.Group controlId="formFatherProfession">
//                   <Form.Label>Father’s Profession</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="fatherProfession"
//                     value={studentData.fatherProfession}
//                     onChange={handleChange}
//                   />
//                 </Form.Group>
//               </Col>
//               <Col xs={12} sm={6}>
//                 <Form.Group controlId="formFatherIncome">
//                   <Form.Label>Father’s Income</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="fatherIncome"
//                     value={studentData.fatherIncome}
//                     onChange={handleChange}
//                   />
//                 </Form.Group>
//               </Col>

//               {/* Mother’s Information */}
//               <Col xs={12}>
//                 <h5 className="text-primary">Mother's Information</h5>
//               </Col>
//               <Col xs={12} sm={6}>
//                 <Form.Group controlId="formMotherName">
//                   <Form.Label>Mother’s Name</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="motherName"
//                     value={studentData.motherName}
//                     onChange={handleChange}
//                   />
//                 </Form.Group>
//               </Col>
//               <Col xs={12} sm={6}>
//                 <Form.Group controlId="formMotherEducation">
//                   <Form.Label>Mother’s Education</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="motherEducation"
//                     value={studentData.motherEducation}
//                     onChange={handleChange}
//                   />
//                 </Form.Group>
//               </Col>
//               <Col xs={12} sm={6}>
//                 <Form.Group controlId="formMotherId">
//                   <Form.Label>Mother’s ID</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="motherId"
//                     value={studentData.motherId}
//                     onChange={handleChange}
//                   />
//                 </Form.Group>
//               </Col>
//               <Col xs={12} sm={6}>
//                 <Form.Group controlId="formMotherMobile">
//                   <Form.Label>Mother’s Mobile No.</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="motherMobile"
//                     value={studentData.motherMobile}
//                     onChange={handleChange}
//                   />
//                 </Form.Group>
//               </Col>
//               <Col xs={12} sm={6}>
//                 <Form.Group controlId="formMotherOccupation">
//                   <Form.Label>Mother’s Occupation</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="motherOccupation"
//                     value={studentData.motherOccupation}
//                     onChange={handleChange}
//                   />
//                 </Form.Group>
//               </Col>
//               <Col xs={12} sm={6}>
//                 <Form.Group controlId="formMotherProfession">
//                   <Form.Label>Mother’s Profession</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="motherProfession"
//                     value={studentData.motherProfession}
//                     onChange={handleChange}
//                   />
//                 </Form.Group>
//               </Col>
//               <Col xs={12} sm={6}>
//                 <Form.Group controlId="formMotherIncome">
//                   <Form.Label>Mother’s Income</Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="motherIncome"
//                     value={studentData.motherIncome}
//                     onChange={handleChange}
//                   />
//                 </Form.Group>
//               </Col>

//               <Col xs={12} className="text-center">
//                 <Button variant="primary" type="submit">Submit</Button>
//               </Col>
//             </Row>
//           </Form>
//         </Card.Body>
//       </Card>
//     </Container>
//   );
// };

// export default AddStudent;
