import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Button, Table, Container, Row, Col, Alert, Form, InputGroup } from 'react-bootstrap';
import config from '../../components/config/config'

const JobPostingManagement = () => {
  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [search, setSearch] = useState('');
  const [statusFilter, setStatusFilter] = useState('All');
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await axios.get('https://control.novelkoirala.com:5001/jobs');
        setJobs(response.data);
        setFilteredJobs(response.data);
      } catch (error) {
        setError('Failed to fetch jobs');
      }
    };

    fetchJobs();
  }, []);

  useEffect(() => {
    const filtered = jobs.filter(job => {
      return (
        (statusFilter === 'All' || job.status === statusFilter) &&
        (job.title.toLowerCase().includes(search.toLowerCase()) ||
         job.location.toLowerCase().includes(search.toLowerCase()))
      );
    });
    setFilteredJobs(filtered);
  }, [search, statusFilter, jobs]);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${config.API_BASE_URL}/jobs/${id}`);
      setJobs(jobs.filter(job => job._id !== id));
    } catch (error) {
      setError('Failed to delete job');
    }
  };

  return (
    <Container className="my-4">
      <Row>
        <Col>
          <h1 className="mb-4">Job Postings</h1>
          {error && <Alert variant="danger">{error}</Alert>}
          
          <Row className="mb-4">
            <Col md={8}>
              <InputGroup>
                <Form.Control
                  type="text"
                  placeholder="Search by title or location"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </InputGroup>
            </Col>
            <Col md={4}>
              <Form.Select
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}
              >
                <option value="All">All Statuses</option>
                <option value="Active">Active</option>
                <option value="Closed">Closed</option>
                <option value="Pending">Pending</option>
              </Form.Select>
            </Col>
          </Row>
          
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Title</th>
                <th>Status</th>
                <th>Location</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredJobs.map(job => (
                <tr key={job._id}>
                  <td>{job.title}</td>
                  <td>{job.status}</td>
                  <td>{job.location}</td>
                  <td>
                    <Link to={`/edit-job/${job._id}`} className="btn btn-warning btn-sm me-2">Edit</Link>
                    <Button variant="danger" size="sm" onClick={() => handleDelete(job._id)}>Delete</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          
          <Link to="/job/create-job" className="btn btn-primary">Add New Job</Link>
        </Col>
      </Row>
    </Container>
  );
};

export default JobPostingManagement;






// import React, { useState, useEffect } from 'react';
// import { Container, Row, Col, Form, Button, Table, Modal } from 'react-bootstrap';

// const JobPostingManagement = () => {
//   // Sample data for job postings
//   const sampleJobPostings = [
//     {
//       id: 1,
//       title: 'Software Engineer',
//       status: 'Active',
//       location: 'New York',
//     },
//     {
//       id: 2,
//       title: 'Marketing Specialist',
//       status: 'Pending',
//       location: 'San Francisco',
//     },
//     {
//       id: 3,
//       title: 'Project Manager',
//       status: 'Closed',
//       location: 'Remote',
//     },
//   ];

//   // Sample data for applications
//   const sampleApplications = {
//     1: [
//       {
//         id: 1,
//         name: 'John Doe',
//         status: 'Received',
//         resume: '#',
//         coverLetter: '#',
//       },
//       {
//         id: 2,
//         name: 'Jane Smith',
//         status: 'Under Review',
//         resume: '#',
//         coverLetter: '#',
//       },
//     ],
//     2: [
//       {
//         id: 3,
//         name: 'Emily Johnson',
//         status: 'Interview Scheduled',
//         resume: '#',
//         coverLetter: '#',
//       },
//     ],
//     3: [
//       {
//         id: 4,
//         name: 'Michael Brown',
//         status: 'Rejected',
//         resume: '#',
//         coverLetter: '#',
//       },
//     ],
//   };

//   const [jobPostings, setJobPostings] = useState(sampleJobPostings);
//   const [filteredPostings, setFilteredPostings] = useState(sampleJobPostings);
//   const [selectedJob, setSelectedJob] = useState(null);
//   const [showEditModal, setShowEditModal] = useState(false);
//   const [showApplicationsModal, setShowApplicationsModal] = useState(false);
//   const [applications, setApplications] = useState([]);
//   const [search, setSearch] = useState('');
//   const [status, setStatus] = useState('');

//   const handleEditClick = (job) => {
//     setSelectedJob(job);
//     setShowEditModal(true);
//   };

//   const handleApplicationsClick = (job) => {
//     setSelectedJob(job);
//     setApplications(sampleApplications[job.id] || []);
//     setShowApplicationsModal(true);
//   };

//   const handleApplicationStatusChange = (e, application) => {
//     const { value } = e.target;
//     setApplications(applications.map(app => app.id === application.id ? { ...app, status: value } : app));
//   };

//   const handleSaveApplicationStatus = (application) => {
//     setApplications(applications.map(app => app.id === application.id ? { ...app, status: application.status } : app));
//   };

//   const handleSearch = () => {
//     setFilteredPostings(
//       jobPostings.filter(
//         (job) =>
//           (search === '' || job.title.toLowerCase().includes(search.toLowerCase())) &&
//           (status === '' || job.status === status)
//       )
//     );
//   };

//   return (
//     <Container fluid className="mt-4">
//       <Row className="mb-3">
//         <Col>
//           <h2 className="text-center">Job Posting Management</h2>
//         </Col>
//       </Row>

//       {/* Search and Filter */}
//       <Row className="mb-4">
//         <Col>
//           <Form>
//             <Form.Group controlId="formSearch">
//               <Form.Label>Search by Job Title</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter job title"
//                 value={search}
//                 onChange={(e) => setSearch(e.target.value)}
//               />
//             </Form.Group>
//             <Form.Group controlId="formStatus">
//               <Form.Label>Job Status</Form.Label>
//               <Form.Control
//                 as="select"
//                 value={status}
//                 onChange={(e) => setStatus(e.target.value)}
//               >
//                 <option value="">All Statuses</option>
//                 <option>Active</option>
//                 <option>Closed</option>
//                 <option>Pending</option>
//               </Form.Control>
//             </Form.Group>
//             <Button variant="primary" onClick={handleSearch}>
//               Search
//             </Button>
//           </Form>
//         </Col>
//       </Row>

//       {/* Job Postings Table */}
//       <Table striped bordered hover>
//         <thead>
//           <tr>
//             <th>Job Title</th>
//             <th>Status</th>
//             <th>Location</th>
//             <th>Actions</th>
//           </tr>
//         </thead>
//         <tbody>
//           {filteredPostings.map((job) => (
//             <tr key={job.id}>
//               <td>{job.title}</td>
//               <td>{job.status}</td>
//               <td>{job.location}</td>
//               <td>
//                 <Button variant="primary" onClick={() => handleEditClick(job)}>
//                   Edit
//                 </Button>
//                 <Button
//                   variant="info"
//                   className="ml-2"
//                   onClick={() => handleApplicationsClick(job)}
//                 >
//                   Applications
//                 </Button>
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>

//       {/* Applications Modal */}
//       <Modal show={showApplicationsModal} onHide={() => setShowApplicationsModal(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>Applications for {selectedJob?.title}</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Table striped bordered hover>
//             <thead>
//               <tr>
//                 <th>Applicant Name</th>
//                 <th>Status</th>
//                 <th>Resume</th>
//                 <th>Cover Letter</th>
//                 <th>Update Status</th>
//               </tr>
//             </thead>
//             <tbody>
//               {applications.map((application) => (
//                 <tr key={application.id}>
//                   <td>{application.name}</td>
//                   <td>{application.status}</td>
//                   <td>
//                     <a href={application.resume} target="_blank" rel="noreferrer">
//                       View Resume
//                     </a>
//                   </td>
//                   <td>
//                     <a href={application.coverLetter} target="_blank" rel="noreferrer">
//                       View Cover Letter
//                     </a>
//                   </td>
//                   <td>
//                     <Form.Control
//                       as="select"
//                       value={application.status}
//                       onChange={(e) => handleApplicationStatusChange(e, application)}
//                     >
//                       <option value="Received">Received</option>
//                       <option value="Under Review">Under Review</option>
//                       <option value="Interview Scheduled">Interview Scheduled</option>
//                       <option value="Rejected">Rejected</option>
//                       <option value="Hired">Hired</option>
//                     </Form.Control>
//                     <Button variant="primary" onClick={() => handleSaveApplicationStatus(application)}>
//                       Save
//                     </Button>
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </Table>
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={() => setShowApplicationsModal(false)}>
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>

//       {/* Edit Job Modal */}
//       {selectedJob && (
//         <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
//           <Modal.Header closeButton>
//             <Modal.Title>Edit Job Posting</Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             <Form>
//               <Form.Group controlId="formJobTitle">
//                 <Form.Label>Job Title</Form.Label>
//                 <Form.Control type="text" name="title" value={selectedJob.title} readOnly />
//               </Form.Group>
//               <Form.Group controlId="formLocation">
//                 <Form.Label>Location</Form.Label>
//                 <Form.Control type="text" name="location" value={selectedJob.location} readOnly />
//               </Form.Group>
//               <Form.Group controlId="formStatus">
//                 <Form.Label>Status</Form.Label>
//                 <Form.Control as="select" name="status" value={selectedJob.status}>
//                   <option>Active</option>
//                   <option>Pending</option>
//                   <option>Closed</option>
//                 </Form.Control>
//               </Form.Group>
//             </Form>
//           </Modal.Body>
//           <Modal.Footer>
//             <Button variant="secondary" onClick={() => setShowEditModal(false)}>
//               Close
//             </Button>
//             <Button variant="primary">Save Changes</Button>
//           </Modal.Footer>
//         </Modal>
//       )}
//     </Container>
//   );
// };

// export default JobPostingManagement;
