import React, { useState, useEffect } from 'react';
import { Container, Table, Form, Button, Row, Col, Card, Alert } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from 'axios';

// Base URL for API
const API_BASE_URL = 'https://control.novelkoirala.com:5001/api/blog-posts';

const BlogManagement = () => {
  const [blogs, setBlogs] = useState([]);
  const [editingBlog, setEditingBlog] = useState(null);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  // Fetch blogs from the backend
  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(API_BASE_URL);
        setBlogs(response.data);
      } catch (err) {
        setError('Failed to fetch blog posts. Please try again.');
      }
    };

    fetchBlogs();
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${API_BASE_URL}/${id}`);
      setBlogs(blogs.filter((blog) => blog._id !== id));
      setSuccess('Blog post deleted successfully!');
    } catch (err) {
      setError('Failed to delete blog post. Please try again.');
    }
  };

  const handleEdit = (blog) => {
    setEditingBlog(blog);
  };

  const handleEditSubmit = async (updatedBlog) => {
    try {
      await axios.put(`${API_BASE_URL}/${updatedBlog._id}`, updatedBlog);
      setBlogs(blogs.map((blog) => (blog._id === updatedBlog._id ? updatedBlog : blog)));
      setEditingBlog(null);
      setSuccess('Blog post updated successfully!');
    } catch (err) {
      setError('Failed to update blog post. Please try again.');
    }
  };

  return (
    <Container fluid className="mt-4" style={{ fontFamily: 'Roboto, sans-serif' }}>
      <Card className="p-4 shadow-sm">
        <h2 className="text-center text-primary mb-4">Blog Management</h2>
        {error && (
          <Row className="mb-3">
            <Col>
              <Alert variant="danger">{error}</Alert>
            </Col>
          </Row>
        )}
        {success && (
          <Row className="mb-3">
            <Col>
              <Alert variant="success">{success}</Alert>
            </Col>
          </Row>
        )}
        <Table striped bordered hover>
          <thead className="text-info">
            <tr>
              <th>Title</th>
              <th>Author</th>
              <th>Category</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {blogs.map((blog) => (
              <tr key={blog._id}>
                <td>{blog.title}</td>
                <td>{blog.author}</td>
                <td>{blog.category}</td>
                <td>
                  <Button variant="outline-primary" onClick={() => handleEdit(blog)} className="me-2">
                    Edit
                  </Button>
                  <Button variant="outline-danger" onClick={() => handleDelete(blog._id)}>
                    Delete
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        {editingBlog && (
          <EditBlogPost blogData={editingBlog} onSubmit={handleEditSubmit} />
        )}
      </Card>
    </Container>
  );
};

const EditBlogPost = ({ blogData, onSubmit }) => {
  const [blog, setBlog] = useState(blogData);

  const handleChange = (e) => {
    setBlog({ ...blog, [e.target.name]: e.target.value });
  };

  const handleContentChange = (content) => {
    setBlog({ ...blog, content });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await onSubmit(blog);
  };

  return (
    <Card className="p-4 mt-4 shadow-sm">
      <h4 className="text-center text-info">Edit Blog Post</h4>
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="formEditBlogTitle">
              <Form.Label className="text-info">Title</Form.Label>
              <Form.Control
                type="text"
                name="title"
                value={blog.title}
                onChange={handleChange}
                placeholder="Enter blog title"
                required
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="formEditBlogAuthor">
              <Form.Label className="text-info">Author</Form.Label>
              <Form.Control
                type="text"
                name="author"
                value={blog.author}
                onChange={handleChange}
                placeholder="Enter author name"
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={6}>
            <Form.Group controlId="formEditBlogCategory">
              <Form.Label className="text-info">Category</Form.Label>
              <Form.Control
                type="text"
                name="category"
                value={blog.category}
                onChange={handleChange}
                placeholder="Enter blog category"
                required
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={12}>
            <Form.Group controlId="formEditBlogContent">
              <Form.Label className="text-info">Content</Form.Label>
              <ReactQuill
                value={blog.content}
                onChange={handleContentChange}
                theme="snow"
                placeholder="Edit your blog content here..."
                style={{ height: '200px' }}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col>
            <Button type="submit" variant="primary" className="w-100">
              Update Blog Post
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default BlogManagement;
