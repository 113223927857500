import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useTable, useGlobalFilter, useSortBy } from 'react-table';
import { Container, Row, Col, Table, Button, Alert, Spinner } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import ViewDetailsModal from './ViewDetailsModal';
import AddVisitorModal from './AddVisitorModal';
import { FaEye, FaPlus, FaEdit, FaTrash, FaSync } from 'react-icons/fa';
import axios from 'axios';
import { useBranch } from '../Context/BranchContext';
import GlobalFilter from './GlobalFilter';
import config from '../../components/config/config'; // Import the GlobalFilter component

// API Functions
const fetchVisitors = async () => {
  try {
    const response = await axios.get(`${config.API_BASE_URL}/visitors`); // Make sure the URL is correct
    return response.data;
  } catch (error) {
    throw error;
  }
};

const addVisitor = async (visitor) => {
  try {
    const response = await axios.post(`${config.API_BASE_URL}:5001/visitors`, visitor);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const updateVisitor = async (visitor) => {
  try {
    const response = await axios.put(`${config.API_BASE_URL}/visitors/${visitor._id}`, visitor);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const deleteVisitor = async (_id) => {
  await axios.delete(`${config.API_BASE_URL}/visitors/${_id}`);
};

const VisitorDataTable = () => {
  const { selectedBranch } = useBranch(); // Get current branch from context
  const [successMessage, setSuccessMessage] = useState('');
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isFiltering, setIsFiltering] = useState(true);
  const [selectedVisitor, setSelectedVisitor] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentVisitor, setCurrentVisitor] = useState(null);
  const [error, setError] = useState(null);

  // Fetch initial data
  const loadData = useCallback(async () => {
    setIsFiltering(true); // Start filtering
    try {
      const visitors = await fetchVisitors();
      setData(visitors);
      setError(null);
    } catch (error) {
      setError('Failed to fetch visitors. Please try again later.');
    }
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  // Filter visitors based on the selected branch
  useEffect(() => {
    if (data.length > 0) {
      setIsFiltering(true);
      const filtered = selectedBranch
        ? data.filter(visitor => visitor.branchId === selectedBranch._id)
        : data;
      setFilteredData(filtered);
      setIsFiltering(false);
    }
  }, [data, selectedBranch]);

  const handleViewDetails = useCallback((visitor) => {
    setSelectedVisitor(visitor);
    setShowDetails(true);
  }, []);

  const handleEditVisitor = useCallback((visitor) => {
    setCurrentVisitor(visitor);
    setShowEditModal(true);
  }, []);

  const handleDeleteVisitor = useCallback(async (_id) => {
    try {
      await deleteVisitor(_id);
      setData(prevData => prevData.filter(visitor => visitor._id !== _id));
      setError(null);
    } catch (error) {
      setError('Failed to delete visitor. Please try again later.');
    }
  }, []);

  const handleAddVisitor = useCallback(async (visitor) => {
    try {
      const newVisitor = await addVisitor(visitor);
      setData(prevData => [...prevData, newVisitor]);
      setError(null);
    } catch (error) {
      setError('Failed to add visitor. Please try again later.');
    }
  }, []);

  const handleEditVisitorSubmit = useCallback(async (visitor) => {
    try {
      const updatedVisitor = await updateVisitor(visitor);
      setData(prevData => prevData.map(v => (v._id === updatedVisitor._id ? updatedVisitor : v)));
      setError(null);
    } catch (error) {
      setError('Failed to update visitor. Please try again later.');
    }
  }, []);

  const columns = useMemo(
    () => [
      { Header: 'Name', accessor: 'name' },
      { Header: 'Purpose of Visit', accessor: 'purposeOfVisit' },
      { Header: 'Time In', accessor: 'timeIn' },
      { Header: 'Time Out', accessor: 'timeOut' },
      { Header: 'Source', accessor: 'source' },
      {
        Header: 'Actions',
        accessor: 'actions',
        Cell: ({ row }) => (
          <div>
            <Button variant="info" onClick={() => handleViewDetails(row.original)} className="me-2">
              <FaEye /> View
            </Button>
            <Button variant="warning" onClick={() => handleEditVisitor(row.original)} className="me-2">
              <FaEdit /> Edit
            </Button>
            <Button variant="danger" onClick={() => handleDeleteVisitor(row.original._id)}>
              <FaTrash /> Delete
            </Button>
          </div>
        ),
      },
    ],
    [handleViewDetails, handleEditVisitor, handleDeleteVisitor]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { globalFilter },
    setGlobalFilter,
  } = useTable(
    { columns, data: filteredData }, // Use filtered data
    useGlobalFilter,
    useSortBy
  );

  return (
    <Container fluid className="mt-4">
      <Row className="mb-3">
        <Col>
          <h2 className="text-center text-primary">Visitor Log</h2>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={6}>
          <GlobalFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
        </Col>
        <Col md={6} className="text-end">
          <Button variant="success" onClick={() => setShowAddModal(true)}>
            <FaPlus /> Add Visitor
          </Button>
          <Button variant="secondary" onClick={loadData} className="ms-2">
            <FaSync /> Reload
          </Button>
          <CSVLink data={filteredData} className="btn btn-primary ms-2">
            Download CSV
          </CSVLink>
        </Col>
      </Row>

      {error && (
        <Row className="mb-3">
          <Col>
            <Alert variant="danger">{error}</Alert>
          </Col>
        </Row>
      )}

      {isFiltering ? (
        <Row>
          <Col className="text-center">
            <Spinner animation="border" />
            <div>Loading data...</div>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col>
            <Table {...getTableProps()} bordered hover responsive>
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                        {column.render('Header')}
                        <span>
                          {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : '' }
                        </span>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => (
                        <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      )}

      {selectedVisitor && (
        <ViewDetailsModal
          show={showDetails}
          handleClose={() => setShowDetails(false)}
          visitor={selectedVisitor}
        />
      )}

      <AddVisitorModal
        show={showAddModal}
        handleClose={() => setShowAddModal(false)}
        handleAddVisitor={handleAddVisitor}
      />

      {currentVisitor && (
        <AddVisitorModal
          show={showEditModal}
          handleClose={() => setShowEditModal(false)}
          handleAddVisitor={handleEditVisitorSubmit}
          visitor={currentVisitor}
        />
      )}
    </Container>
  );
};

export default VisitorDataTable;
