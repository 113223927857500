import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col, Card, Spinner, Alert, FormControl, InputGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Edit as EditIcon, Delete as DeleteIcon, Visibility as VisibilityIcon } from '@mui/icons-material';
import { useBranch } from '../Context/BranchContext'; // Import the useBranch hook
import 'bootstrap/dist/css/bootstrap.min.css';
import config from '../../components/config/config';

const ViewStudents = () => {
  const { currentBranchId } = useBranch(); // Get the current branch ID from the context
  const [students, setStudents] = useState([]);
  const [filteredStudents, setFilteredStudents] = useState([]);
  const [searchQuery, setSearchQuery] = useState(''); // State for search input
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({ show: false, message: '', type: '' });

  // Fetch students whenever the current branch ID changes
  useEffect(() => {
    const fetchStudentsByBranch = async () => {
      setLoading(true);
      console.log('Fetching students for branch ID:', currentBranchId); // Debugging log
 
      // Reset alert when branch ID changes
      if (currentBranchId) {
        setAlert({ show: false, message: '', type: '' });
      }

      // If no branch ID, show an alert and return
      if (!currentBranchId) {
        setLoading(false); // Stop loading if no branch ID is available
        setAlert({ show: true, message: 'No branch selected.', type: 'warning' }); // Notify user
        return; // Exit if branch ID is not available
      }

      try {
        const response = await fetch(`http://localhost:5001/api/students/branch/${currentBranchId}`);

        // Check for response status
        if (!response.ok) {
          if (response.status === 404) {
            setAlert({ show: true, message: 'No students found for this branch.', type: 'info' });
            setStudents([]); // Clear students if no students found
          } else {
            throw new Error(`HTTP error! Status: ${response.status}`); // Error handling for non-200 responses
          }
        } else {
          const data = await response.json();
          console.log('Students data fetched:', data); // Debugging log
          setStudents(data);
          setFilteredStudents(data); // Initialize filtered students with full data
        }
      } catch (error) {
        console.error('Error fetching students:', error); // Log error details
        setAlert({ show: true, message: 'Failed to load student data.', type: 'danger' });
      } finally {
        setLoading(false);
      }
    };

    // Only fetch students if the branch ID is defined
    if (currentBranchId) {
      fetchStudentsByBranch(); // Call fetch function directly
    }
  }, [currentBranchId]); // Dependency on currentBranchId

  // Handle search input change and filter students
  useEffect(() => {
    if (searchQuery.trim() !== '') {
      const filtered = students.filter((student) =>
        student.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredStudents(filtered);
    } else {
      setFilteredStudents(students); // If search query is empty, show all students
    }
  }, [searchQuery, students]); // Update when searchQuery or students list changes

  const handleDelete = async (id) => {
    try {
      setAlert({ show: true, message: 'Deleting student...', type: 'info' });
      const response = await fetch(`http://localhost:5001/api/students/${id}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Failed to delete student.'); // Handle potential errors during deletion
      }

      setStudents(students.filter(student => student._id !== id));
      setFilteredStudents(filteredStudents.filter(student => student._id !== id)); // Update filtered list after deletion
      setAlert({ show: true, message: 'Student deleted successfully!', type: 'success' });
    } catch (error) {
      console.error('Error deleting student:', error); // Log error details
      setAlert({ show: true, message: 'Failed to delete student.', type: 'danger' });
    }
  };

  return (
    <Container fluid className="my-4">
      <Row className="mb-4">
        <Col>
          <h1 className="text-center mb-4" style={{ fontFamily: 'Arial, sans-serif', color: '#343a40' }}>Student Directory</h1>
        </Col>
      </Row>

      {/* Search bar to filter students */}
      <Row className="mb-4">
        <Col>
          <InputGroup>
            <FormControl
              placeholder="Search students by name..."
              aria-label="Search students"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </InputGroup>
        </Col>
      </Row>

      {loading ? (
        <Row className="d-flex justify-content-center">
          <Spinner animation="border" />
        </Row>
      ) : (
        <Row>
          {filteredStudents.length > 0 ? (
            filteredStudents.map((student) => (
              <Col xs={12} sm={6} md={4} lg={3} key={student._id} className="mb-4">
                <Card>
                <Card.Img
                     variant="top"
                     src={student.profilePicture ? `${config.API_BASE_URL}/${student.profilePicture}` : '/default-avatar.png'}
                     style={{ objectFit: 'cover', height: '200px' }}
                   />
                  <Card.Body>
                    <Card.Title className="text-primary">{student.name}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">Class: {student.className}</Card.Subtitle>
                    <Card.Text>Admission Date: {student.admissionDate}</Card.Text>
                    <div className="d-flex justify-content-between">
                      <Button variant="warning" as={Link} to={`/students/edit/${student._id}`} className="me-2">
                        <EditIcon fontSize="small" /> Edit
                      </Button>
                      <Button variant="danger" onClick={() => handleDelete(student._id)} className="me-2">
                        <DeleteIcon fontSize="small" /> Delete
                      </Button>
                      <Button variant="outline-primary" as={Link} to={`/students/details/${student._id}`}>
                        <VisibilityIcon fontSize="small" /> View
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))
          ) : (
            <Col className="text-center">
              <p>No students found for this branch.</p>
            </Col>
          )}
        </Row>
      )}

      <Alert show={alert.show} variant={alert.type} onClose={() => setAlert({ ...alert, show: false })} dismissible>
        {alert.message}
      </Alert>
    </Container>
  );
};

export default ViewStudents;






// import React, { useState, useEffect } from 'react';
// import { Table, Button, Container, Row, Col, InputGroup, FormControl, Card, Spinner, Alert } from 'react-bootstrap';
// import { Link } from 'react-router-dom';
// import { Edit as EditIcon, Delete as DeleteIcon, Visibility as VisibilityIcon } from '@mui/icons-material';
// import 'bootstrap/dist/css/bootstrap.min.css';

// const ViewStudents = () => {
//   const [students, setStudents] = useState([]);
//   const [search, setSearch] = useState('');
//   const [loading, setLoading] = useState(true);
//   const [alert, setAlert] = useState({ show: false, message: '', type: '' });

//   useEffect(() => {
//     // Fetch data from backend
//     const fetchStudents = async () => {
//       try {
//         const response = await fetch('http://localhost:5001/api/students');
//         const data = await response.json();
//         setStudents(data);
//       } catch (error) {
//         setAlert({ show: true, message: 'Failed to load student data.', type: 'danger' });
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchStudents();
//   }, []);

//   const handleDelete = async (id) => {
//     try {
//       // Show deleting feedback
//       setAlert({ show: true, message: 'Deleting student...', type: 'info' });

//       await fetch(`http://localhost:5001/api/students/${id}`, {
//         method: 'DELETE',
//       });

//       // Update the state by filtering out the deleted student
//       setStudents(students.filter(student => student._id !== id));
      
//       // Show success message
//       setAlert({ show: true, message: 'Student deleted successfully!', type: 'success' });
//     } catch (error) {
//       // Show error message
//       setAlert({ show: true, message: 'Failed to delete student.', type: 'danger' });
//     }
//   };

//   // Define filteredStudents before using it
//   const filteredStudents = students.filter(student =>
//     (student.name && student.name.toLowerCase().includes(search.toLowerCase())) ||
//     (student.className && student.className.toLowerCase().includes(search.toLowerCase()))
//   );

//   return (
//     <Container fluid className="my-4">
//       <Row className="mb-4">
//         <Col>
//           <h1 className="text-center mb-4" style={{ fontFamily: 'Arial, sans-serif', color: '#343a40' }}>Student Directory</h1>
//         </Col>
//       </Row>
      
//       <Row className="mb-4">
//         <Col>
//           <InputGroup>
//             <FormControl
//               placeholder="Search by name or class"
//               aria-label="Search"
//               aria-describedby="search-addon"
//               value={search}
//               onChange={(e) => setSearch(e.target.value)}
//               style={{ borderRadius: '0.25rem' }}
//             />
//           </InputGroup>
//         </Col>
//       </Row>

//       {loading ? (
//         <Row className="d-flex justify-content-center">
//           <Spinner animation="border" />
//         </Row>
//       ) : (
//         <Row>
//           {filteredStudents.length > 0 ? (
//             filteredStudents.map((student) => (
//               <Col xs={12} sm={6} md={4} lg={3} key={student._id} className="mb-4">
//                 <Card>
//                   <Card.Body>
//                     <Card.Title className="text-primary">{student.name}</Card.Title>
//                     <Card.Subtitle className="mb-2 text-muted">Class: {student.className}</Card.Subtitle>
//                     <Card.Text>Admission Date: {student.admissionDate}</Card.Text>
//                     <div className="d-flex justify-content-between">
//                       <Button variant="warning" as={Link} to={`/students/edit/${student._id}`} className="me-2">
//                         <EditIcon fontSize="small" /> Edit
//                       </Button>
//                       <Button variant="danger" onClick={() => handleDelete(student._id)} className="me-2">
//                         <DeleteIcon fontSize="small" /> Delete
//                       </Button>
//                       <Button variant="outline-primary" as={Link} to={`/students/details/${student._id}`}>
//                         <VisibilityIcon fontSize="small" /> View
//                       </Button>
//                     </div>
//                   </Card.Body>
//                 </Card>
//               </Col>
//             ))
//           ) : (
//             <Col className="text-center">
//               <p>No students found</p>
//             </Col>
//           )}
//         </Row>
//       )}

//       <Alert show={alert.show} variant={alert.type} onClose={() => setAlert({ ...alert, show: false })} dismissible>
//         {alert.message}
//       </Alert>
//     </Container>
//   );
// };

// export default ViewStudents;




