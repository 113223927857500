import React from 'react';
import { Modal, Button, Image, Card } from 'react-bootstrap';

const JobApplicationDetail = ({ application }) => {
  return (
    <Modal show={!!application} onHide={() => window.location.reload()}>
      <Modal.Header closeButton>
        <Modal.Title>Application Details: {application.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card className="mb-4">
          <Card.Body>
            <h4>Applicant Information</h4>
            <p><strong>Name:</strong> {application.name}</p>
            <p><strong>Email:</strong> {application.email}</p>
            <p><strong>Job Title:</strong> {application.jobTitle}</p>
            <p><strong>Status:</strong> {application.status}</p>
          </Card.Body>
        </Card>

        <Card className="mb-4">
          <Card.Body>
            <h4>Cover Letter</h4>
            <p>{application.coverLetter}</p>
          </Card.Body>
        </Card>

        <Card className="mb-4">
          <Card.Body>
            <h4>Uploaded Documents</h4>
            {application.documents && application.documents.map((doc, index) => (
              <div key={index}>
                <strong>{doc.title}</strong>
                <Image src={doc.url} alt={doc.title} fluid className="mb-3" />
              </div>
            ))}
          </Card.Body>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => window.location.reload()}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default JobApplicationDetail;
