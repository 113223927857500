import React, { useState, useEffect } from 'react';
import { Card, Form, Button, ListGroup, Row, Col, Container, Alert } from 'react-bootstrap';
import axios from 'axios';
import config from '../../components/config/config';

const BranchList = () => {
  const [branches, setBranches] = useState([]);
  const [selectedBranchId, setSelectedBranchId] = useState(null);
  const [branchData, setBranchData] = useState({
    name: '',
    location: '',
    contact: '',
    email: '',
    manager: ''
  });
  const [managers, setManagers] = useState([]);
  const [isNewBranch, setIsNewBranch] = useState(false);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await axios.get(`${config.API_BASE_URL}/api/branch`);
        setBranches(response.data);
      } catch (error) {
        console.error('Error fetching branches:', error);
      }
    };

    const fetchManagers = async () => {
      try {
        const response = await axios.get(`${config.API_BASE_URL}/api/employees`);
        const employees = response.data;
        const filteredManagers = employees.filter(emp => emp.role === 'manager');
        setManagers(filteredManagers);
      } catch (error) {
        console.error('Error fetching managers:', error);
      }
    };

    fetchBranches();
    fetchManagers();
  }, []);

  const fetchBranchDetails = async (branchId) => {
    try {
      const response = await axios.get(`${config.API_BASE_URL}/api/branch/${branchId}`);
      setBranchData(response.data);
      setSelectedBranchId(branchId);
      setIsNewBranch(false);
    } catch (error) {
      console.error('Error fetching branch details:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBranchData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isNewBranch) {
        // Add new branch
        await axios.post(`${config.API_BASE_URL}/api/branch`, branchData);
        setAlert({ type: 'success', message: 'New branch added successfully!' });
      } else {
        // Update existing branch
        await axios.put(`${config.API_BASE_URL}1/api/branch/${selectedBranchId}`, branchData);
        setAlert({ type: 'success', message: 'Branch details updated successfully!' });
      }
      // Refresh the list of branches
      const response = await axios.get(`${config.API_BASE_URL}/api/branch`);
      setBranches(response.data);
      // Clear form
      setBranchData({ name: '', location: '', contact: '', email: '', manager: '' });
      setIsNewBranch(false);
      setSelectedBranchId(null);
    } catch (error) {
      setAlert({ type: 'danger', message: 'Error saving branch details.' });
      console.error('Error handling branch form submission:', error);
    }
  };

  const handleDelete = async (branchId) => {
    try {
      await axios.delete(`${config.API_BASE_URL}/api/branch/${branchId}`);
      setAlert({ type: 'success', message: 'Branch deleted successfully!' });
      setBranches(branches.filter(branch => branch._id !== branchId));
      setBranchData({ name: '', location: '', contact: '', email: '', manager: '' });
      setSelectedBranchId(null);
    } catch (error) {
      setAlert({ type: 'danger', message: 'Error deleting branch.' });
      console.error('Error deleting branch:', error);
    }
  };

  return (
    <Container className="mt-5">
      <Row>
        {/* Branch List */}
        <Col md={4}>
          <Card className="shadow-sm border-0">
            <Card.Header className="bg-primary text-white text-center font-weight-bold">
              Branch List
            </Card.Header>
            <ListGroup variant="flush" className="overflow-auto" style={{ maxHeight: '400px' }}>
              {branches.map((branch) => (
                <ListGroup.Item
                  key={branch._id}
                  action
                  onClick={() => fetchBranchDetails(branch._id)}
                  className={`cursor-pointer ${selectedBranchId === branch._id ? 'active' : ''}`}
                  style={{ borderBottom: '1px solid #f1f1f1' }}
                >
                  {branch.name}
                  <Button variant="danger" size="sm" className="float-end" onClick={() => handleDelete(branch._id)}>
                    Delete
                  </Button>
                </ListGroup.Item>
              ))}
              <ListGroup.Item
                action
                onClick={() => {
                  setBranchData({ name: '', location: '', contact: '', email: '', manager: '' });
                  setIsNewBranch(true);
                  setSelectedBranchId(null);
                }}
                className="text-success font-weight-bold"
              >
                + Add New Branch
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>

        {/* Branch Details Form */}
        <Col md={8}>
          <Card className="shadow-sm border-0">
            <Card.Body className="p-4">
              <Card.Title className="mb-4 text-center font-weight-bold" style={{ fontSize: '1.5rem' }}>
                {isNewBranch ? 'Add New Branch' : 'Branch Details'}
              </Card.Title>
              {alert && (
                <Alert variant={alert.type} className="text-center">
                  {alert.message}
                </Alert>
              )}
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formBranchName">
                  <Form.Label>Branch Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={branchData.name}
                    onChange={handleChange}
                    placeholder="Enter branch name"
                    className="rounded-pill"
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formBranchLocation">
                  <Form.Label>Location</Form.Label>
                  <Form.Control
                    type="text"
                    name="location"
                    value={branchData.location}
                    onChange={handleChange}
                    placeholder="Enter location"
                    className="rounded-pill"
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formBranchContact">
                  <Form.Label>Contact Number</Form.Label>
                  <Form.Control
                    type="text"
                    name="contact"
                    value={branchData.contact}
                    onChange={handleChange}
                    placeholder="Enter contact number"
                    className="rounded-pill"
                  />
                </Form.Group>
                <Form.Group controlId="formBranchEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={branchData.email}
                    onChange={handleChange}
                    placeholder="Enter email"
                    className="rounded-pill"
                  />
                </Form.Group>
                <Form.Group controlId="formBranchManager">
                  <Form.Label>Manager Name</Form.Label>
                  <Form.Control
                    as="select"
                    name="manager"
                    value={branchData.manager}
                    onChange={handleChange}
                    className="rounded-pill"
                    required
                  >
                    <option value="">Select manager</option>
                    {managers.map((manager) => (
                      <option key={manager._id} value={manager.name}>
                        {manager.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <div className="text-center">
                  <Button variant="success" type="submit" className="rounded-pill px-4">
                    {isNewBranch ? 'Add Branch' : 'Save Changes'}
                  </Button>
                  <Button
                    variant="secondary"
                    className="rounded-pill px-4 ml-3"
                    type="button"
                    onClick={() => {
                      setBranchData({ name: '', location: '', contact: '', email: '', manager: '' });
                      setIsNewBranch(false);
                      setSelectedBranchId(null);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default BranchList;




// import React, { useState, useEffect } from 'react';
// import { Card, Form, Button, ListGroup, Row, Col, Container } from 'react-bootstrap';
// import axios from 'axios';

// const BranchList = () => {
//   const [branches, setBranches] = useState([]);
//   const [selectedBranchId, setSelectedBranchId] = useState(null);
//   const [branchData, setBranchData] = useState({
//     name: '',
//     location: '',
//     contact: '',
//     manager: ''
//   });
//   const [managers, setManagers] = useState([]);

//   // Fetch all branches and managers when the component mounts
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const branchesResponse = await axios.get('/api/branches'); // Replace with your endpoint
//         setBranches(branchesResponse.data);

//         const managersResponse = await axios.get('/api/managers'); // Replace with your endpoint
//         if (Array.isArray(managersResponse.data)) {
//           setManagers(managersResponse.data);
//         } else {
//           console.error('Unexpected data format for managers:', managersResponse.data);
//         }
//       } catch (error) {
//         console.error('Error fetching data:', error);
//       }
//     };

//     fetchData();
//   }, []);

//   // Fetch selected branch details when a branch is clicked
//   const fetchBranchDetails = async (branchId) => {
//     try {
//       const branchResponse = await axios.get(`/api/branch/${branchId}`);
//       setBranchData(branchResponse.data);
//       setSelectedBranchId(branchId); // Set the selected branch ID
//     } catch (error) {
//       console.error('Error fetching branch details:', error);
//     }
//   };

//   // Handle input changes for the form
//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setBranchData((prevData) => ({
//       ...prevData,
//       [name]: value
//     }));
//   };

//   // Handle form submission
//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       await axios.put(`/api/branch/${selectedBranchId}`, branchData); // Replace with the correct endpoint
//       alert('Branch details updated successfully');
//     } catch (error) {
//       console.error('Error updating branch data:', error);
//     }
//   };

//   return (
//     <Container className="mt-5">
//       <Row>
//         {/* Branch List */}
//         <Col md={4}>
//           <Card className="shadow-sm border-0">
//             <Card.Header className="bg-primary text-white text-center font-weight-bold">
//               Branch List
//             </Card.Header>
//             <ListGroup variant="flush" className="overflow-auto" style={{ maxHeight: '400px' }}>
//               {branches.map((branch) => (
//                 <ListGroup.Item
//                   key={branch._id}
//                   action
//                   onClick={() => fetchBranchDetails(branch._id)}
//                   className={`cursor-pointer ${selectedBranchId === branch._id ? 'active' : ''}`}
//                   style={{ borderBottom: '1px solid #f1f1f1' }}
//                 >
//                   {branch.name}
//                 </ListGroup.Item>
//               ))}
//             </ListGroup>
//           </Card>
//         </Col>

//         {/* Branch Details Form */}
//         <Col md={8}>
//           <Card className="shadow-sm border-0">
//             <Card.Body className="p-4">
//               <Card.Title className="mb-4 text-center font-weight-bold" style={{ fontSize: '1.5rem' }}>
//                 Branch Details
//               </Card.Title>
//               {selectedBranchId ? (
//                 <Form onSubmit={handleSubmit}>
//                   <Form.Group controlId="formBranchName">
//                     <Form.Label>Branch Name</Form.Label>
//                     <Form.Control
//                       type="text"
//                       name="name"
//                       value={branchData.name}
//                       onChange={handleChange}
//                       placeholder="Enter branch name"
//                       className="rounded-pill"
//                     />
//                   </Form.Group>
//                   <Form.Group controlId="formBranchLocation">
//                     <Form.Label>Location</Form.Label>
//                     <Form.Control
//                       type="text"
//                       name="location"
//                       value={branchData.location}
//                       onChange={handleChange}
//                       placeholder="Enter location"
//                       className="rounded-pill"
//                     />
//                   </Form.Group>
//                   <Form.Group controlId="formBranchContact">
//                     <Form.Label>Contact Details</Form.Label>
//                     <Form.Control
//                       type="text"
//                       name="contact"
//                       value={branchData.contact}
//                       onChange={handleChange}
//                       placeholder="Enter contact details"
//                       className="rounded-pill"
//                     />
//                   </Form.Group>
//                   <Form.Group controlId="formBranchManager">
//                     <Form.Label>Manager</Form.Label>
//                     <Form.Control
//                       as="select"
//                       name="manager"
//                       value={branchData.manager}
//                       onChange={handleChange}
//                       className="rounded-pill"
//                     >
//                       <option value="">Select manager</option>
//                       {managers.map((manager) => (
//                         <option key={manager._id} value={manager._id}>
//                           {manager.name}
//                         </option>
//                       ))}
//                     </Form.Control>
//                   </Form.Group>
//                   <div className="text-center">
//                     <Button variant="success" type="submit" className="rounded-pill px-4">
//                       Save Changes
//                     </Button>
//                     <Button variant="secondary" className="rounded-pill px-4 ml-3" type="button">
//                       Cancel
//                     </Button>
//                   </div>
//                 </Form>
//               ) : (
//                 <p className="text-center text-muted">Select a branch to view and edit its details.</p>
//               )}
//             </Card.Body>
//           </Card>
//         </Col>
//       </Row>
//     </Container>
//   );
// };

// export default BranchList;

