import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Card, Table, Button } from 'react-bootstrap';
import axios from 'axios';

const ViewPayHistory = () => {
  const { employeeId } = useParams();
  const [payHistory, setPayHistory] = useState([]);
  const [employeeName, setEmployeeName] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchPayHistory = async () => {
      try {
        const response = await axios.get(`https://control.novelkoirala.com:5001/api/employee-salaries/${employeeId}`);
        setPayHistory(response.data);
        if (response.data.length > 0) {
          setEmployeeName(response.data[0].employeeName);
        }
      } catch (error) {
        setError('Failed to fetch pay history');
      }
    };

    fetchPayHistory();
  }, [employeeId]);

  return (
    <Container className="my-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <Card>
            <Card.Header className="bg-primary text-white">
              {employeeName ? `Pay History for ${employeeName}` : 'Pay History'}
            </Card.Header>
            <Card.Body>
              {error && <div className="alert alert-danger">{error}</div>}
              {payHistory.length > 0 ? (
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Amount</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {payHistory.map((record) => (
                      <tr key={record._id}>
                        <td>{new Date(record.date).toLocaleDateString()}</td>
                        <td>${record.amount}</td>
                        <td>{record.status}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <p>No pay history available for this employee.</p>
              )}
              <Button variant="secondary" onClick={() => window.history.back()}>
                Back
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ViewPayHistory;
