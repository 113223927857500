import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const PaySalary = () => {
  const [formData, setFormData] = useState({
    employeeId: '',
    amount: '',
    status: 'Pending',
    date: '',
  });
  const [employees, setEmployees] = useState([]);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axios.get('https://control.novelkoirala.com:5001/api/employees');
        setEmployees(response.data);
      } catch (error) {
        setError('Failed to fetch employees');
      }
    };

    fetchEmployees();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const selectedEmployee = employees.find(emp => emp._id === formData.employeeId);

    if (!selectedEmployee) {
        setError('Selected employee not found');
        return;
    }

    const dataToSend = {
        ...formData,
        employeeName: selectedEmployee.name,
    };

    try {
        await axios.post('https://control.novelkoirala.com:5001/api/employee-salaries', dataToSend);
        setFormData({
            employeeId: '',
            amount: '',
            status: 'Pending',
            date: '',
        });
        setError('');
    } catch (error) {
        setError('Failed to add salary record');
    }
  };

  const handleViewHistory = () => {
    if (formData.employeeId) {
      navigate(`/payhistory/${formData.employeeId}`);
    } else {
      setError('Please select an employee to view their pay history');
    }
  };

  return (
    <Container className="my-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <Card>
            <Card.Header className="bg-primary text-white">Pay Salary</Card.Header>
            <Card.Body>
              {error && <div className="alert alert-danger">{error}</div>}
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="employeeId">
                  <Form.Label>Employee</Form.Label>
                  <Form.Control
                    as="select"
                    name="employeeId"
                    value={formData.employeeId}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select an employee</option>
                    {employees.map((employee) => (
                      <option key={employee._id} value={employee._id}>
                        {employee.name} (ID: {employee._id})
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="amount" className="mt-3">
                  <Form.Label>Amount</Form.Label>
                  <Form.Control
                    type="number"
                    name="amount"
                    value={formData.amount}
                    onChange={handleChange}
                    placeholder="Enter salary amount"
                    required
                  />
                </Form.Group>
                <Form.Group controlId="status" className="mt-3">
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select"
                    name="status"
                    value={formData.status}
                    onChange={handleChange}
                  >
                    <option value="Pending">Pending</option>
                    <option value="Paid">Paid</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="date" className="mt-3">
                  <Form.Label>Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="date"
                    value={formData.date}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <div className="d-flex justify-content-between mt-4">
                  <Button variant="primary" type="submit">Submit</Button>
                  <Button 
                    variant="secondary" 
                    onClick={handleViewHistory}
                  >
                    View Pay History
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PaySalary;
