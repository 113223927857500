import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Card, Button, Container, Row, Col, Spinner, Alert, FormControl, InputGroup, Modal } from 'react-bootstrap';
import { Edit as EditIcon, Delete as DeleteIcon, Visibility as VisibilityIcon } from '@mui/icons-material';
import { useBranch } from '../Context/BranchContext';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import config from '../../components/config/config';

const EmployeeList = () => {
  const { currentBranchId } = useBranch();
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({ show: false, message: '', type: '' });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    const fetchEmployees = async () => {
      setLoading(true);
      setAlert({ show: false, message: '', type: '' });

      if (!currentBranchId) {
        setLoading(false);
        setAlert({ show: true, message: 'No branch selected.', type: 'warning' });
        return;
      }

      try {
        const response = await axios.get(`${config.API_BASE_URL}/api/employees/branch/${currentBranchId}`);
        setEmployees(response.data);
        setFilteredEmployees(response.data);
      } catch (error) {
        setAlert({ show: true, message: 'Failed to fetch employees. Please try again later.', type: 'danger' });
      } finally {
        setLoading(false);
      }
    };

    fetchEmployees();
  }, [currentBranchId]); // Add currentBranchId as a dependency

  useEffect(() => {
    if (search.trim() !== '') {
      const filtered = employees.filter(emp =>
        emp.name.toLowerCase().includes(search.toLowerCase()) ||
        emp.email.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredEmployees(filtered);
    } else {
      setFilteredEmployees(employees);
    }
  }, [search, employees]);

  const handleDelete = async () => {
    try {
      await axios.delete(`${config.API_BASE_URL}/api/employees/${deleteId}`);
      setEmployees(employees.filter(emp => emp._id !== deleteId));
      setFilteredEmployees(filteredEmployees.filter(emp => emp._id !== deleteId));
      setAlert({ show: true, message: 'Employee deleted successfully!', type: 'success' });
    } catch (error) {
      setAlert({ show: true, message: 'Failed to delete employee. Please try again later.', type: 'danger' });
    }
    setShowDeleteModal(false);
  };

  return (
    <Container className="my-5">
      <h1 className="mb-4 text-center">Employee Directory</h1>

      <InputGroup className="mb-4">
        <FormControl
          placeholder="Search employees by name or email..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </InputGroup>

      <Button
        variant="primary"
        as={Link}
        to="/employees/add"
        className="mb-4 d-block mx-auto"
        style={{ backgroundColor: '#007bff', borderColor: '#007bff' }}
      >
        Add New Employee
      </Button>

      {loading ? (
        <div className="d-flex justify-content-center my-4">
          <Spinner animation="border" variant="primary" />
        </div>
      ) : (
        <Row>
          {filteredEmployees.length > 0 ? (
            filteredEmployees.map(employee => (
              <Col xs={12} sm={6} md={4} lg={3} className="mb-4" key={employee._id}>
                <Card>
                  <Card.Body>
                  <Card.Img
                     variant="top"
                     src={employee.profilePicture ? `${config.API_BASE_URL}/uploads/${employee.profilePicture}` : '/default-avatar.png'}
                     style={{ objectFit: 'cover', height: '200px' }}
                   />
                    <Card.Title className="text-primary">{employee.name}</Card.Title>
                    <Card.Text>
                      <strong>Email:</strong> {employee.email}
                    </Card.Text>
                    <div className="d-flex justify-content-between">
                      <Button
                        variant="warning"
                        as={Link}
                        to={`/employees/edit/${employee._id}`}
                        className="me-2"
                      >
                        <EditIcon fontSize="small" /> Edit
                      </Button>
                      <Button
                        variant="danger"
                        onClick={() => { setShowDeleteModal(true); setDeleteId(employee._id); }}
                        className="me-2"
                      >
                        <DeleteIcon fontSize="small" /> Delete
                      </Button>
                      <Button
                        variant="outline-primary"
                        as={Link}
                        to={`/employees/details/${employee._id}`}
                      >
                        <VisibilityIcon fontSize="small" /> View
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))
          ) : (
            <Col className="text-center">
              <p>No employees found for this branch.</p>
            </Col>
          )}
        </Row>
      )}

      <Alert show={alert.show} variant={alert.type} onClose={() => setAlert({ ...alert, show: false })} dismissible>
        {alert.message}
      </Alert>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this employee?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
          <Button variant="danger" onClick={handleDelete}>Delete</Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default EmployeeList;





// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import { Card, Button, Container, Row, Col, Form, Spinner, Alert, Modal } from 'react-bootstrap';
// import axios from 'axios';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import config from '../../components/config/config';

// const EmployeeList = () => {
//   const [employees, setEmployees] = useState([]);
//   const [search, setSearch] = useState('');
//   const [loading, setLoading] = useState(true);
//   const [alert, setAlert] = useState({ show: false, message: '', type: '' });
//   const [showDeleteModal, setShowDeleteModal] = useState(false);
//   const [deleteId, setDeleteId] = useState(null);

//   useEffect(() => {
//     const fetchEmployees = async () => {
//       try {
//         const response = await axios.get(`${config.API_BASE_URL}/api/employees`);
//         setEmployees(response.data);
//         setLoading(false);
//       } catch (error) {
//         setAlert({ show: true, message: 'Failed to fetch employees. Please try again later.', type: 'danger' });
//         setLoading(false);
//       }
//     };

//     fetchEmployees();
//   }, []);

//   const handleDelete = async () => {
//     try {
//       await axios.delete(`${config.API_BASE_URL}/api/employees/${deleteId}`);
//       setEmployees(employees.filter(emp => emp._id !== deleteId));
//       setAlert({ show: true, message: 'Employee deleted successfully!', type: 'success' });
//     } catch (error) {
//       setAlert({ show: true, message: 'Failed to delete employee. Please try again later.', type: 'danger' });
//     }
//     setShowDeleteModal(false);
//   };

//   const filteredEmployees = employees.filter(emp =>
//     emp.name.toLowerCase().includes(search.toLowerCase()) ||
//     emp.email.toLowerCase().includes(search.toLowerCase())
//   );

//   return (
//     <Container className="my-5">
//       <h1 className="mb-4 text-center">Employee Directory</h1>

//       <Form.Group className="mb-4">
//         <Form.Control
//           type="text"
//           placeholder="Search Employee"
//           value={search}
//           onChange={(e) => setSearch(e.target.value)}
//           className="rounded-pill"
//         />
//       </Form.Group>

//       <Button
//         variant="primary"
//         as={Link}
//         to="/employees/add"
//         className="mb-4 d-block mx-auto"
//         style={{ backgroundColor: '#007bff', borderColor: '#007bff' }}
//       >
//         Add New Employee
//       </Button>

//       {loading ? (
//         <div className="d-flex justify-content-center my-4">
//           <Spinner animation="border" variant="primary" />
//         </div>
//       ) : (
//         <Row>
//           {filteredEmployees.length > 0 ? (
//             filteredEmployees.map(employee => (
//               <Col xs={12} sm={6} md={4} lg={3} className="mb-4 d-flex" key={employee._id}>
//                 <Card className="w-100 h-100 d-flex flex-column">
                  
//                   <Card.Img
//                     variant="top"
//                     src={employee.profilePicture ? `${config.API_BASE_URL}/uploads/${employee.profilePicture}` : '/default-avatar.png'}
//                     style={{ objectFit: 'cover', height: '200px' }}
//                   />
//                   <Card.Body className="d-flex flex-column">
//                     <Card.Title className="text-center">{employee.name}</Card.Title>
//                     <Card.Text className="text-center flex-grow-1">
//                       <strong>Role:</strong> {employee.role}<br />
//                       <strong>Email:</strong> {employee.email}
//                     </Card.Text>
//                     <div className="d-flex justify-content-around mt-3">
//                       <Button
//                         variant="warning"
//                         as={Link}
//                         to={`/employees/edit/${employee._id}`}
//                         style={{ width: '45%' }}
//                       >
//                         Edit
//                       </Button>
//                       <Button
//                         variant="danger"
//                         onClick={() => { setShowDeleteModal(true); setDeleteId(employee._id); }}
//                         style={{ width: '45%' }}
//                       >
//                         Delete
//                       </Button>
//                     </div>
//                   </Card.Body>
//                 </Card>
//               </Col>
//             ))
//           ) : (
//             <Col className="text-center">
//               <p>No employees found</p>
//             </Col>
//           )}
//         </Row>
//       )}

//       <Alert show={alert.show} variant={alert.type} onClose={() => setAlert({ ...alert, show: false })} dismissible>
//         {alert.message}
//       </Alert>

//       {/* Delete Confirmation Modal */}
//       <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
//         <Modal.Header closeButton>
//           <Modal.Title>Confirm Delete</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>Are you sure you want to delete this employee?</Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
//           <Button variant="danger" onClick={handleDelete}>Delete</Button>
//         </Modal.Footer>
//       </Modal>
//     </Container>
//   );
// };

// export default EmployeeList;















