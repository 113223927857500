import React, { createContext, useContext, useState, useEffect } from 'react';
import config from '../../components/config/config';

// Create the context
const BranchContext = createContext();

// Custom hook to use the BranchContext
export const useBranch = () => useContext(BranchContext);

// Provider component to wrap your app and provide branch data
export const BranchProvider = ({ children }) => {
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [userBranches, setUserBranches] = useState([]);
  const [user, setUser] = useState(null);

  // Function to fetch user and branches
  const fetchUserAndBranches = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.warn('No token found in localStorage');
      return;
    }

    try {
      // Fetch user profile
      const response = await fetch(`${config.API_BASE_URL}/api/profile`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch user profile: ${response.statusText}`);
      }

      const userData = await response.json();
      setUser(userData);
      console.log('User data fetched:', userData);

      // Fetch branches from the API
      const branchResponse = await fetch(`${config.API_BASE_URL}/api/branch`);

      if (!branchResponse.ok) {
        throw new Error(`Failed to fetch branches: ${branchResponse.statusText}`);
      }

      const branches = await branchResponse.json();
      console.log('Branches data fetched:', branches);

      // Set user branches and selected branch based on the user's role
      if (userData.role === 'Admin') {
        setUserBranches(branches);
        setSelectedBranch(null); // Set to null for Admin
        console.log('Admin role detected: All branches are available');
      } else {
        const assignedBranch = branches.find(branch => branch._id === userData.branchId);
        setUserBranches(assignedBranch ? [assignedBranch] : []);
        setSelectedBranch(assignedBranch);
        console.log('Non-admin role detected: Assigned branch:', assignedBranch);
      }
    } catch (error) {
      console.error('Error fetching user or branch data:', error);
    }
  };

  // Fetch user and branches on initial load
  useEffect(() => {
    fetchUserAndBranches();
  }, []);

  // Listen to token changes in localStorage
  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === 'token') {
        fetchUserAndBranches(); // Re-fetch if the token changes
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  // Function to refresh selected branch data (called when branch changes)
  const refreshBranches = () => {
    if (selectedBranch) {
      console.log('Refreshing data for branch ID:', selectedBranch._id);
      fetchUserAndBranches(); // Re-fetch branches whenever called
    }
  };

  return (
    <BranchContext.Provider
      value={{
        selectedBranch,
        setSelectedBranch,
        userBranches,
        setUserBranches,
        user,
        currentBranchId: selectedBranch ? selectedBranch._id : null,
        refreshBranches, // Expose fetch function to manually refresh
      }}
    >
      {children}
    </BranchContext.Provider>
  );
};



// import React, { createContext, useContext, useState, useEffect } from 'react';
// import config from '../../components/config/config';

// // Create the context
// const BranchContext = createContext();

// // Custom hook to use the BranchContext
// export const useBranch = () => useContext(BranchContext);

// // Provider component to wrap your app and provide branch data
// export const BranchProvider = ({ children }) => {
//   const [selectedBranch, setSelectedBranch] = useState(null);
//   const [userBranches, setUserBranches] = useState([]);
//   const [user, setUser] = useState(null);

//   // Function to fetch user and branches
//   const fetchUserAndBranches = async () => {
//     const token = localStorage.getItem('token');
//     if (!token) {
//       console.warn('No token found in localStorage');
//       return;
//     }

//     try {
//       // Fetch user profile
//       const response = await fetch(`${config.API_BASE_URL}/api/profile`, {
//         headers: {
//           'Authorization': `Bearer ${token}`,
//         },
//       });

//       if (!response.ok) {
//         throw new Error(`Failed to fetch user profile: ${response.statusText}`);
//       }

//       const userData = await response.json();
//       setUser(userData);
//       console.log('User data fetched:', userData);

//       // Fetch branches from the API
//       const branchResponse = await fetch('http://localhost:5001/api/branch');

//       if (!branchResponse.ok) {
//         throw new Error(`Failed to fetch branches: ${branchResponse.statusText}`);
//       }

//       const branches = await branchResponse.json();
//       console.log('Branches data fetched:', branches);

//       // Set user branches and selected branch based on the user's role
//       if (userData.role === 'Admin') {
//         setUserBranches(branches);
//         setSelectedBranch(null); // Set to null for Admin
//         console.log('Admin role detected: All branches are available');
//       } else {
//         const assignedBranch = branches.find(branch => branch._id === userData.branchId);
//         setUserBranches(assignedBranch ? [assignedBranch] : []);
//         setSelectedBranch(assignedBranch);
//         console.log('Non-admin role detected: Assigned branch:', assignedBranch);
//       }
//     } catch (error) {
//       console.error('Error fetching user or branch data:', error);
//     }
//   };

//   // Fetch user and branches on initial load
//   useEffect(() => {
//     fetchUserAndBranches();
//   }, []);

//   // Listen to token changes in localStorage
//   useEffect(() => {
//     const handleStorageChange = (e) => {
//       if (e.key === 'token') {
//         fetchUserAndBranches(); // Re-fetch if the token changes
//       }
//     };

//     window.addEventListener('storage', handleStorageChange);
//     return () => {
//       window.removeEventListener('storage', handleStorageChange);
//     };
//   }, []);

//   return (
//     <BranchContext.Provider
//       value={{
//         selectedBranch,
//         setSelectedBranch,
//         userBranches,
//         setUserBranches,
//         user,
//         currentBranchId: selectedBranch ? selectedBranch._id : null,
//         refreshBranches: fetchUserAndBranches, // Expose fetch function to manually refresh
//       }}
//     >
//       {children}
//     </BranchContext.Provider>
//   );
// };
