import React, { useEffect, useState } from 'react';
import { Table, Container, Row, Col, Card, Badge, Alert, Spinner, Button } from 'react-bootstrap';
import axios from 'axios';

const ActivityLog = () => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1); // Track current page
  const [totalPages, setTotalPages] = useState(1); // Track total pages

  const fetchLogs = async (page) => {
    setLoading(true);
    try {
      const response = await axios.get(`http://localhost:5001/api/activity-logs?page=${page}&limit=10`);
      const { logs, totalPages } = response.data;
      setLogs(logs);
      setTotalPages(totalPages);
      setLoading(false);
    } catch (error) {
      setError('Failed to load activity logs');
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchLogs(page); // Fetch logs for the current page
  }, [page]);

  const handleNextPage = () => {
    if (page < totalPages) setPage(page + 1);
  };

  const handlePrevPage = () => {
    if (page > 1) setPage(page - 1);
  };

  return (
    <Container fluid className="mt-4">
      <Row className="mb-4">
        <Col>
          <Card>
            <Card.Header as="h5" className="bg-primary text-white">
              <i className="bi bi-file-text"></i> Admin Activity Logs
            </Card.Header>
            <Card.Body>
              {error && <Alert variant="danger">{error}</Alert>}
              {loading ? (
                <div className="text-center">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : (
                <>
                  <Table striped bordered hover responsive className="text-center">
                    <thead className="table-primary">
                      <tr>
                        <th>User</th>
                        <th>Action</th>
                        <th>Timestamp</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {logs.length > 0 ? (
                        logs.map((log) => (
                          <tr key={log._id}>
                            <td>{log.user}</td>
                            <td>
                              <Badge bg="info">{log.action}</Badge>
                            </td>
                            <td>{new Date(log.timestamp).toLocaleString()}</td>
                            <td>{log.description}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="4">No activity logs found.</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                  {/* Pagination Controls */}
                  <div className="d-flex justify-content-between mt-3">
                    <Button 
                      variant="secondary" 
                      onClick={handlePrevPage} 
                      disabled={page === 1}
                    >
                      Previous
                    </Button>
                    <span>Page {page} of {totalPages}</span>
                    <Button 
                      variant="secondary" 
                      onClick={handleNextPage} 
                      disabled={page === totalPages}
                    >
                      Next
                    </Button>
                  </div>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ActivityLog;
