import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Table,
  Card,
  Pagination,
  Alert
} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Line, Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, BarElement, PointElement, Title, Tooltip, Legend } from 'chart.js';
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // Ensure jspdf-autotable is installed
import { CSVLink } from 'react-csv';
import axios from 'axios';

// Register necessary components for Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  BarElement,
  PointElement, // Required for line charts
  Title,
  Tooltip,
  Legend
);

const ClassWiseReport = () => {
  const [filter, setFilter] = useState({ className: '', startDate: '', endDate: '' });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true); // Set loading before fetching data
        const response = await axios.get('https://control.novelkoirala.com:5001/api/classwise-attendance', {
          params: {
            className: filter.className,
            startDate: filter.startDate,
            endDate: filter.endDate
          }
        });
        setData(response.data);
        setError(null); // Clear any previous errors
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [filter]);

  const handleChangeFilter = (e) => {
    const { name, value } = e.target;
    setFilter({ ...filter, [name]: value });
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredData = data.filter(item => {
    return (
      (filter.className ? item.className.toLowerCase().includes(filter.className.toLowerCase()) : true) &&
      (filter.startDate && filter.endDate ? new Date(item.date) >= new Date(filter.startDate) && new Date(item.date) <= new Date(filter.endDate) : true)
    );
  });

  const chartData = {
    labels: filteredData.map(item => item.date),
    datasets: [
      {
        label: 'Class Attendance',
        data: filteredData.map(item => item.attendance),
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        fill: true,
      }
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y;
            }
            return label;
          },
        },
      },
    },
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.text("Class Wise Attendance Report", 10, 10);
    doc.text(`Date Range: ${filter.startDate} - ${filter.endDate}`, 10, 20);
    doc.autoTable({ html: '#attendance-table' });
    doc.save('class-wise-report.pdf');
  };

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);

  return (
    <Container className="my-5">
      <Card>
        <Card.Header as="h4" className="bg-primary text-white">
          Class Wise Report
        </Card.Header>
        <Card.Body>
          {loading && <div>Loading...</div>}
          {error && (
            <Alert variant="danger">
              <strong>Error:</strong> {error}
            </Alert>
          )}
          <Form>
            <Row className="mb-4">
              <Col xs={12} md={4}>
                <Form.Group controlId="className">
                  <Form.Label>Class Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="className"
                    value={filter.className}
                    onChange={handleChangeFilter}
                    placeholder="Enter class name"
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={4}>
                <Form.Group controlId="startDate">
                  <Form.Label>Start Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="startDate"
                    value={filter.startDate}
                    onChange={handleChangeFilter}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={4}>
                <Form.Group controlId="endDate">
                  <Form.Label>End Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="endDate"
                    value={filter.endDate}
                    onChange={handleChangeFilter}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Button
              variant="primary"
              onClick={() => setPage(0)} // Reset pagination on filter change
            >
              Apply Filters
            </Button>
            <Button
              variant="secondary"
              className="ms-2"
              onClick={exportToPDF}
            >
              Export to PDF
            </Button>
            <CSVLink
              data={filteredData}
              headers={[
                { label: 'Class Name', key: 'className' },
                { label: 'Date', key: 'date' },
                { label: 'Attendance', key: 'attendance' }
              ]}
              filename="class-wise-report.csv"
              style={{ textDecoration: 'none' }}
            >
              <Button
                variant="secondary"
                className="ms-2"
              >
                Export to CSV
              </Button>
            </CSVLink>
          </Form>
          <Card className="mt-4">
            <Card.Body>
              <Table striped bordered hover responsive id="attendance-table">
                <thead>
                  <tr>
                    <th>Class Name</th>
                    <th>Date</th>
                    <th>Attendance</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <tr key={row.id}>
                      <td>{row.className}</td>
                      <td>{row.date}</td>
                      <td>{row.attendance}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Pagination className="mt-3">
                <Pagination.Prev onClick={() => handleChangePage(Math.max(page - 1, 0))} disabled={page === 0} />
                {[...Array(totalPages)].map((_, index) => (
                  <Pagination.Item key={index} active={index === page} onClick={() => handleChangePage(index)}>
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next onClick={() => handleChangePage(Math.min(page + 1, totalPages - 1))} disabled={page >= totalPages - 1} />
              </Pagination>
            </Card.Body>
          </Card>
          <Row className="mt-4">
            <Col xs={12} md={6}>
              <Card>
                <Card.Header>Class Wise Attendance Trend</Card.Header>
                <Card.Body>
                  <Line data={chartData} options={chartOptions} />
                </Card.Body>
              </Card>
            </Col>
            <Col xs={12} md={6}>
              <Card>
                <Card.Header>Attendance by Class</Card.Header>
                <Card.Body>
                  <Bar data={chartData} options={chartOptions} />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ClassWiseReport;
