import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Dropdown, Button, Modal, Alert } from 'react-bootstrap';
import { FaBell, FaUserCircle } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate } from 'react-router-dom';
import config from '../../components/config/config';
import { useBranch } from '../Context/BranchContext';

const TopBar = ({ refreshKey }) => {
  const navigate = useNavigate();
  const { selectedBranch, setSelectedBranch, userBranches, refreshBranches } = useBranch(); // Use the BranchContext
  const [user, setUser] = useState(null);
  const [showProfile, setShowProfile] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: '', type: '' });

  // Fetch user details using JWT token
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
        if (!token) {
          console.error('No token found'); // Debugging
          navigate('/login'); // Redirect to login if token is missing
          return;
        }

        const response = await fetch(`${config.API_BASE_URL}/api/profile`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          const errorMessage = await response.text(); // Get the error message from the response
          throw new Error(`Failed to fetch user data: ${response.status} - ${errorMessage}`);
        }

        const data = await response.json();
        setUser(data);
        console.log('User fetched:', data); // Debugging
      } catch (error) {
        console.error('Error fetching user data:', error);
        navigate('/login'); // Redirect to login on error
      }
    };

    fetchUser();
  }, []);

  const handleBranchChange = (branch) => {
    setSelectedBranch(branch);
  };

  const handleProfileClick = () => {
    setShowProfile(true);
  };

  const handleCloseProfile = () => {
    setShowProfile(false);
  };

  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove token from localStorage
    navigate('/login'); // Redirect to login page
  };

  return (
    <>
      <Navbar expand="lg" className="py-3" style={{ backgroundColor: '#f8f9fa' }}>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto d-flex align-items-center">
            {/* Branch Selector */}
            <Dropdown className="me-4">
              <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                {selectedBranch ? selectedBranch.name : 'All'} {/* Default to 'All' if no branch is selected */}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  active={!selectedBranch} // Highlight "All" if no branch is selected
                  onClick={() => handleBranchChange(null)}
                >
                  All
                </Dropdown.Item>
                {userBranches.length > 0 ? (
                  userBranches.map((branch) => (
                    <Dropdown.Item
                      key={branch._id}
                      active={selectedBranch && selectedBranch._id === branch._id} // Highlight selected branch
                      onClick={() => handleBranchChange(branch)} // Selects the clicked branch
                    >
                      {branch.name}
                    </Dropdown.Item>
                  ))
                ) : (
                  <Dropdown.Item disabled>No branches available</Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>

            {/* Notifications */}
            <Nav.Item className="me-3">
              <Button variant="link" className="text-dark position-relative">
                <FaBell size={22} />
                <span className="badge rounded-pill bg-danger position-absolute top-0 start-100 translate-middle">
                  5
                </span>
              </Button>
            </Nav.Item>

            {/* User Profile */}
            <Nav.Item>
              <Dropdown align="end">
                <Dropdown.Toggle variant="link" className="text-dark">
                  <FaUserCircle size={22} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={handleProfileClick}>Profile</Dropdown.Item>
                  <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      {/* Alert for no branch selected */}
      {alert.show && (
        <Alert variant={alert.type} onClose={() => setAlert({ ...alert, show: false })} dismissible>
          {alert.message}
        </Alert>
      )}

      {/* Profile Modal */}
      <Modal show={showProfile} onHide={handleCloseProfile} centered>
        <Modal.Header closeButton>
          <Modal.Title>User Profile</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4">
          {user ? (
            <div>
              <h5 className="text-center mb-4">Profile Details</h5>
              <div className="mb-3">
                <p className="fw-bold">Name:</p>
                <p className="text-muted">{user.username}</p>
              </div>
              <div className="mb-3">
                <p className="fw-bold">Role:</p>
                <p className="text-muted">{user.role}</p>
              </div>
              <div className="mb-3">
                <p className="fw-bold">Branch:</p>
                <p className="text-muted">{selectedBranch ? selectedBranch.name : 'N/A'}</p>
              </div>
            </div>
          ) : (
            <p className="text-center">Loading....</p>
          )}
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button variant="secondary" onClick={handleCloseProfile} className="px-4">
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TopBar;



// import React, { useState, useEffect } from 'react';
// import { Navbar, Nav, Dropdown, Button, Container, Modal, Alert } from 'react-bootstrap';
// import { FaBell, FaUserCircle } from 'react-icons/fa';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { useNavigate } from 'react-router-dom';
// import config from '../../components/config/config';
// import { useBranch } from '../Context/BranchContext';

// const TopBar = ({ refreshKey }) => {
//   const navigate = useNavigate();
//   const { selectedBranch, setSelectedBranch, userBranches, refreshBranches } = useBranch(); // Use the BranchContext
//   const [user, setUser] = useState(null);
//   const [showProfile, setShowProfile] = useState(false);
//   const [alert, setAlert] = useState({ show: false, message: '', type: '' });

//   // Fetch user details using JWT token
//   useEffect(() => {
//     const fetchUser = async () => {
//       try {
//         const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
//         if (!token) {
//           console.error('No token found'); // Debugging
//           navigate('/login'); // Redirect to login if token is missing
//           return;
//         }

//         const response = await fetch(`${config.API_BASE_URL}/api/profile`, {
//           headers: {
//             'Authorization': `Bearer ${token}`,
//           },
//         });

//         if (!response.ok) {
//           const errorMessage = await response.text(); // Get the error message from the response
//           throw new Error(`Failed to fetch user data: ${response.status} - ${errorMessage}`);
//         }

//         const data = await response.json();
//         setUser(data);
//         console.log('User fetched:', data); // Debugging
//       } catch (error) {
//         console.error('Error fetching user data:', error);
//         navigate('/login'); // Redirect to login on error
//       }
//     };

//     fetchUser();
//   }, []);

//   const handleBranchChange = (branch) => {
//     setSelectedBranch(branch);
 
//      // Set the branch that was clicked
//   };
  
  
//   // const handleBranchChange = (branch) => {
//   //   setSelectedBranch(branch); // Update the selected branch in context
//   //   refreshBranches(); // Refresh branches data if needed
//   //   setAlert({ show: false, message: '', type: '' }); // Reset any alerts when a branch is selected
//   // };

//   const handleProfileClick = () => {
//     setShowProfile(true);
//   };

//   const handleCloseProfile = () => {
//     setShowProfile(false);
//   };

//   const handleLogout = () => {
//     localStorage.removeItem('token'); // Remove token from localStorage
//     navigate('/login'); // Redirect to login page
//   };

//   return (
//     <>
//       <Navbar bg="primary" variant="dark" expand="lg" className="shadow-sm py-3">
//         <Container fluid>
//           <Navbar.Brand href="#home" className="fw-bold text-light">
//             Admin Panel
//           </Navbar.Brand>
//           <Navbar.Collapse id="basic-navbar-nav">
//             <Nav className="ms-auto d-flex align-items-center">
//               {/* Branch Selector */}
//               <Dropdown className="me-4">
//                 <Dropdown.Toggle variant="outline-light" id="dropdown-basic">
//                   {selectedBranch ? selectedBranch.name : 'All'} {/* Default to 'All' if no branch is selected */}
//                 </Dropdown.Toggle>
//                 <Dropdown.Menu>
//                   <Dropdown.Item
//                     active={!selectedBranch} // Highlight "All" if no branch is selected
//                     onClick={() => handleBranchChange(null)}
//                   >
//                     All
//                   </Dropdown.Item>
//                   {userBranches.length > 0 ? (
//                     userBranches.map((branch) => (
//                       <Dropdown.Item
//                         key={branch._id}
//                         active={selectedBranch && selectedBranch._id === branch._id} // Highlight selected branch
//                         onClick={() => handleBranchChange(branch)} // Selects the clicked branch
//                       >
//                         {branch.name}
//                       </Dropdown.Item>
//                     ))
//                   ) : (
//                     <Dropdown.Item disabled>No branches available</Dropdown.Item>
//                   )}
//                 </Dropdown.Menu>
//               </Dropdown>

//               {/* Notifications */}
//               <Nav.Item className="me-3">
//                 <Button variant="link" className="text-light position-relative">
//                   <FaBell size={22} />
//                   <span className="badge rounded-pill bg-danger position-absolute top-0 start-100 translate-middle">
//                     5
//                   </span>
//                 </Button>
//               </Nav.Item>

//               {/* User Profile */}
//               <Nav.Item>
//                 <Dropdown align="end">
//                   <Dropdown.Toggle variant="link" className="text-light">
//                     <FaUserCircle size={22} />
//                   </Dropdown.Toggle>
//                   <Dropdown.Menu>
//                     <Dropdown.Item onClick={handleProfileClick}>Profile</Dropdown.Item>
//                     <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
//                   </Dropdown.Menu>
//                 </Dropdown>
//               </Nav.Item>
//             </Nav>
//           </Navbar.Collapse>
//         </Container>
//       </Navbar>

//       {/* Alert for no branch selected */}
//       {alert.show && (
//         <Alert variant={alert.type} onClose={() => setAlert({ ...alert, show: false })} dismissible>
//           {alert.message}
//         </Alert>
//       )}

//       {/* Profile Modal */}
//       <Modal show={showProfile} onHide={handleCloseProfile} centered>
//         <Modal.Header closeButton className="bg-primary text-white">
//           <Modal.Title>User Profile</Modal.Title>
//         </Modal.Header>
//         <Modal.Body className="p-4">
//           {user ? (
//             <div>
//               <h5 className="text-center mb-4">Profile Details</h5>
//               <div className="mb-3">
//                 <p className="fw-bold">Name:</p>
//                 <p className="text-muted">{user.username}</p>
//               </div>
//               <div className="mb-3">
//                 <p className="fw-bold">Role:</p>
//                 <p className="text-muted">{user.role}</p>
//               </div>
//               <div className="mb-3">
//                 <p className="fw-bold">Branch:</p>
//                 <p className="text-muted">{selectedBranch ? selectedBranch.name : 'N/A'}</p>
//               </div>
//             </div>
//           ) : (
//             <p className="text-center">Loading....</p>
//           )}
//         </Modal.Body>
//         <Modal.Footer className="justify-content-center">
//           <Button variant="secondary" onClick={handleCloseProfile} className="px-4">
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// };

// export default TopBar;

// import React, { useState, useEffect } from 'react';
// import { Navbar, Nav, Dropdown, Button, Container, Modal, Alert } from 'react-bootstrap';
// import { FaBell, FaUserCircle } from 'react-icons/fa';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { useNavigate } from 'react-router-dom';
// import config from '../../components/config/config';
// import { useBranch } from '../Context/BranchContext';

// const TopBar = ({ refreshKey }) => {
//   const navigate = useNavigate();
//   const { selectedBranch, setSelectedBranch, userBranches, refreshBranches } = useBranch(); // Use the BranchContext
//   const [user, setUser] = useState(null);
//   const [showProfile, setShowProfile] = useState(false);
//   const [alert, setAlert] = useState({ show: false, message: '', type: '' });

//   // Fetch user details using JWT token
//   useEffect(() => {
//     const fetchUser = async () => {
//       try {
//         const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
//         if (!token) {
//           console.error('No token found'); // Debugging
//           navigate('/login'); // Redirect to login if token is missing
//           return;
//         }

//         const response = await fetch(`${config.API_BASE_URL}/api/profile`, {
//           headers: {
//             'Authorization': `Bearer ${token}`,
//           },
//         });

//         if (!response.ok) {
//           const errorMessage = await response.text(); // Get the error message from the response
//           throw new Error(`Failed to fetch user data: ${response.status} - ${errorMessage}`);
//         }

//         const data = await response.json();
//         setUser(data);
//         console.log('User fetched:', data); // Debugging
//       } catch (error) {
//         console.error('Error fetching user data:', error);
//         navigate('/login'); // Redirect to login on error
//       }
//     };

//     fetchUser();
//   }, []);

//   const handleBranchChange = (branch) => {
//     // Always set the selected branch without conditionally allowing unselection
//     setSelectedBranch(branch); // Update the selected branch in context
//     refreshBranches(); // Refresh branches data if needed
//     setAlert({ show: false, message: '', type: '' }); // Reset any alerts when a branch is selected
//   };

//   const handleProfileClick = () => {
//     setShowProfile(true);
//   };

//   const handleCloseProfile = () => {
//     setShowProfile(false);
//   };

//   const handleLogout = () => {
//     localStorage.removeItem('token'); // Remove token from localStorage
//     navigate('/login'); // Redirect to login page
//   };

//   return (
//     <>
//       <Navbar bg="primary" variant="dark" expand="lg" className="shadow-sm py-3">
//         <Container fluid>
//           <Navbar.Brand href="#home" className="fw-bold text-light">
//             Admin Panel
//           </Navbar.Brand>
//           <Navbar.Collapse id="basic-navbar-nav">
//             <Nav className="ms-auto d-flex align-items-center">
//               {/* Branch Selector */}

//               <Dropdown className="me-4">
//       <Dropdown.Toggle variant="outline-light" id="dropdown-basic">
//         {selectedBranch ? selectedBranch.name : 'All'} {/* Default to 'All' if no branch is selected */}
//       </Dropdown.Toggle>
//       <Dropdown.Menu>
//         <Dropdown.Item onClick={() => handleBranchChange(null)}>All</Dropdown.Item> {/* Option for all branches */}
//         {userBranches.length > 0 ? (
//           userBranches.map((branch) => (
//             <Dropdown.Item
//               key={branch._id}
//               onClick={() => handleBranchChange(branch)} // Selects the clicked branch
//             >
//               {branch.name}
//             </Dropdown.Item>
//           ))
//         ) : (
//           <Dropdown.Item disabled>No branches available</Dropdown.Item>
//         )}
//       </Dropdown.Menu>
//     </Dropdown>
             

//               {/* Notifications */}
//               <Nav.Item className="me-3">
//                 <Button variant="link" className="text-light position-relative">
//                   <FaBell size={22} />
//                   <span className="badge rounded-pill bg-danger position-absolute top-0 start-100 translate-middle">
//                     5
//                   </span>
//                 </Button>
//               </Nav.Item>

//               {/* User Profile */}
//               <Nav.Item>
//                 <Dropdown align="end">
//                   <Dropdown.Toggle variant="link" className="text-light">
//                     <FaUserCircle size={22} />
//                   </Dropdown.Toggle>
//                   <Dropdown.Menu>
//                     <Dropdown.Item onClick={handleProfileClick}>Profile</Dropdown.Item>
//                     <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
//                   </Dropdown.Menu>
//                 </Dropdown>
//               </Nav.Item>
//             </Nav>
//           </Navbar.Collapse>
//         </Container>
//       </Navbar>

//       {/* Alert for no branch selected */}
//       {alert.show && (
//         <Alert variant={alert.type} onClose={() => setAlert({ ...alert, show: false })} dismissible>
//           {alert.message}
//         </Alert>
//       )}

//       {/* Profile Modal */}
//       <Modal show={showProfile} onHide={handleCloseProfile} centered>
//         <Modal.Header closeButton className="bg-primary text-white">
//           <Modal.Title>User Profile</Modal.Title>
//         </Modal.Header>
//         <Modal.Body className="p-4">
//           {user ? (
//             <div>
//               <h5 className="text-center mb-4">Profile Details</h5>
//               <div className="mb-3">
//                 <p className="fw-bold">Name:</p>
//                 <p className="text-muted">{user.username}</p>
//               </div>
//               <div className="mb-3">
//                 <p className="fw-bold">Role:</p>
//                 <p className="text-muted">{user.role}</p>
//               </div>
//               <div className="mb-3">
//                 <p className="fw-bold">Branch:</p>
//                 <p className="text-muted">{selectedBranch ? selectedBranch.name : 'N/A'}</p>
//               </div>
//               {/* Add more user details here */}
//             </div>
//           ) : (
//             <p className="text-center">Loading....</p>
//           )}
//         </Modal.Body>
//         <Modal.Footer className="justify-content-center">
//           <Button variant="secondary" onClick={handleCloseProfile} className="px-4">
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// };

// export default TopBar;





 {/* <Dropdown className="me-4">
                <Dropdown.Toggle variant="outline-light" id="dropdown-basic">
                  {selectedBranch ? selectedBranch.name : 'Select Branch'}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {userBranches.length > 0 ? (
                    userBranches.map((branch) => (
                      <Dropdown.Item
                        key={branch._id}
                        onClick={() => handleBranchChange(branch)} // Always selects the clicked branch
                      >
                        {branch.name}
                      </Dropdown.Item>
                    ))
                  ) : (
                    <Dropdown.Item disabled>No branches available</Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown> */}


// import React, { useState, useEffect } from 'react';
// import { Navbar, Nav, Dropdown, Button, Container, Modal } from 'react-bootstrap';
// import { FaBell, FaUserCircle } from 'react-icons/fa';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import { useNavigate } from 'react-router-dom';
// import config from '../../components/config/config';
// import { useBranch } from '../Context/BranchContext';

// const TopBar = ({ refreshKey }) => {
//   const navigate = useNavigate();
//   const [branches, setBranches] = useState([]);
//   const [userBranches, setUserBranches] = useState([]); // Branches available to the user
//   const [selectedBranch, setSelectedBranch] = useState(null);
//   const [user, setUser] = useState(null);
//   const [showProfile, setShowProfile] = useState(false);
//   const [isBranchesLoaded, setIsBranchesLoaded] = useState(false);
  

//   // Fetch branches from the API
//   useEffect(() => {
//     const fetchBranches = async () => {
//       try {
//         const response = await fetch(`${config.API_BASE_URL}/api/branch`);
//         if (!response.ok) throw new Error('Failed to fetch branches');
//         const data = await response.json();
//         setBranches(data);
//         setIsBranchesLoaded(true); // Set branches loaded to true
//         console.log('Branches fetched:', data); // Debugging
//       } catch (error) {
//         console.error('Error fetching branches:', error);
//       }
//     };

//     fetchBranches();
//   }, [refreshKey]);

//   // Fetch user details using JWT token
//   useEffect(() => {
//     const fetchUser = async () => {
//       try {
//         if (!isBranchesLoaded) return; // Wait until branches are loaded

//         const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
//         if (!token) {
//           console.error('No token found'); // Debugging
//           navigate('/login'); // Redirect to login if token is missing
//           return;
//         }

//         const response = await fetch(`${config.API_BASE_URL}/api/profile`, {
//           headers: {
//             'Authorization': `Bearer ${token}`,
//           },
//         });

//         if (!response.ok) {
//           const errorMessage = await response.text(); // Get the error message from the response
//           throw new Error(`Failed to fetch user data: ${response.status} - ${errorMessage}`);
//         }

//         const data = await response.json();
//         setUser(data);
//         console.log('User fetched:', data); // Debugging

//         // Determine branches available to the user based on role
//         const assignedBranch = branches.find(branch => branch._id === data.branchId);
//         if (data.role === 'Admin') {
//           setUserBranches(branches); // Admin sees all branches
//         } else {
//           setUserBranches(assignedBranch ? [assignedBranch] : []); // Non-admin sees only their branch
//         }

//         // Set the selected branch to the user's branch
//         setSelectedBranch(assignedBranch);

//         console.log('User branches available:', userBranches); // Debugging
//       } catch (error) {
//         console.error('Error fetching user data:', error);
//         navigate('/login'); // Redirect to login on error
//       }
//     };

//     fetchUser();
//   }, [isBranchesLoaded, branches]);

//   const handleBranchChange = (branch) => {
//     setSelectedBranch(branch);
//   };

//   const handleProfileClick = () => {
//     setShowProfile(true);
//   };

//   const handleCloseProfile = () => {
//     setShowProfile(false);
//   };

//   const handleLogout = () => {
//     localStorage.removeItem('token'); // Remove token from localStorage
//     navigate('/login'); // Redirect to login page
//   };



//   return (
//     <>
//       <Navbar bg="primary" variant="dark" expand="lg" className="shadow-sm py-3">
//         <Container fluid>
//           <Navbar.Brand href="#home" className="fw-bold text-light">
//             Admin Panel
//           </Navbar.Brand>
//           <Navbar.Collapse id="basic-navbar-nav">
//             <Nav className="ms-auto d-flex align-items-center">
//               {/* Branch Selector */}
//               <Dropdown className="me-4">
//                 <Dropdown.Toggle variant="outline-light" id="dropdown-basic">
//                   {selectedBranch ? selectedBranch.name : 'Select Branch'}
//                 </Dropdown.Toggle>
//                 <Dropdown.Menu>
//                   {userBranches.length > 0 ? (
//                     userBranches.map((branch) => (
//                       <Dropdown.Item
//                         key={branch._id}
//                         onClick={() => handleBranchChange(branch)}
//                       >
//                         {branch.name}
//                       </Dropdown.Item>
//                     ))
//                   ) : (
//                     <Dropdown.Item disabled>No branches available</Dropdown.Item>
//                   )}
//                 </Dropdown.Menu>
//               </Dropdown>

//               {/* Notifications */}
//               <Nav.Item className="me-3">
//                 <Button variant="link" className="text-light position-relative">
//                   <FaBell size={22} />
//                   <span className="badge rounded-pill bg-danger position-absolute top-0 start-100 translate-middle">
//                     5
//                   </span>
//                 </Button>
//               </Nav.Item>

//               {/* User Profile */}
//               <Nav.Item>
//                 <Dropdown align="end">
//                   <Dropdown.Toggle variant="link" className="text-light">
//                     <FaUserCircle size={22} />
//                   </Dropdown.Toggle>
//                   <Dropdown.Menu>
//                     <Dropdown.Item onClick={handleProfileClick}>Profile</Dropdown.Item>
//                     <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
//                   </Dropdown.Menu>
//                 </Dropdown>
//               </Nav.Item>
//             </Nav>
//           </Navbar.Collapse>
//         </Container>
//       </Navbar>

//       {/* Profile Modal */}
//       <Modal show={showProfile} onHide={handleCloseProfile} centered>
//         <Modal.Header closeButton className="bg-primary text-white">
//           <Modal.Title>User Profile</Modal.Title>
//         </Modal.Header>
//         <Modal.Body className="p-4">
//           {user ? (
//             <div>
//               <h5 className="text-center mb-4">Profile Details</h5>
//               <div className="mb-3">
//                 <p className="fw-bold">Name:</p>
//                 <p className="text-muted">{user.username}</p>
//               </div>
//               <div className="mb-3">
//                 <p className="fw-bold">Role:</p>
//                 <p className="text-muted">{user.role}</p>
//               </div>
//               <div className="mb-3">
//                 <p className="fw-bold">Branch:</p>
//                 <p className="text-muted">{selectedBranch ? selectedBranch.name : 'N/A'}</p>
//               </div>
//               {/* Add more user details here */}
//             </div>
//           ) : (
//             <p className="text-center">Loading....</p>
//           )}
//         </Modal.Body>
//         <Modal.Footer className="justify-content-center">
//           <Button variant="secondary" onClick={handleCloseProfile} className="px-4">
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </>
//   );
// };

// export default TopBar;
