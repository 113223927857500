import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Card, InputGroup, FormControl, Alert, Spinner } from 'react-bootstrap';
import axios from 'axios';

const WebsiteContentManagement = () => {
  const [settings, setSettings] = useState({
    logo: '',
    websiteName: '',
    websiteTitle: '',
    websiteDescription: '',
    metaContents: '',
    keywords: '',
    termsAndPolicies: '',
    footer: '',
    socialLinks: {
      facebook: '',
      twitter: '',
      linkedin: '',
    },
    telephoneNo: '',
    header: '',
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [imagePreview, setImagePreview] = useState(null); // State for image preview

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await axios.get('https://control.novelkoirala.com:5001/api/settings');
        setSettings({
          logo: '',
          websiteName: response.data.websiteName || '',
          websiteTitle: response.data.websiteTitle || '',
          websiteDescription: response.data.websiteDescription || '',
          metaContents: response.data.metaContents || '',
          keywords: response.data.keywords || '',
          termsAndPolicies: response.data.termsAndPolicies || '',
          footer: response.data.footer || '',
          socialLinks: {
            facebook: response.data.socialLinks?.facebook || '',
            twitter: response.data.socialLinks?.twitter || '',
            linkedin: response.data.socialLinks?.linkedin || '',
          },
          telephoneNo: response.data.telephoneNo || '',
          header: response.data.header || '',
        });
        setError(null);
      } catch (error) {
        setError('Failed to fetch settings. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchSettings();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSettings({ ...settings, [name]: value });
  };

  const handleSocialLinkChange = (e) => {
    const { name, value } = e.target;
    setSettings({
      ...settings,
      socialLinks: { ...settings.socialLinks, [name]: value },
    });
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    setSettings({ ...settings, logo: file });
    // Create a preview URL for the selected image
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setImagePreview(previewUrl);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      if (settings.logo) formData.append('logo', settings.logo);
      if (settings.websiteName) formData.append('websiteName', settings.websiteName);
      if (settings.websiteTitle) formData.append('websiteTitle', settings.websiteTitle);
      if (settings.websiteDescription) formData.append('websiteDescription', settings.websiteDescription);
      if (settings.metaContents) formData.append('metaContents', settings.metaContents);
      if (settings.keywords) formData.append('keywords', settings.keywords);
      if (settings.termsAndPolicies) formData.append('termsAndPolicies', settings.termsAndPolicies);
      if (settings.footer) formData.append('footer', settings.footer);
      if (settings.telephoneNo) formData.append('telephoneNo', settings.telephoneNo);
      if (settings.header) formData.append('header', settings.header);
      
      // Convert social links object to a JSON string
      formData.append('socialLinks', JSON.stringify(settings.socialLinks));

      await axios.post('https://control.novelkoirala.com:5001/api/settings', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      alert('Settings updated successfully!');
    } catch (error) {
      setError('Failed to update settings. Please try again.');
    }
  };

  if (loading) {
    return (
      <Container fluid className="mt-4">
        <div className="d-flex justify-content-center my-4">
          <Spinner animation="border" variant="primary" />
        </div>
      </Container>
    );
  }

  return (
    <Container fluid className="mt-4">
      <Card className="p-4 shadow-sm">
        <h2 className="text-center text-primary mb-4">Website Content Management</h2>
        {error && (
          <Row className="mb-3">
            <Col>
              <Alert variant="danger">{error}</Alert>
            </Col>
          </Row>
        )}
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="formLogo">
                <Form.Label className="text-info">Website Logo</Form.Label>
                <Form.Control type="file" onChange={handleLogoChange} />
                {imagePreview && (
                  <img
                    src={imagePreview}
                    alt="Preview"
                    style={{ maxWidth: '100%', marginTop: '10px', borderRadius: '5px' }}
                  />
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formWebsiteName">
                <Form.Label className="text-info">Website Name</Form.Label>
                <Form.Control
                  type="text"
                  name="websiteName"
                  value={settings.websiteName}
                  onChange={handleChange}
                  placeholder="Enter website name"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="formWebsiteTitle">
                <Form.Label className="text-info">Website Title</Form.Label>
                <Form.Control
                  type="text"
                  name="websiteTitle"
                  value={settings.websiteTitle}
                  onChange={handleChange}
                  placeholder="Enter website title"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formWebsiteDescription">
                <Form.Label className="text-info">Website Description</Form.Label>
                <Form.Control
                  type="text"
                  name="websiteDescription"
                  value={settings.websiteDescription}
                  onChange={handleChange}
                  placeholder="Enter website description"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={12}>
              <Form.Group controlId="formMetaContents">
                <Form.Label className="text-info">Meta Contents</Form.Label>
                <Form.Control
                  as="textarea"
                  name="metaContents"
                  value={settings.metaContents}
                  onChange={handleChange}
                  placeholder="Enter meta contents"
                  rows={3}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="formKeywords">
                <Form.Label className="text-info">Keywords</Form.Label>
                <Form.Control
                  type="text"
                  name="keywords"
                  value={settings.keywords}
                  onChange={handleChange}
                  placeholder="Enter keywords"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group controlId="formTelephoneNo">
                <Form.Label className="text-info">Telephone Number</Form.Label>
                <Form.Control
                  type="text"
                  name="telephoneNo"
                  value={settings.telephoneNo}
                  onChange={handleChange}
                  placeholder="Enter telephone number"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={12}>
              <Form.Group controlId="formTermsAndPolicies">
                <Form.Label className="text-info">Terms and Policies</Form.Label>
                <Form.Control
                  as="textarea"
                  name="termsAndPolicies"
                  value={settings.termsAndPolicies}
                  onChange={handleChange}
                  placeholder="Enter terms and policies"
                  rows={3}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={12}>
              <Form.Group controlId="formFooter">
                <Form.Label className="text-info">Footer</Form.Label>
                <Form.Control
                  as="textarea"
                  name="footer"
                  value={settings.footer}
                  onChange={handleChange}
                  placeholder="Enter footer content"
                  rows={3}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={6}>
              <Form.Group controlId="formHeader">
                <Form.Label className="text-info">Header</Form.Label>
                <Form.Control
                  type="text"
                  name="header"
                  value={settings.header}
                  onChange={handleChange}
                  placeholder="Enter header content"
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col md={12}>
              <Form.Label className="text-info">Social Links</Form.Label>
              <InputGroup className="mb-3">
                <FormControl
                  placeholder="Facebook Link"
                  name="facebook"
                  value={settings.socialLinks.facebook}
                  onChange={handleSocialLinkChange}
                />
                <FormControl
                  placeholder="Twitter Link"
                  name="twitter"
                  value={settings.socialLinks.twitter}
                  onChange={handleSocialLinkChange}
                />
                <FormControl
                  placeholder="LinkedIn Link"
                  name="linkedin"
                  value={settings.socialLinks.linkedin}
                  onChange={handleSocialLinkChange}
                />
              </InputGroup>
            </Col>
          </Row>

          <Button variant="primary" type="submit">
            Save Settings
          </Button>
        </Form>
      </Card>
    </Container>
  );
};

export default WebsiteContentManagement;
