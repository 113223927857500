import React, { useState, useEffect } from 'react';
import { Container, Button, Table, Modal, Alert } from 'react-bootstrap';
import axios from 'axios';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';

const ViewSubjects = () => {
  const [subjects, setSubjects] = useState([]);
  const [error, setError] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  // Fetch subjects from the backend API
  const fetchSubjects = async () => {
    try {
      const response = await axios.get('http://localhost:5001/api/subjects');
      setSubjects(response.data.subjects); // Adjust based on your API response
    } catch (error) {
      console.error('Error fetching subjects:', error);
      setError('Failed to fetch subjects. Please try again later.');
    }
  };

  useEffect(() => {
    fetchSubjects();
  }, []);

  const handleDelete = async () => {
    try {
      await axios.delete(`http://localhost:5001/api/subjects/${deleteId}`);
      setSubjects(subjects.filter(subject => subject._id !== deleteId)); // Adjust based on your API response
      setShowDeleteModal(false);
    } catch (error) {
      console.error('Error deleting subject:', error);
      setError('Failed to delete subject. Please try again later.');
    }
  };

  return (
    <Container className="my-4">
      <h1 className="text-center mb-4 text-primary">View Subjects</h1>
      <Button variant="primary" href="/subjects/create" className="mb-3">
        Add New Subject
      </Button>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Class</th>
            <th>Subject Name</th>
            <th>Marks</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {subjects.map((subject) => (
            <tr key={subject._id}>
              <td>{subject.className}</td>
              <td>{subject.subjectName}</td>
              <td>{subject.marks}</td>
              <td>
                <Button variant="warning" className="me-2">
                  <EditIcon />
                </Button>
                <Button variant="danger" onClick={() => { setShowDeleteModal(true); setDeleteId(subject._id); }}>
                  <DeleteIcon />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      {error && (
        <Alert variant="danger" className="mt-3">
          {error}
        </Alert>
      )}

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this subject?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>Cancel</Button>
          <Button variant="danger" onClick={handleDelete}>Delete</Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ViewSubjects;
