import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Form, Button, Row, Col, Card, Alert, Spinner } from 'react-bootstrap';
import axios from 'axios';

const EditClass = () => {
  const { id } = useParams(); // Get the class ID from the URL
  const navigate = useNavigate(); // Use useNavigate for navigation
  const [classData, setClassData] = useState({ name: '', teacher: '', tuitionFees: '' });
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchClass = async () => {
      try {
        const response = await axios.get(`https://control.novelkoirala.com:5001/api/classes/${id}`);
        setClassData(response.data.class || response.data); // Adjust based on the actual data structure
        setError(null);
      } catch (error) {
        setError('Failed to fetch class details. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchClass();
  }, [id]);

  const handleChange = (e) => {
    setClassData({ ...classData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`https://control.novelkoirala.com:5001/api/classes/${id}`, classData);
      navigate('/classes/view'); // Redirect to the classes list page after successful update
    } catch (error) {
      setError('Failed to update class. Please try again.');
    }
  };

  if (loading) {
    return (
      <Container className="my-5">
        <div className="d-flex justify-content-center my-4">
          <Spinner animation="border" variant="primary" />
        </div>
      </Container>
    );
  }

  return (
    <Container className="my-5">
      <Card className="p-4 shadow-sm">
        <h2 className="text-center text-primary mb-4">Edit Class</h2>
        {error && (
          <Row className="mb-3">
            <Col>
              <Alert variant="danger">
                {error}
              </Alert>
            </Col>
          </Row>
        )}
        {classData && (
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="formClassName">
                  <Form.Label className="text-info">Class Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={classData.name}
                    onChange={handleChange}
                    placeholder="Enter class name"
                    required
                  />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="formClassTeacher">
                  <Form.Label className="text-info">Class Teacher</Form.Label>
                  <Form.Control
                    type="text"
                    name="teacher"
                    value={classData.teacher}
                    onChange={handleChange}
                    placeholder="Enter teacher's name"
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="formClassTuitionFees">
                  <Form.Label className="text-info">Monthly Tuition Fees</Form.Label>
                  <Form.Control
                    type="number"
                    name="tuitionFees"
                    value={classData.tuitionFees}
                    onChange={handleChange}
                    placeholder="Enter monthly tuition fees"
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col>
                <Button type="submit" variant="primary" className="w-100">
                  Update Class
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Card>
    </Container>
  );
};

export default EditClass;
