import React from 'react';
import { Modal, Button, ListGroup } from 'react-bootstrap';

const ViewDetailsModal = ({ show, handleClose, visitor }) => {
  if (!visitor) {
    return null; // Return nothing if visitor is null or undefined
  }

  const visitHistory = Array.isArray(visitor.visitHistory) ? visitor.visitHistory : [];

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="text-primary">Visitor Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">
          <h5 className="text-muted">Basic Information</h5>
          <p><strong>Name:</strong> <span className="text-dark">{visitor.name}</span></p>
          <p><strong>Purpose of Visit:</strong> <span className="text-dark">{visitor.purposeOfVisit}</span></p>
          <p><strong>Time In:</strong> <span className="text-dark">{visitor.timeIn}</span></p>
          <p><strong>Time Out:</strong> <span className="text-dark">{visitor.timeOut}</span></p>
          <p><strong>Comments:</strong> <span className="text-dark">{visitor.comments}</span></p>
        </div>
        <div>
          <h5 className="text-muted">Visit History</h5>
          <ListGroup>
            {visitHistory.length > 0 ? (
              visitHistory.map((visit, index) => (
                <ListGroup.Item key={index} className="border-0">
                  <i className="bi bi-clock"></i> {visit}
                </ListGroup.Item>
              ))
            ) : (
              <ListGroup.Item className="text-center">No visit history available</ListGroup.Item>
            )}
          </ListGroup>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ViewDetailsModal;
