import React, { useState, useEffect } from 'react';
import { Button, Form, Container, Alert, Spinner, Card } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import config from '../../components/config/config';
import { logActivity } from '../ActivityLogs/ActivityLogger';

const AddClass = () => {
  const [className, setClassName] = useState('');
  const [tuitionFees, setTuitionFees] = useState(''); 
  const [selectedTeacher, setSelectedTeacher] = useState('');
  const [teachers, setTeachers] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('success');
  const [loading, setLoading] = useState(false);
  const [teachersLoading, setTeachersLoading] = useState(true);
  const [branches, setBranches] = useState([]);
  const [userBranches, setUserBranches] = useState([]); // Branches available to the user
  const [selectedBranch, setSelectedBranch] = useState(null); // Store the selected branch
  const [user, setUser] = useState(null);
  const [isBranchesLoaded, setIsBranchesLoaded] = useState(false);
  
  const navigate = useNavigate();

  // Fetch branches
  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await fetch(`${config.API_BASE_URL}/api/branch`);
        if (!response.ok) throw new Error('Failed to fetch branches');
        const data = await response.json();
        setBranches(data);
        setIsBranchesLoaded(true); // Set branches loaded to true
        console.log('Branches fetched:', data); // Debugging
      } catch (error) {
        console.error('Error fetching branches:', error);
      }
    };

    fetchBranches();
  }, []);

  // Fetch user details using JWT token
  useEffect(() => {
    const fetchUser = async () => {
      try {
        if (!isBranchesLoaded) return; // Wait until branches are loaded

        const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
        if (!token) {
          console.error('No token found');
          return; // Exit if no token is found
        }

        const response = await fetch(`${config.API_BASE_URL}/api/profile`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          const errorMessage = await response.text();
          throw new Error(`Failed to fetch user data: ${response.status} - ${errorMessage}`);
        }

        const data = await response.json();
        setUser(data);

        // Determine branches available to the user based on role
        const assignedBranch = branches.find(branch => branch._id === data.branchId);
        if (data.role === 'Admin') {
          setUserBranches(branches); // Admin sees all branches
        } else {
          setUserBranches(assignedBranch ? [assignedBranch] : []); // Non-admin sees only their branch
        }

        // Set the selected branch to the user's branch
        setSelectedBranch(assignedBranch?._id || null); // Set branch ID if available

      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUser();
  }, [isBranchesLoaded, branches]);

  // Fetch teachers
  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        const response = await axios.get('http://localhost:5001/api/employees');
        const filteredTeachers = response.data.filter(employee => employee.role === 'teacher');
        
        if (filteredTeachers.length === 0) {
          setAlertMessage('No teachers found. Please add teachers first.');
          setAlertType('danger');
          setShowAlert(true);
        } else {
          setTeachers(filteredTeachers);
        }
        setTeachersLoading(false);
      } catch (error) {
        console.error('Error fetching employees:', error.response?.data || error.message);
        setAlertMessage('Failed to load teachers. Please try again.');
        setAlertType('danger');
        setShowAlert(true);
        setTeachersLoading(false);
      }
    };

    fetchTeachers();
  }, []);

  const handleBranchChange = (e) => {
    const selectedBranchId = e.target.value;
    setSelectedBranch(selectedBranchId); // Update selected branch
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation
    if (!className || !tuitionFees || !selectedTeacher || !selectedBranch) {
      setAlertMessage('All fields are required');
      setAlertType('danger');
      setShowAlert(true);
      return;
    }

    // Prepare the data, including selected branch
    const classData = {
      name: className,
      tuitionFees: parseFloat(tuitionFees),
      teacher: selectedTeacher,
      branch: selectedBranch, // Include selected branch
    };

    setLoading(true);

    try {
      // Make the POST request to the backend
      await axios.post('http://localhost:5001/api/classes', classData, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      setAlertMessage('Class added successfully!');
      await logActivity(`${user.username}`, 'Add', `Added Class: ${classData.name}`);
      setAlertType('success');
      setShowAlert(true);
      navigate('/classes/view'); // Redirect to view classes page

      // Clear form fields
      setClassName('');
      setTuitionFees('');
      setSelectedTeacher('');
      setSelectedBranch(null); // Clear selected branch
    } catch (error) {
      console.error('Error adding class:', error.response?.data || error.message);
      setAlertMessage('Failed to add class. Please try again.');
      setAlertType('danger');
      setShowAlert(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="my-5">
      <Form.Group className="mb-3">
        <Form.Label>Select Branch</Form.Label>
        <Form.Select value={selectedBranch || ''} onChange={handleBranchChange} required>
          <option value="">Select Branch</option>
          {userBranches.length > 0 ? (
            userBranches.map((branch) => (
              <option key={branch._id} value={branch._id}>
                {branch.name}
              </option>
            ))
          ) : (
            <option disabled>No branches available</option>
          )}
        </Form.Select>
      </Form.Group>

      <Card>
        <Card.Header as="h4" className="text-center">Add New Class</Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formClassName" className="mb-3">
              <Form.Label>Class Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter class name"
                value={className}
                onChange={(e) => setClassName(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formTuitionFees" className="mb-3">
              <Form.Label>Monthly Tuition Fees</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter tuition fees"
                value={tuitionFees}
                onChange={(e) => setTuitionFees(e.target.value)}
                required
              />
            </Form.Group>

            <Form.Group controlId="formTeacher" className="mb-3">
              <Form.Label>Select Class Teacher</Form.Label>
              <Form.Select
                value={selectedTeacher}
                onChange={(e) => setSelectedTeacher(e.target.value)}
                required
                disabled={teachersLoading}
              >
                <option value="">Select a teacher</option>
                {teachers.map(teacher => (
                  <option key={teacher._id} value={teacher._id}>
                    {teacher.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Button variant="primary" type="submit" disabled={loading}>
              {loading ? <Spinner animation="border" size="sm" /> : 'Add Class'}
            </Button>
          </Form>

          {showAlert && (
            <Alert variant={alertType} className="mt-3" onClose={() => setShowAlert(false)} dismissible>
              {alertMessage}
            </Alert>
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default AddClass;



// import React, { useState, useEffect } from 'react';
// import { Button, Form, Container, Alert, Spinner, Card } from 'react-bootstrap';
// import { useNavigate } from 'react-router-dom';
// import axios from 'axios';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import config from '../../components/config/config';

// const AddClass = () => {
//   const [className, setClassName] = useState('');
//   const [tuitionFees, setTuitionFees] = useState(''); 
//   const [selectedTeacher, setSelectedTeacher] = useState('');
//   const [teachers, setTeachers] = useState([]);
//   const [showAlert, setShowAlert] = useState(false);
//   const [alertMessage, setAlertMessage] = useState('');
//   const [alertType, setAlertType] = useState('success');
//   const [loading, setLoading] = useState(false);
//   const [teachersLoading, setTeachersLoading] = useState(true);
//   const [branches, setBranches] = useState([]);
//   const [userBranches, setUserBranches] = useState([]); // Branches available to the user
//   const [selectedBranch, setSelectedBranch] = useState(null);
//   const [user, setUser] = useState(null);
//   const [showProfile, setShowProfile] = useState(false);
//   const [isBranchesLoaded, setIsBranchesLoaded] = useState(false);




  

//   useEffect(() => {
//     const fetchBranches = async () => {
//       try {
//         const response = await fetch(`${config.API_BASE_URL}/api/branch`);
//         if (!response.ok) throw new Error('Failed to fetch branches');
//         const data = await response.json();
//         setBranches(data);
//         setIsBranchesLoaded(true); // Set branches loaded to true
//         console.log('Branches fetched:', data); // Debugging
//       } catch (error) {
//         console.error('Error fetching branches:', error);
//       }
//     };

//     fetchBranches();
//   }, []);

//   // Fetch user details using JWT token
//   useEffect(() => {
//     const fetchUser = async () => {
//       try {
//         if (!isBranchesLoaded) return; // Wait until branches are loaded

//         const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
//         if (!token) {
//           console.error('No token found'); // Debugging
//           // Redirect to login if token is missing
//           return;
//         }

//         const response = await fetch(`${config.API_BASE_URL}/api/profile`, {
//           headers: {
//             'Authorization': `Bearer ${token}`,
//           },
//         });

//         if (!response.ok) {
//           const errorMessage = await response.text(); // Get the error message from the response
//           throw new Error(`Failed to fetch user data: ${response.status} - ${errorMessage}`);
//         }

//         const data = await response.json();
//         setUser(data);
//         console.log('User fetched:', data); // Debugging

//         // Determine branches available to the user based on role
//         const assignedBranch = branches.find(branch => branch._id === data.branchId);
//         if (data.role === 'Admin') {
//           setUserBranches(branches); // Admin sees all branches
//         } else {
//           setUserBranches(assignedBranch ? [assignedBranch] : []); // Non-admin sees only their branch
//         }

//         // Set the selected branch to the user's branch
//         setSelectedBranch(assignedBranch);

//         console.log('User branches available:', userBranches); // Debugging
//       } catch (error) {
//         console.error('Error fetching user data:', error);
//         // Redirect to login on error
//       }
//     };

//     fetchUser();
//   }, [isBranchesLoaded, branches]);
 



  
//   const navigate = useNavigate();

//   useEffect(() => {
//     const fetchTeachers = async () => {
//       try {
//         const response = await axios.get('http://localhost:5001/api/employees');
//         const filteredTeachers = response.data.filter(employee => employee.role === 'teacher');
        
//         if (filteredTeachers.length === 0) {
//           setAlertMessage('No teachers found. Please add teachers first.');
//           setAlertType('danger');
//           setShowAlert(true);
//         } else {
//           setTeachers(filteredTeachers);
//         }
//         setTeachersLoading(false);
//       } catch (error) {
//         console.error('Error fetching employees:', error.response?.data || error.message);
//         setAlertMessage('Failed to load teachers. Please try again.');
//         setAlertType('danger');
//         setShowAlert(true);
//         setTeachersLoading(false);
//       }
//     };

//     fetchTeachers();
//   }, []);

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     // Validation
//     if (!className || !tuitionFees || !selectedTeacher) {
//       setAlertMessage('All fields are required');
//       setAlertType('danger');
//       setShowAlert(true);
//       return;
//     }

//     // Prepare the data
//     const classData = {
//       name: className,
//       tuitionFees: parseFloat(tuitionFees),
//       teacher: selectedTeacher,
//     };

//     setLoading(true);

//     try {
//       // Make the POST request to the backend
//       await axios.post('http://localhost:5001/api/classes', classData, {
//         headers: {
//           'Content-Type': 'application/json',
//         },
//       });

//       setAlertMessage('Class added successfully!');
//       setAlertType('success');
//       setShowAlert(true);
//       navigate('/classes/view'); // Redirect to view classes page

//       // Clear form fields
//       setClassName('');
//       setTuitionFees('');
//       setSelectedTeacher('');
//     } catch (error) {
//       console.error('Error adding class:', error.response?.data || error.message);
//       setAlertMessage('Failed to add class. Please try again.');
//       setAlertType('danger');
//       setShowAlert(true);
//     } finally {
//       setLoading(false);
//     }
//   };
//   const handleBranchChange = (e) => {
//     const selectedBranchId = e.target.value; // Get the branch _id from the dropdown value
//     setSelectedBranch(selectedBranchId); // Update the selected branch state
//     setClassName({ ...className, branch: selectedBranchId }); // Set the branch _id in studentData
//   };

//   return (
//     <Container className="my-5">
//                 <Form.Group className="mb-3">
//   <Form.Label>Select Branch</Form.Label>
//   <Form.Select value={selectedBranch ? selectedBranch : ''} onChange={handleBranchChange}>
//     <option value="">Select Branch</option>
//     {userBranches.length > 0 ? (
//       userBranches.map((branch) => (
//         <option key={branch._id} value={branch._id}>
//           {branch.name}
//         </option>
//       ))
//     ) : (
//       <option disabled>No branches available</option>
//     )}
//   </Form.Select>
// </Form.Group>
//       <Card>
//         <Card.Header as="h4" className="text-center">Add New Class</Card.Header>
//         <Card.Body>
//           <Form onSubmit={handleSubmit}>
//             <Form.Group controlId="formClassName" className="mb-3">
//               <Form.Label>Class Name</Form.Label>
//               <Form.Control
//                 type="text"
//                 placeholder="Enter class name"
//                 value={className}
//                 onChange={(e) => setClassName(e.target.value)}
//                 required
//               />
//             </Form.Group>

//             <Form.Group controlId="formTuitionFees" className="mb-3">
//               <Form.Label>Monthly Tuition Fees</Form.Label>
//               <Form.Control
//                 type="number"
//                 placeholder="Enter tuition fees"
//                 value={tuitionFees}
//                 onChange={(e) => setTuitionFees(e.target.value)}
//                 required
//               />
//             </Form.Group>

//             <Form.Group controlId="formTeacher" className="mb-3">
//               <Form.Label>Select Class Teacher</Form.Label>
//               <Form.Select
//                 value={selectedTeacher}
//                 onChange={(e) => setSelectedTeacher(e.target.value)}
//                 required
//                 disabled={teachersLoading}
//               >
//                 <option value="">Select a teacher</option>
//                 {teachers.map(teacher => (
//                   <option key={teacher._id} value={teacher._id}>
//                     {teacher.name}
//                   </option>
//                 ))}
//               </Form.Select>
//             </Form.Group>

//             <Button variant="primary" type="submit" disabled={loading}>
//               {loading ? <Spinner animation="border" size="sm" /> : 'Add Class'}
//             </Button>
//           </Form>

//           {showAlert && (
//             <Alert variant={alertType} className="mt-3" onClose={() => setShowAlert(false)} dismissible>
//               {alertMessage}
//             </Alert>
//           )}
//         </Card.Body>
//       </Card>
//     </Container>
//   );
// };

// export default AddClass;
