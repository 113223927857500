import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Button, Container, Row, Col, Spinner, Alert } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

const EditStudent = () => {
  const { studentId } = useParams();
  const navigate = useNavigate();
  const [studentData, setStudentData] = useState({
    currentCountry: '',
    registrationNo: '',
    name: '',
    facebookName: '',
    nationality: '',
    age: '',
    gender: '',
    interestedJob: '',
    classAttendRecord: '',
    counseling: '',
    counselingDate: '',
    admissionDate: '',
    graduationDate: '',
    visaStatus: '',
    visaExpireDate: '',
    educationalBackgroundHome: '',
    educationalBackgroundJapan: '',
    collegeName: '',
    mobileNumber: '',
    japaneseTestLevel: '',
    englishTestLevel: '',
    othersLicenses: '',
    academicTranscriptCheck: '',
    nearestCity: '',
    desiredCountry: '',
    desiredSalary: '',
    experienceDetails: '',
    classAttendDay: '',
    counsellorStaff: '',
    referrerPerson: '',
    emergencyContact: '',
    jobOfferDate: '',
    jobOfferCompany: '',
    teacherSalesStaff: '',
    studentDocForVisa: '',
    lawyerName: '',
    lawyerRefDate: '',
    visaApplyDate: '',
    visaResultDate: '',
    reapplyDate: '',
    wizAcademyGradDate: '',
    japanEntryDate: '',
    companyEntryDate: '',
    admissionFee: '',
    tuitionFee: '',
    tuitionPaymentDate: '',
    dueTuitionFee: '',
    duePaymentDate: '',
    dropoutDate: '',
    refundFee: '',
    dropoutReason: '',
    snsReviewMarketing: '',
    residenceCardReceiveDate: '',
    visaExpiredDate: '',
    others: '',
    lawyerFeeFromCompany: '',
    lawyerFeePaidDate: '',
    invoiceDate: '',
    depositDate: '',
    payeeName: '',
    dueInvoiceDate: '',
    dueDepositDate: '',
    duePayeeName: '',
    picture: '',
  });
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({ show: false, message: '', type: '' });

  useEffect(() => {
    const fetchStudent = async () => {
      try {
        const response = await fetch(`http://localhost:5001/api/students/${studentId}`);
        const data = await response.json();
        setStudentData(data);
      } catch (error) {
        setAlert({ show: true, message: 'Failed to load student data.', type: 'danger' });
      } finally {
        setLoading(false);
      }
    };

    fetchStudent();
  }, [studentId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setStudentData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`http://localhost:5001/api/students/${studentId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(studentData),
      });

      if (response.ok) {
        setAlert({ show: true, message: 'Student updated successfully!', type: 'success' });
        setTimeout(() => navigate('/students/view'), 2000);
      } else {
        setAlert({ show: true, message: 'Failed to update student.', type: 'danger' });
      }
    } catch (error) {
      setAlert({ show: true, message: 'Failed to update student.', type: 'danger' });
    }
  };

  return (
    <Container fluid className="my-4">
      <Row className="mb-4">
        <Col>
          <h1 className="text-center mb-4" style={{ fontFamily: 'Arial, sans-serif', color: '#343a40' }}>Edit Student</h1>
        </Col>
      </Row>

      {loading ? (
        <Row className="d-flex justify-content-center">
          <Spinner animation="border" />
        </Row>
      ) : (
        <Form onSubmit={handleSubmit}>
          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Current Country</Form.Label>
                <Form.Control
                  type="text"
                  name="currentCountry"
                  value={studentData.currentCountry}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Registration Number</Form.Label>
                <Form.Control
                  type="text"
                  name="registrationNo"
                  value={studentData.registrationNo}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={studentData.name}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Facebook Name</Form.Label>
                <Form.Control
                  type="text"
                  name="facebookName"
                  value={studentData.facebookName}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Nationality</Form.Label>
                <Form.Control
                  type="text"
                  name="nationality"
                  value={studentData.nationality}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Age</Form.Label>
                <Form.Control
                  type="number"
                  name="age"
                  value={studentData.age}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Gender</Form.Label>
                <Form.Control
                  type="text"
                  name="gender"
                  value={studentData.gender}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Interested Job</Form.Label>
                <Form.Control
                  type="text"
                  name="interestedJob"
                  value={studentData.interestedJob}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Class Attend Record</Form.Label>
                <Form.Control
                  type="text"
                  name="classAttendRecord"
                  value={studentData.classAttendRecord}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Counseling</Form.Label>
                <Form.Control
                  type="text"
                  name="counseling"
                  value={studentData.counseling}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Counseling Date</Form.Label>
                <Form.Control
                  type="date"
                  name="counselingDate"
                  value={studentData.counselingDate}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Admission Date</Form.Label>
                <Form.Control
                  type="date"
                  name="admissionDate"
                  value={studentData.admissionDate}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Graduation Date</Form.Label>
                <Form.Control
                  type="date"
                  name="graduationDate"
                  value={studentData.graduationDate}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Visa Status</Form.Label>
                <Form.Control
                  type="text"
                  name="visaStatus"
                  value={studentData.visaStatus}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Visa Expire Date</Form.Label>
                <Form.Control
                  type="date"
                  name="visaExpireDate"
                  value={studentData.visaExpireDate}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Educational Background Home</Form.Label>
                <Form.Control
                  type="text"
                  name="educationalBackgroundHome"
                  value={studentData.educationalBackgroundHome}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Educational Background Japan</Form.Label>
                <Form.Control
                  type="text"
                  name="educationalBackgroundJapan"
                  value={studentData.educationalBackgroundJapan}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>College Name</Form.Label>
                <Form.Control
                  type="text"
                  name="collegeName"
                  value={studentData.collegeName}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Mobile Number</Form.Label>
                <Form.Control
                  type="text"
                  name="mobileNumber"
                  value={studentData.mobileNumber}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Japanese Test Level</Form.Label>
                <Form.Control
                  type="text"
                  name="japaneseTestLevel"
                  value={studentData.japaneseTestLevel}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>English Test Level</Form.Label>
                <Form.Control
                  type="text"
                  name="englishTestLevel"
                  value={studentData.englishTestLevel}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Others Licenses</Form.Label>
                <Form.Control
                  type="text"
                  name="othersLicenses"
                  value={studentData.othersLicenses}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Academic Transcript Check</Form.Label>
                <Form.Control
                  type="text"
                  name="academicTranscriptCheck"
                  value={studentData.academicTranscriptCheck}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Nearest City</Form.Label>
                <Form.Control
                  type="text"
                  name="nearestCity"
                  value={studentData.nearestCity}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Desired Country</Form.Label>
                <Form.Control
                  type="text"
                  name="desiredCountry"
                  value={studentData.desiredCountry}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Desired Salary</Form.Label>
                <Form.Control
                  type="text"
                  name="desiredSalary"
                  value={studentData.desiredSalary}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Experience Details</Form.Label>
                <Form.Control
                  type="text"
                  name="experienceDetails"
                  value={studentData.experienceDetails}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Class Attend Day</Form.Label>
                <Form.Control
                  type="text"
                  name="classAttendDay"
                  value={studentData.classAttendDay}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Counsellor Staff</Form.Label>
                <Form.Control
                  type="text"
                  name="counsellorStaff"
                  value={studentData.counsellorStaff}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Referrer Person</Form.Label>
                <Form.Control
                  type="text"
                  name="referrerPerson"
                  value={studentData.referrerPerson}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Emergency Contact</Form.Label>
                <Form.Control
                  type="text"
                  name="emergencyContact"
                  value={studentData.emergencyContact}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Job Offer Date</Form.Label>
                <Form.Control
                  type="date"
                  name="jobOfferDate"
                  value={studentData.jobOfferDate}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Job Offer Company</Form.Label>
                <Form.Control
                  type="text"
                  name="jobOfferCompany"
                  value={studentData.jobOfferCompany}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Teacher Sales Staff</Form.Label>
                <Form.Control
                  type="text"
                  name="teacherSalesStaff"
                  value={studentData.teacherSalesStaff}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Student Doc For Visa</Form.Label>
                <Form.Control
                  type="text"
                  name="studentDocForVisa"
                  value={studentData.studentDocForVisa}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Lawyer Name</Form.Label>
                <Form.Control
                  type="text"
                  name="lawyerName"
                  value={studentData.lawyerName}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Lawyer Ref Date</Form.Label>
                <Form.Control
                  type="date"
                  name="lawyerRefDate"
                  value={studentData.lawyerRefDate}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Visa Apply Date</Form.Label>
                <Form.Control
                  type="date"
                  name="visaApplyDate"
                  value={studentData.visaApplyDate}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Visa Result Date</Form.Label>
                <Form.Control
                  type="date"
                  name="visaResultDate"
                  value={studentData.visaResultDate}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Reapply Date</Form.Label>
                <Form.Control
                  type="date"
                  name="reapplyDate"
                  value={studentData.reapplyDate}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Wiz Academy Graduation Date</Form.Label>
                <Form.Control
                  type="date"
                  name="wizAcademyGradDate"
                  value={studentData.wizAcademyGradDate}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Japan Entry Date</Form.Label>
                <Form.Control
                  type="date"
                  name="japanEntryDate"
                  value={studentData.japanEntryDate}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Company Entry Date</Form.Label>
                <Form.Control
                  type="date"
                  name="companyEntryDate"
                  value={studentData.companyEntryDate}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Admission Fee</Form.Label>
                <Form.Control
                  type="text"
                  name="admissionFee"
                  value={studentData.admissionFee}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Tuition Fee</Form.Label>
                <Form.Control
                  type="text"
                  name="tuitionFee"
                  value={studentData.tuitionFee}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Tuition Payment Date</Form.Label>
                <Form.Control
                  type="date"
                  name="tuitionPaymentDate"
                  value={studentData.tuitionPaymentDate}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Due Tuition Fee</Form.Label>
                <Form.Control
                  type="text"
                  name="dueTuitionFee"
                  value={studentData.dueTuitionFee}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Due Payment Date</Form.Label>
                <Form.Control
                  type="date"
                  name="duePaymentDate"
                  value={studentData.duePaymentDate}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Dropout Date</Form.Label>
                <Form.Control
                  type="date"
                  name="dropoutDate"
                  value={studentData.dropoutDate}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Refund Fee</Form.Label>
                <Form.Control
                  type="text"
                  name="refundFee"
                  value={studentData.refundFee}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Dropout Reason</Form.Label>
                <Form.Control
                  type="text"
                  name="dropoutReason"
                  value={studentData.dropoutReason}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>SNS Review Marketing</Form.Label>
                <Form.Control
                  type="text"
                  name="snsReviewMarketing"
                  value={studentData.snsReviewMarketing}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Residence Card Receive Date</Form.Label>
                <Form.Control
                  type="date"
                  name="residenceCardReceiveDate"
                  value={studentData.residenceCardReceiveDate}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Visa Expired Date</Form.Label>
                <Form.Control
                  type="date"
                  name="visaExpiredDate"
                  value={studentData.visaExpiredDate}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Others</Form.Label>
                <Form.Control
                  type="text"
                  name="others"
                  value={studentData.others}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Lawyer Fee From Company</Form.Label>
                <Form.Control
                  type="text"
                  name="lawyerFeeFromCompany"
                  value={studentData.lawyerFeeFromCompany}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Lawyer Fee Paid Date</Form.Label>
                <Form.Control
                  type="date"
                  name="lawyerFeePaidDate"
                  value={studentData.lawyerFeePaidDate}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Invoice Date</Form.Label>
                <Form.Control
                  type="date"
                  name="invoiceDate"
                  value={studentData.invoiceDate}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Deposit Date</Form.Label>
                <Form.Control
                  type="date"
                  name="depositDate"
                  value={studentData.depositDate}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Payee Name</Form.Label>
                <Form.Control
                  type="text"
                  name="payeeName"
                  value={studentData.payeeName}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Due Invoice Date</Form.Label>
                <Form.Control
                  type="date"
                  name="dueInvoiceDate"
                  value={studentData.dueInvoiceDate}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Due Deposit Date</Form.Label>
                <Form.Control
                  type="date"
                  name="dueDepositDate"
                  value={studentData.dueDepositDate}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Due Payee Name</Form.Label>
                <Form.Control
                  type="text"
                  name="duePayeeName"
                  value={studentData.duePayeeName}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group>
                <Form.Label>Picture</Form.Label>
                <Form.Control
                  type="text"
                  name="picture"
                  value={studentData.picture}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Button variant="primary" type="submit" className="w-100">
            Update Student
          </Button>

          <Alert show={alert.show} variant={alert.type} className="mt-4" onClose={() => setAlert({ ...alert, show: false })} dismissible>
            {alert.message}
          </Alert>
        </Form>
      )}
    </Container>
  );
};

export default EditStudent;
