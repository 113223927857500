import React, { useState } from 'react';
import { Container, ListGroup, Button, Alert, Badge, Row, Col } from 'react-bootstrap';

const NotificationCenter = () => {
  const [notifications, setNotifications] = useState([
    { id: 1, message: 'New application received for Software Engineer position.', timestamp: '2024-09-12 10:00 AM' },
    { id: 2, message: 'Job posting status updated to Closed for Marketing Specialist.', timestamp: '2024-09-11 03:30 PM' },
    // Add more notifications as needed
  ]);

  const handleDismiss = (id) => {
    setNotifications(notifications.filter(notification => notification.id !== id));
  };

  return (
    <Container className="mt-4">
      <Row>
        <Col>
          <h2 className="mb-4">Notification Center</h2>
          <ListGroup variant="flush">
            {notifications.map(notification => (
              <ListGroup.Item key={notification.id} className="d-flex justify-content-between align-items-center border-0 mb-2">
                <div>
                  <Alert variant="info" className="mb-1 p-2">
                    <strong>{notification.message}</strong>
                    <div className="text-muted mt-1">{notification.timestamp}</div>
                  </Alert>
                </div>
                <Button variant="outline-secondary" size="sm" onClick={() => handleDismiss(notification.id)}>
                  Dismiss
                </Button>
              </ListGroup.Item>
            ))}
          </ListGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default NotificationCenter;
