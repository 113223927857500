import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const StudentFeesManagement = () => {
  const [formData, setFormData] = useState({
    studentId: '',
    amount: '',
    status: 'Pending',
    date: '',
  });
  const [students, setStudents] = useState([]);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await axios.get('https://control.novelkoirala.com:5001/api/students');
        setStudents(response.data);
      } catch (error) {
        setError('Failed to fetch students');
      }
    };

    fetchStudents();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const selectedStudent = students.find(student => student._id === formData.studentId);

    if (!selectedStudent) {
      setError('Selected student not found');
      return;
    }

    const dataToSend = {
      ...formData,
      studentName: selectedStudent.name,
    };

    try {
      await axios.post('https://control.novelkoirala.com:5001/api/student-fees', dataToSend);
      setFormData({
        studentId: '',
        amount: '',
        status: 'Pending',
        date: '',
      });
      setError('');
    } catch (error) {
      setError('Failed to add fee record');
    }
  };

  const handleViewHistory = () => {
    if (formData.studentId) {
      navigate(`/payhistory/${formData.studentId}`);
    } else {
      setError('Please select a student to view their pay history');
    }
  };

  return (
    <Container className="my-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <Card>
            <Card.Header className="bg-primary text-white">Record Fees</Card.Header>
            <Card.Body>
              {error && <div className="alert alert-danger">{error}</div>}
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="studentId">
                  <Form.Label>Student</Form.Label>
                  <Form.Control
                    as="select"
                    name="studentId"
                    value={formData.studentId}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select a student</option>
                    {students.map((student) => (
                      <option key={student._id} value={student._id}>
                        {student.name} (ID: {student._id})
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="amount" className="mt-3">
                  <Form.Label>Amount</Form.Label>
                  <Form.Control
                    type="number"
                    name="amount"
                    value={formData.amount}
                    onChange={handleChange}
                    placeholder="Enter fee amount"
                    required
                  />
                </Form.Group>
                <Form.Group controlId="status" className="mt-3">
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select"
                    name="status"
                    value={formData.status}
                    onChange={handleChange}
                  >
                    <option value="Pending">Pending</option>
                    <option value="Paid">Paid</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group controlId="date" className="mt-3">
                  <Form.Label>Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="date"
                    value={formData.date}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <div className="d-flex justify-content-between mt-4">
                  <Button variant="primary" type="submit">Submit</Button>
                  <Button 
                    variant="secondary" 
                    onClick={handleViewHistory}
                  >
                    View fees History
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default StudentFeesManagement;
