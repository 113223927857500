import React, { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { useBranch } from './Context/BranchContext'; // Adjust the import path as necessary

const Dashboard = () => {
  const { currentBranchId } = useBranch(); // Get the current branch ID from context
  const [data, setData] = useState({
    totalStudents: 0,
    totalEmployees: 0,
    counselingThisMonth: 0,
    admissionsThisMonth: 0,
    dropoutsThisMonth: 0,
    jobOffersThisMonth: 0,
    visaApprovalsThisMonth: 0,
    visaRejectionsThisMonth: 0,
    gradCompanyEntriesThisMonth: 0,
    visaApplyRequestsThisMonth: 0,
    visasAppliedThisMonth: 0,
  });

  // Fetch data from backend when component loads
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`http://localhost:5001/api/dashboard?branchId=${currentBranchId || ''}`);
        
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const data = await response.json();
        setData(data);
      } catch (err) {
        console.error(err);
        // You might want to handle errors more gracefully here
      }
    };

    fetchData();
  }, [currentBranchId]); // Add currentBranchId as a dependency

  const renderCard = (title, value, variant) => (
    <Col xs={12} md={4} className="mb-4">
      <Card className={`border-${variant} shadow-sm h-100`}>
        <Card.Header className={`bg-${variant} text-white`}>{title}</Card.Header>
        <Card.Body className="text-center">
          <h2 className={`text-${variant}`}>{value}</h2>
          <p>This Month</p>
          <Button variant={`outline-${variant}`}>View Details</Button>
        </Card.Body>
      </Card>
    </Col>
  );

  return (
    <Container fluid className="p-4">
      <Row>
        {renderCard('Total Students', data.totalStudents, 'success')}
        {renderCard('Total Employees', data.totalEmployees, 'primary')}
        {renderCard('Counseling This Month', data.counselingThisMonth, 'info')}
        {renderCard('Admissions This Month', data.admissionsThisMonth, 'warning')}
        {renderCard('Dropouts This Month', data.dropoutsThisMonth, 'danger')}
        {renderCard('Job Offers This Month', data.jobOffersThisMonth, 'light')}
        {renderCard('Visa Approvals This Month', data.visaApprovalsThisMonth, 'success')}
        {renderCard('Visa Rejections This Month', data.visaRejectionsThisMonth, 'danger')}
        {renderCard('Grad/Company Entries This Month', data.gradCompanyEntriesThisMonth, 'secondary')}
        {renderCard('Visa Apply Requests This Month', data.visaApplyRequestsThisMonth, 'info')}
        {renderCard('Visas Applied This Month', data.visasAppliedThisMonth, 'primary')}
      </Row>
    </Container>
  );
};

export default Dashboard;




// import React from 'react';
// import { Container, Card, Table } from 'react-bootstrap';

// const Dashboard = () => {
//   return (
//     <Container className="p-4">
//     <Card className="mb-4">
//       <Card.Header className="bg-primary text-white">
//         <h4>Dropouts This Month - Details</h4>
//       </Card.Header>
//       <Card.Body>
//         <p>This month, we recorded a total of 5 dropouts. Below is a list of the students who dropped out.</p>

//         {/* Table with detailed statistics */}
//         <h5 className="mb-3">Dropout Statistics</h5>
//         <Table striped bordered hover>
//           <thead>
//             <tr>
//               <th>Name</th>
//               <th>Date of Dropout</th>
//             </tr>
//           </thead>
//           <tbody>
//             <tr>
//               <td>John Doe</td>
//               <td>September 5, 2024</td>
//             </tr>
//             <tr>
//               <td>Jane Smith</td>
//               <td>September 12, 2024</td>
//             </tr>
//             <tr>
//               <td>Emily Johnson</td>
//               <td>September 15, 2024</td>
//             </tr>
//             <tr>
//               <td>Michael Brown</td>
//               <td>September 20, 2024</td>
//             </tr>
//             <tr>
//               <td>Alice Davis</td>
//               <td>September 28, 2024</td>
//             </tr>
//           </tbody>
//         </Table>
//       </Card.Body>
//     </Card>
//   </Container>
// );
// };

// export default Dashboard;

