// src/components/OverviewDashboard.js
import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Table, Card } from 'react-bootstrap'; // Import Bootstrap components
import axios from 'axios'; // Import axios for making HTTP requests
import { Line } from 'react-chartjs-2'; // Import Line chart from react-chartjs-2
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js'; // Import necessary components from Chart.js

ChartJS.register(LineElement, CategoryScale, LinearScale, Title, Tooltip, Legend); // Register components for Chart.js

const AttendanceReport = () => {
  const [filters, setFilters] = useState({
    startDate: '',
    endDate: '',
    name: '',
    status: ''
  });

  const [studentData, setStudentData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const studentResponse = await axios.get('https://control.novelkoirala.com:5001/api/students-attendance', { params: filters });
        const employeeResponse = await axios.get('https://control.novelkoirala.com:5001/api/employee-attendance', { params: filters });
        setStudentData(studentResponse.data);
        setEmployeeData(employeeResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error.response ? error.response.data : error.message);
      }
    };

    fetchData();
  }, [filters]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleSearch = () => {
    // Fetch data based on updated filters
    const fetchData = async () => {
      try {
        const studentResponse = await axios.get('https://control.novelkoirala.com:5001/api/students-attendance', { params: filters });
        const employeeResponse = await axios.get('https://control.novelkoirala.com:5001/api/employee-attendance', { params: filters });
        setStudentData(studentResponse.data);
        setEmployeeData(employeeResponse.data);
      } catch (error) {
        console.error('Error fetching data:', error.response ? error.response.data : error.message);
      }
    };

    fetchData();
  };

  const generateChartData = (data) => {
    const labels = [];
    const presentCounts = [];
    const absentCounts = [];

    data.forEach(item => {
      labels.push(item.date);
      presentCounts.push(item.presentCount || 0);
      absentCounts.push(item.absentCount || 0);
    });

    return {
      labels,
      datasets: [
        {
          label: 'Present',
          data: presentCounts,
          borderColor: 'green',
          backgroundColor: 'rgba(0, 255, 0, 0.2)',
          fill: true
        },
        {
          label: 'Absent',
          data: absentCounts,
          borderColor: 'red',
          backgroundColor: 'rgba(255, 0, 0, 0.2)',
          fill: true
        }
      ]
    };
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={12}>
          <Card>
            <Card.Body>
              <h2 className="text-center mb-4 text-primary">Attendance Overview</h2>
              <Form>
                <Row className="mb-3">
                  <Col md={3}>
                    <Form.Group controlId="name">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={filters.name}
                        onChange={handleChange}
                        placeholder="Enter name"
                      />
                    </Form.Group>
                  </Col>

                  <Col md={3}>
                    <Form.Group controlId="startDate">
                      <Form.Label>Start Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="startDate"
                        value={filters.startDate}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={3}>
                    <Form.Group controlId="endDate">
                      <Form.Label>End Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="endDate"
                        value={filters.endDate}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={3}>
                    <Form.Group controlId="status">
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        as="select"
                        name="status"
                        value={filters.status}
                        onChange={handleChange}
                      >
                        <option value="">All</option>
                        <option value="Present">Present</option>
                        <option value="Absent">Absent</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Button variant="primary" onClick={handleSearch} className="w-100">
                  Search
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={12}>
          <Card>
            <Card.Body>
              <h4 className="mb-4">Student Attendance Trends</h4>
              <Line data={generateChartData(studentData)} options={{ responsive: true }} />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={12}>
          <Card>
            <Card.Body>
              <h4 className="mb-4">Employee Attendance Trends</h4>
              <Line data={generateChartData(employeeData)} options={{ responsive: true }} />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={12}>
          <Card>
            <Card.Body>
              <h4 className="mb-4">Student Attendance Table</h4>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Student Name</th>
                    <th>Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {studentData.length > 0 ? (
                    studentData.map((row) => (
                      <tr key={row._id}>
                        <td>{row._id}</td>
                        <td>{row.studentName}</td>
                        <td>{new Date(row.date).toLocaleDateString()}</td>
                        <td>{row.status}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="text-center">
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={12}>
          <Card>
            <Card.Body>
              <h4 className="mb-4">Employee Attendance Table</h4>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Employee Name</th>
                    <th>Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {employeeData.length > 0 ? (
                    employeeData.map((row) => (
                      <tr key={row._id}>
                        <td>{row._id}</td>
                        <td>{row.employeeName}</td>
                        <td>{new Date(row.date).toLocaleDateString()}</td>
                        <td>{row.status}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="text-center">
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AttendanceReport;
