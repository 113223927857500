import React, { useState, useEffect, useMemo } from 'react';
import { Table, Container, Row, Col, Card, Alert, Pagination, Button, Collapse } from 'react-bootstrap';
import axios from 'axios';

const fetchSubmissions = async () => {
  try {
    const response = await axios.get('https://control.novelkoirala.com:50011/api/contact-forms');
    return response.data;
  } catch (error) {
    throw error;
  }
};

const ContactUsData = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 4;
  const [openMessage, setOpenMessage] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        const submissions = await fetchSubmissions();
        setData(submissions);
        setError(null); // Clear any previous error
      } catch (error) {
        setError('Failed to fetch contact form submissions. Please try again later.');
      }
    };
    loadData();
  }, []);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://control.novelkoirala.com:5001/api/contact-forms/${id}`);
      setData(data.filter(item => item._id !== id)); // Remove deleted item from state
    } catch (error) {
      setError('Failed to delete contact form. Please try again later.');
    }
  };

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = data.slice(indexOfFirstRow, indexOfLastRow);

  const totalPages = Math.ceil(data.length / rowsPerPage);

  const paginationItems = [];
  for (let number = 1; number <= totalPages; number++) {
    paginationItems.push(
      <Pagination.Item
        key={number}
        active={number === currentPage}
        onClick={() => setCurrentPage(number)}
      >
        {number}
      </Pagination.Item>
    );
  }

  return (
    <Container fluid className="mt-4">
      <Row className="mb-3">
        <Col>
          <h2 className="text-center text-primary mb-4">Contact Form Submissions</h2>
        </Col>
      </Row>
      {error && (
        <Row className="mb-3">
          <Col>
            <Alert variant="danger">{error}</Alert>
          </Col>
        </Row>
      )}
      <Card>
        <Card.Header className="bg-primary text-white">
          <h4 className="mb-0">Submitted Forms</h4>
        </Card.Header>
        <Card.Body>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Telephone Number</th>
                <th>Subject</th>
                <th>Contents of Inquiry</th>
                <th>Message</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentRows.map((row) => (
                <tr key={row._id}>
                  <td>{row.name}</td>
                  <td>{row.email}</td>
                  <td>{row.telephone}</td>
                  <td>{row.subject}</td>
                  <td>{row.inquiry.join(', ')}</td>
                  <td>
                    <div style={{ maxWidth: '200px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                      <Collapse in={openMessage === row._id}>
                        <div>{row.message}</div>
                      </Collapse>
                      <Button
                        variant="link"
                        onClick={() => setOpenMessage(openMessage === row._id ? null : row._id)}
                      >
                        {openMessage === row._id ? 'Read Less' : 'Read More'}
                      </Button>
                    </div>
                  </td>
                  <td>
                    <Button variant="danger" onClick={() => handleDelete(row._id)}>Delete</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Pagination className="justify-content-center mt-4">
            {paginationItems}
          </Pagination>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ContactUsData;
