import React, { useState } from 'react';
import { Nav, NavItem, NavLink, Collapse, ListGroup, ListGroupItem, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { HouseDoor, People, Building, ClipboardData, PersonBoundingBox, Book, Calendar, FileEarmarkText, Globe, Newspaper, CaretUp, CaretDown, Bell, Activity, Cash } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { FaBriefcase, FaUser } from 'react-icons/fa';
import './SideBar.css'; // Import the CSS file for custom styles

const sections = [
  {
    label: 'Dashboard',
    icon: <HouseDoor />,
    route: '/',
    subItems: [],
  },
  {
    label: 'Branch Management',
    icon: <Building />,
    route: null,
    subItems: [
      { label: 'Create Branch', route: '/branches/create' },
      { label: 'View Branches', route: '/branches' },
      { label: 'Select Manager', route: '/branches/role-management' },
    ]
  },
  {
    label: 'Employee Management',
    icon: <People />,
    route: null,
    subItems: [
      { label: 'Add New Employee', route: '/employees/add' },
      { label: 'View Employees', route: '/employees/view' },
    ]
  },
  {
    label: 'Classes Management',
    icon: <ClipboardData />,
    route: null,
    subItems: [
      { label: 'Add New Class', route: '/classes/add' },
      { label: 'View Classes', route: '/classes/view' }
    ]
  },
  {
    label: 'Student Management',
    icon: <PersonBoundingBox />,
    route: null,
    subItems: [
      { label: 'Add New Student', route: '/students/add' },
      { label: 'View Students', route: '/students/view' }
    ]
  },
  {
    label: 'Money Management',
    icon: <Cash />,
    route: null,
    subItems: [
      { label: 'Salary', route: '/salary/employee' },
      { label: 'fees', route: '/fees/students' }
    ]
  },

  {
    label: 'Subject Management',
    icon: <Book />,
    route: null,
    subItems: [
      { label: 'Add New Subject', route: '/subjects/create' },
      { label: 'View Subjects', route: '/subjects/view' }
    ]
  },
  {
    label: 'Attendance Management',
    icon: <Calendar />,
    route: null,
    subItems: [
      { label: 'Student Attendance', route: '/attendance/student' },
      { label: 'Employee Attendance', route: '/attendance/employee' },
      { label: 'Student Report', route: '/attendance/students-report' },
      { label: 'Employee Report', route: '/attendance/employee-report' },
      { label: 'Overview Report', route: '/attendance/report' },
      { label: 'Classwise Report', route: '/attendance/classwise-report' }
    ]
  },
  {
    label: 'Form Data',
    icon: <FileEarmarkText />,
    route: null,
    subItems: [
      { label: 'Visitor Form', route: '/forms/visitor' },
      { label: 'Admission Form', route: '/forms/admission-form' },
      { label: 'Contact us Form', route: '/forms/contact-us-form'}

    ]
  },
  {
    label: 'Vacancy Management',
    icon: <FaBriefcase />,
    route: null,
    subItems: [
      { label: 'Create Job', route: '/job/create-job' },
      { label: 'Edit/Delete', route: '/job/edit-job' },
      {label:'Job Applications', route:'/job/applications'},
      { label: 'Job Report', route: '/job/job-report' },
    ]
  },

  {
    label: 'Website Management',
    icon: <Globe />,
    route: null,
    subItems: [
      { label: 'Site Info', route: '/website-management/site-info' },
  
    ]
  },
  {
    label: 'Blog Management',
    icon: <Newspaper />,
    route: null,
    subItems: [
      { label: 'Create', route: '/website-management/blog/create-blog' },
      { label: 'Edit/Delete', route: '/website-management/blog/edit-blog' },
    ]
  },
 

  {
    label: 'Activity Log',
    icon: <Activity/>,
    route:'/activity-log',
    subItems: [
      
    ]


  },

];

const SideBar = () => {
  const navigate = useNavigate();
  const Logout = () => {
    localStorage.removeItem('token'); // Remove token from localStorage
    navigate('/login'); // Redirect to login page
  };

  const [openSections, setOpenSections] = useState({});

  const handleClick = (label) => {
    setOpenSections((prev) => ({ ...prev, [label]: !prev[label] }));
  };

  const renderSection = ({ label, icon, route, subItems }) => (
    <Nav.Item key={label}>
      <Nav.Link
        as={route ? Link : 'div'}
        to={route || '#'}
        onClick={() => handleClick(label)}
        className={`d-flex align-items-center ${openSections[label] ? 'active' : ''}`}
      >
        {icon} <span className="ms-2">{label}</span>
        {subItems.length > 0 && (
          <span className="ms-auto">
            {openSections[label] ? <CaretUp /> : <CaretDown />}
          </span>
        )}
      </Nav.Link>
      {subItems.length > 0 && (
        <Collapse in={openSections[label]}>
          <ListGroup className="ps-3">
            {subItems.map((subItem) => (
              <ListGroupItem
                key={subItem.label}
                as={Link}
                to={subItem.route}
                className="ps-4"
              >
                {subItem.label}
              </ListGroupItem>
            ))}
          </ListGroup>
        </Collapse>
      )}
    </Nav.Item>
  );

  return (
    <div className="sidebar-container">
      <div className="logo-section d-flex align-items-center mb-4">
        <FaUser className="me-2" size={24} /> {/* Placeholder logo icon */}
        <span className="logo-text">Language Site</span>
      </div>
      <Nav defaultActiveKey="/" className="flex-column">
        {sections.map(renderSection)}
        <hr />
        <Nav.Item>
          <Button variant="outline-danger" onClick={Logout}>Logout</Button>
        </Nav.Item>
      </Nav>
    </div>
  );
};

export default SideBar;
