// src/pages/MessagingPage.js
import React, { useState } from 'react';
import { Container, Typography, TextField, Button, MenuItem, Select, FormControl, InputLabel, FormHelperText, Grid, Paper, IconButton } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import MessageBox from './MessageBox';

const MessagingPage = () => {
  const [recipient, setRecipient] = useState('');
  const [message, setMessage] = useState('');
  const [file, setFile] = useState(null);
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState('');
  const [severity, setSeverity] = useState('info');

  const handleSendMessage = () => {
    // Logic to send message
    setMsg('Message sent successfully!');
    setSeverity('success');
    setOpen(true);
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom sx={{ mb: 3, color: '#333' }}>
        Compose a New Message
      </Typography>

      <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="recipient-label">Send Message To</InputLabel>
          <Select
            labelId="recipient-label"
            id="recipient"
            value={recipient}
            onChange={(e) => setRecipient(e.target.value)}
            label="Send Message To"
            sx={{ bgcolor: '#f9f9f9' }}
          >
            <MenuItem value="all">All Students</MenuItem>
            {/* Add other recipient options here */}
          </Select>
          <FormHelperText>Select the recipient group.</FormHelperText>
        </FormControl>

        <TextField
          id="message"
          label="Write your Message here..."
          multiline
          rows={4}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          variant="outlined"
          fullWidth
          sx={{ mb: 2 }}
          inputProps={{ maxLength: 244 }}
        />
        <Typography variant="caption" color="textSecondary" sx={{ mb: 2 }}>
          {message.length}/244 Characters
        </Typography>

        <Grid container spacing={2} alignItems="center">
          <Grid item xs={8}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSendMessage}
              endIcon={<SendIcon />}
              fullWidth
            >
              Send Message
            </Button>
          </Grid>
          <Grid item xs={4}>
            <IconButton
              color="primary"
              component="label"
              sx={{ borderColor: 'primary.main', border: 1, borderRadius: 1, p: 1 }}
            >
              <AttachFileIcon />
              <input
                type="file"
                hidden
                onChange={(e) => setFile(e.target.files[0])}
              />
            </IconButton>
            {file && <Typography variant="body2" sx={{ ml: 2 }}>{file.name}</Typography>}
          </Grid>
        </Grid>
      </Paper>

      <MessageBox open={open} onClose={() => setOpen(false)} message={msg} severity={severity} />
    </Container>
  );
};

export default MessagingPage;
