import React, { useState } from 'react';
import { Modal, Form, Button, Alert } from 'react-bootstrap';
import axios from 'axios';
import { useBranch } from '../Context/BranchContext';
import config from '../../components/config/config'; // Adjust the import according to your file structure

const AddVisitorModal = ({ show, handleClose, handleAddVisitor, visitor }) => {
  const { selectedBranch } = useBranch(); // Use the custom hook

  const [formData, setFormData] = useState({
    name: visitor ? visitor.name : '',
    address: visitor ? visitor.address : '',
    contact: visitor ? visitor.contact : '',
    gender: visitor ? visitor.gender : '',
    email: visitor ? visitor.email : '',
    college: visitor ? visitor.college : '',
    timeIn: visitor ? visitor.timeIn : '',
    timeOut: visitor ? visitor.timeOut : '',
    comments: visitor ? visitor.comments : '',
    source: visitor ? visitor.source : '',
    purposeOfVisit: visitor ? visitor.purposeOfVisit : '',
    branchId: selectedBranch ? selectedBranch._id : '', // Set branch ID based on context
  });

  const [error, setError] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (visitor) {
        // Update existing visitor
        await axios.put(`${config.API_BASE_URL}/visitors/${visitor._id}`, formData);
      } else {
        // Add new visitor
        await axios.post(`${config.API_BASE_URL}/visitors`, formData);
      }
      handleAddVisitor(formData); // Call parent function to update data
      handleClose();
      setError(null); // Clear any previous error
    } catch (error) {
      setError('Failed to save visitor. Please check the data and try again.');
      console.error(error.response.data); // Log the server response for more details
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{visitor ? 'Edit Visitor' : 'Add Visitor'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant="danger">{error}</Alert>}
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formAddress">
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formContact">
            <Form.Label>Contact</Form.Label>
            <Form.Control
              type="text"
              name="contact"
              value={formData.contact}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formGender">
            <Form.Label>Gender</Form.Label>
            <Form.Control
              as="select"
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              required
            >
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formCollege">
            <Form.Label>College</Form.Label>
            <Form.Control
              type="text"
              name="college"
              value={formData.college}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formTimeIn">
            <Form.Label>Time In</Form.Label>
            <Form.Control
              type="time"
              name="timeIn"
              value={formData.timeIn}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formTimeOut">
            <Form.Label>Time Out</Form.Label>
            <Form.Control
              type="time"
              name="timeOut"
              value={formData.timeOut}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formComments">
            <Form.Label>Comments</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              name="comments"
              value={formData.comments}
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group controlId="formSource">
            <Form.Label>Source</Form.Label>
            <Form.Control
              as="select"
              name="source"
              value={formData.source}
              onChange={handleChange}
              required
            >
              <option value="">Select Source</option>
              <option value="Facebook">Facebook</option>
              <option value="Website">Website</option>
              <option value="Referral">Referral</option>
              <option value="Other">Other</option>
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="formPurposeOfVisit">
            <Form.Label>Purpose of Visit</Form.Label>
            <Form.Control
              type="text"
              name="purposeOfVisit"
              value={formData.purposeOfVisit}
              onChange={handleChange}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="mt-3">
            {visitor ? 'Update Visitor' : 'Add Visitor'}
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddVisitorModal;
