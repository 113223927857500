import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Table, Card } from 'react-bootstrap';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const StudentDetails = () => {
  const [studentData, setStudentData] = useState({
    currentCountry: '',
    registrationNo: '',
    name: '',
    facebookName: '',
    nationality: '',
    age: '',
    gender: '',
    interestedJob: '',
    classAttendRecord: '',
    counseling: '',
    counselingDate: '',
    admissionDate: '',
    graduationDate: '',
    visaStatus: '',
    visaExpireDate: '',
    educationalBackgroundHome: '',
    educationalBackgroundJapan: '',
    collegeName: '',
    mobileNumber: '',
    japaneseTestLevel: '',
    englishTestLevel: '',
    othersLicenses: '',
    academicTranscriptCheck: '',
    nearestCity: '',
    desiredCountry: '',
    desiredSalary: '',
    experienceDetails: '',
    classAttendDay: '',
    counsellorStaff: '',
    referrerPerson: '',
    emergencyContact: '',
    jobOfferDate: '',
    jobOfferCompany: '',
    teacherSalesStaff: '',
    studentDocForVisa: '',
    lawyerName: '',
    lawyerRefDate: '',
    visaApplyDate: '',
    visaResultDate: '',
    reapplyDate: '',
    wizAcademyGradDate: '',
    japanEntryDate: '',
    companyEntryDate: '',
    admissionFee: '',
    tuitionFee: '',
    tuitionPaymentDate: '',
    dueTuitionFee: '',
    duePaymentDate: '',
    dropoutDate: '',
    refundFee: '',
    dropoutReason: '',
    snsReviewMarketing: '',
    residenceCardReceiveDate: '',
    visaExpiredDate: '',
    others: '',
    lawyerFeeFromCompany: '',
    lawyerFeePaidDate: '',
    invoiceDate: '',
    depositDate: '',
    payeeName: '',
    dueInvoiceDate: '',
    dueDepositDate: '',
    duePayeeName: '',
    picture: '',
  });

  const { studentId } = useParams();

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const response = await axios.get(`http://localhost:5001/api/students/${studentId}`);
        setStudentData(response.data);
      } catch (error) {
        console.error('Failed to fetch student data:', error);
      }
    };

    fetchStudentData();
  }, [studentId]);

  if (!studentData) {
    return <div>Loading...</div>; // Show a loading message or spinner while data is being fetched
  }

  return (
    <Container>
      <Card className="my-4">
        <Card.Body>
          <Card.Title className="text-center mb-4">Admission Form Details</Card.Title>
          <Row>
            <Col md={4}>
              <img
                src={studentData.profilePicture ? `http://localhost:5001/${studentData.profilePicture}` : '/default-avatar.png'}
                alt="Student"
                className="img-fluid rounded-circle"
              />
              <h5 className="mt-2">{studentData.name}</h5>
            </Col>
            <Col md={8}>
              <Table striped bordered hover>
                <tbody>
                  <tr>
                    <td>Current Country</td>
                    <td>{studentData.currentCountry}</td>
                  </tr>
                  <tr>
                    <td>Registration No</td>
                    <td>{studentData.registrationNo}</td>
                  </tr>
                  <tr>
                    <td>Facebook Name</td>
                    <td>{studentData.facebookName}</td>
                  </tr>
                  <tr>
                    <td>Nationality</td>
                    <td>{studentData.nationality}</td>
                  </tr>
                  <tr>
                    <td>Age</td>
                    <td>{studentData.age}</td>
                  </tr>
                  <tr>
                    <td>Gender</td>
                    <td>{studentData.gender}</td>
                  </tr>
                  <tr>
                    <td>Interested Job</td>
                    <td>{studentData.interestedJob}</td>
                  </tr>
                  <tr>
                    <td>Class Attend Record</td>
                    <td>{studentData.classAttendRecord}</td>
                  </tr>
                  <tr>
                    <td>Counseling</td>
                    <td>{studentData.counseling}</td>
                  </tr>
                  <tr>
                    <td>Counseling Date</td>
                    <td>{studentData.counselingDate}</td>
                  </tr>
                  <tr>
                    <td>Date of Admission</td>
                    <td>{studentData.admissionDate}</td>
                  </tr>
                  <tr>
                    <td>Graduation Date</td>
                    <td>{studentData.graduationDate}</td>
                  </tr>
                  <tr>
                    <td>Visa Status</td>
                    <td>{studentData.visaStatus}</td>
                  </tr>
                  <tr>
                    <td>Visa Expire Date</td>
                    <td>{studentData.visaExpireDate}</td>
                  </tr>
                  <tr>
                    <td>Educational Background (Home)</td>
                    <td>{studentData.educationalBackgroundHome}</td>
                  </tr>
                  <tr>
                    <td>Educational Background (Japan)</td>
                    <td>{studentData.educationalBackgroundJapan}</td>
                  </tr>
                  <tr>
                    <td>College Name</td>
                    <td>{studentData.collegeName}</td>
                  </tr>
                  <tr>
                    <td>Mobile Number</td>
                    <td>{studentData.mobileNumber}</td>
                  </tr>
                  <tr>
                    <td>Japanese Test Level</td>
                    <td>{studentData.japaneseTestLevel}</td>
                  </tr>
                  <tr>
                    <td>English Test Level</td>
                    <td>{studentData.englishTestLevel}</td>
                  </tr>
                  <tr>
                    <td>Other Licenses</td>
                    <td>{studentData.othersLicenses}</td>
                  </tr>
                  <tr>
                    <td>Academic Transcript Check</td>
                    <td>{studentData.academicTranscriptCheck}</td>
                  </tr>
                  <tr>
                    <td>Nearest City</td>
                    <td>{studentData.nearestCity}</td>
                  </tr>
                  <tr>
                    <td>Desired Country</td>
                    <td>{studentData.desiredCountry}</td>
                  </tr>
                  <tr>
                    <td>Desired Salary</td>
                    <td>{studentData.desiredSalary}</td>
                  </tr>
                  <tr>
                    <td>Experience Details</td>
                    <td>{studentData.experienceDetails}</td>
                  </tr>
                  <tr>
                    <td>Class Attend Day</td>
                    <td>{studentData.classAttendDay}</td>
                  </tr>
                  <tr>
                    <td>Counselor Staff</td>
                    <td>{studentData.counsellorStaff}</td>
                  </tr>
                  <tr>
                    <td>Referrer Person</td>
                    <td>{studentData.referrerPerson}</td>
                  </tr>
                  <tr>
                    <td>Emergency Contact</td>
                    <td>{studentData.emergencyContact}</td>
                  </tr>
                  <tr>
                    <td>Job Offer Date</td>
                    <td>{studentData.jobOfferDate}</td>
                  </tr>
                  <tr>
                    <td>Job Offer Company</td>
                    <td>{studentData.jobOfferCompany}</td>
                  </tr>
                  <tr>
                    <td>Teacher/Sales Staff</td>
                    <td>{studentData.teacherSalesStaff}</td>
                  </tr>
                  <tr>
                    <td>Student Document for Visa</td>
                    <td>{studentData.studentDocForVisa}</td>
                  </tr>
                  <tr>
                    <td>Lawyer Name</td>
                    <td>{studentData.lawyerName}</td>
                  </tr>
                  <tr>
                    <td>Lawyer Reference Date</td>
                    <td>{studentData.lawyerRefDate}</td>
                  </tr>
                  <tr>
                    <td>Visa Apply Date</td>
                    <td>{studentData.visaApplyDate}</td>
                  </tr>
                  <tr>
                    <td>Visa Result Date</td>
                    <td>{studentData.visaResultDate}</td>
                  </tr>
                  <tr>
                    <td>Reapply Date</td>
                    <td>{studentData.reapplyDate}</td>
                  </tr>
                  <tr>
                    <td>Wiz Academy Graduation Date</td>
                    <td>{studentData.wizAcademyGradDate}</td>
                  </tr>
                  <tr>
                    <td>Japan Entry Date</td>
                    <td>{studentData.japanEntryDate}</td>
                  </tr>
                  <tr>
                    <td>Company Entry Date</td>
                    <td>{studentData.companyEntryDate}</td>
                  </tr>
                  <tr>
                    <td>Admission Fee</td>
                    <td>{studentData.admissionFee}</td>
                  </tr>
                  <tr>
                    <td>Tuition Fee</td>
                    <td>{studentData.tuitionFee}</td>
                  </tr>
                  <tr>
                    <td>Tuition Payment Date</td>
                    <td>{studentData.tuitionPaymentDate}</td>
                  </tr>
                  <tr>
                    <td>Due Tuition Fee</td>
                    <td>{studentData.dueTuitionFee}</td>
                  </tr>
                  <tr>
                    <td>Due Payment Date</td>
                    <td>{studentData.duePaymentDate}</td>
                  </tr>
                  <tr>
                    <td>Dropout Date</td>
                    <td>{studentData.dropoutDate}</td>
                  </tr>
                  <tr>
                    <td>Refund Fee</td>
                    <td>{studentData.refundFee}</td>
                  </tr>
                  <tr>
                    <td>Dropout Reason</td>
                    <td>{studentData.dropoutReason}</td>
                  </tr>
                  <tr>
                    <td>SNS Review/Marketing</td>
                    <td>{studentData.snsReviewMarketing}</td>
                  </tr>
                  <tr>
                    <td>Residence Card Receive Date</td>
                    <td>{studentData.residenceCardReceiveDate}</td>
                  </tr>
                  <tr>
                    <td>Visa Expired Date</td>
                    <td>{studentData.visaExpiredDate}</td>
                  </tr>
                  <tr>
                    <td>Others</td>
                    <td>{studentData.others}</td>
                  </tr>
                  <tr>
                    <td>Lawyer Fee From Company</td>
                    <td>{studentData.lawyerFeeFromCompany}</td>
                  </tr>
                  <tr>
                    <td>Lawyer Fee Paid Date</td>
                    <td>{studentData.lawyerFeePaidDate}</td>
                  </tr>
                  <tr>
                    <td>Invoice Date</td>
                    <td>{studentData.invoiceDate}</td>
                  </tr>
                  <tr>
                    <td>Deposit Date</td>
                    <td>{studentData.depositDate}</td>
                  </tr>
                  <tr>
                    <td>Payee Name</td>
                    <td>{studentData.payeeName}</td>
                  </tr>
                  <tr>
                    <td>Due Invoice Date</td>
                    <td>{studentData.dueInvoiceDate}</td>
                  </tr>
                  <tr>
                    <td>Due Deposit Date</td>
                    <td>{studentData.dueDepositDate}</td>
                  </tr>
                  <tr>
                    <td>Due Payee Name</td>
                    <td>{studentData.duePayeeName}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default StudentDetails;




// import React from 'react';
// import { Container, Row, Col, Table, Card, Button, Form } from 'react-bootstrap';

// // Sample data
// const sampleData = {
//   studentName: 'John Doe',
//   picture: 'https://via.placeholder.com/150',
//   registrationNo: '12345',
//   dateOfAdmission: '09/08/2024',
//   selectClass: 'Class A',
//   discountInFee: '10%',
//   mobileNo: '+1234567890',
//   dateOfBirth: '01/01/2005',
//   gender: 'Male',
//   identificationMark: 'None',
//   bloodGroup: 'O+',
//   diseaseIfAny: 'None',
//   studentBirthFormId: 'AB123456',
//   cast: 'General',
//   previousSchool: 'XYZ School',
//   previousID: '456789',
//   additionalNote: 'N/A',
//   orphanStudent: 'No',
//   osc: 'No',
//   religion: 'Christianity',
//   familyBackground: 'N/A',
//   numberOfSiblings: '2',
//   address: '123 Main St, City, Country',
//   fatherName: 'Michael Doe',
//   fatherEducation: 'Bachelors',
//   fatherId: 'ID123456',
//   fatherMobileNo: '+0987654321',
//   fatherOccupation: 'Engineer',
//   fatherProfession: 'Mechanical',
//   fatherIncome: '$5000/month',
//   motherName: 'Sarah Doe',
//   motherEducation: 'Masters',
//   motherId: 'ID654321',
//   motherMobileNo: '+1122334455',
//   motherOccupation: 'Teacher',
//   motherProfession: 'Education',
//   motherIncome: '$3000/month',
// };

// const StudentDetails = () => {
//   return (
//     <Container>
//       <Card className="my-4">
//         <Card.Body>
//           <Card.Title className="text-center mb-4">Admission Form Details</Card.Title>
//           <Row>
//             <Col md={4}>
//               <img src={sampleData.picture} alt="Student" className="img-fluid rounded-circle" />
//               <h5 className="mt-2">{sampleData.studentName}</h5>
//             </Col>
//             <Col md={8}>
//               <Table striped bordered hover>
//                 <tbody>
//                   <tr>
//                     <td>Registration No</td>
//                     <td>{sampleData.registrationNo}</td>
//                   </tr>
//                   <tr>
//                     <td>Date of Admission</td>
//                     <td>{sampleData.dateOfAdmission}</td>
//                   </tr>
//                   <tr>
//                     <td>Select Class</td>
//                     <td>{sampleData.selectClass}</td>
//                   </tr>
//                   <tr>
//                     <td>Discount In Fee</td>
//                     <td>{sampleData.discountInFee}</td>
//                   </tr>
//                   <tr>
//                     <td>Mobile No. for SMS/WhatsApp</td>
//                     <td>{sampleData.mobileNo}</td>
//                   </tr>
//                   <tr>
//                     <td>Date Of Birth</td>
//                     <td>{sampleData.dateOfBirth}</td>
//                   </tr>
//                   <tr>
//                     <td>Gender</td>
//                     <td>{sampleData.gender}</td>
//                   </tr>
//                   <tr>
//                     <td>Any Identification Mark?</td>
//                     <td>{sampleData.identificationMark}</td>
//                   </tr>
//                   <tr>
//                     <td>Blood Group</td>
//                     <td>{sampleData.bloodGroup}</td>
//                   </tr>
//                   <tr>
//                     <td>Disease If Any?</td>
//                     <td>{sampleData.diseaseIfAny}</td>
//                   </tr>
//                   <tr>
//                     <td>Student Birth Form ID / NIC</td>
//                     <td>{sampleData.studentBirthFormId}</td>
//                   </tr>
//                   <tr>
//                     <td>Cast</td>
//                     <td>{sampleData.cast}</td>
//                   </tr>
//                   <tr>
//                     <td>Previous School</td>
//                     <td>{sampleData.previousSchool}</td>
//                   </tr>
//                   <tr>
//                     <td>Previous ID / Board Roll No</td>
//                     <td>{sampleData.previousID}</td>
//                   </tr>
//                   <tr>
//                     <td>Any Additional Note</td>
//                     <td>{sampleData.additionalNote}</td>
//                   </tr>
//                   <tr>
//                     <td>Orphan Student</td>
//                     <td>{sampleData.orphanStudent}</td>
//                   </tr>
//                   <tr>
//                     <td>OSC</td>
//                     <td>{sampleData.osc}</td>
//                   </tr>
//                   <tr>
//                     <td>Religion</td>
//                     <td>{sampleData.religion}</td>
//                   </tr>
//                   <tr>
//                     <td>Family Background</td>
//                     <td>{sampleData.familyBackground}</td>
//                   </tr>
//                   <tr>
//                     <td>Number of Siblings</td>
//                     <td>{sampleData.numberOfSiblings}</td>
//                   </tr>
//                   <tr>
//                     <td>Address</td>
//                     <td>{sampleData.address}</td>
//                   </tr>
//                 </tbody>
//               </Table>
//               <Card.Title className="mt-4">Father's Information</Card.Title>
//               <Table striped bordered hover>
//                 <tbody>
//                   <tr>
//                     <td>Father’s Name</td>
//                     <td>{sampleData.fatherName}</td>
//                   </tr>
//                   <tr>
//                     <td>Father’s Education</td>
//                     <td>{sampleData.fatherEducation}</td>
//                   </tr>
//                   <tr>
//                     <td>Father’s ID</td>
//                     <td>{sampleData.fatherId}</td>
//                   </tr>
//                   <tr>
//                     <td>Father’s Mobile No.</td>
//                     <td>{sampleData.fatherMobileNo}</td>
//                   </tr>
//                   <tr>
//                     <td>Father’s Occupation</td>
//                     <td>{sampleData.fatherOccupation}</td>
//                   </tr>
//                   <tr>
//                     <td>Father’s Profession</td>
//                     <td>{sampleData.fatherProfession}</td>
//                   </tr>
//                   <tr>
//                     <td>Father’s Income</td>
//                     <td>{sampleData.fatherIncome}</td>
//                   </tr>
//                 </tbody>
//               </Table>
//               <Card.Title className="mt-4">Mother's Information</Card.Title>
//               <Table striped bordered hover>
//                 <tbody>
//                   <tr>
//                     <td>Mother’s Name</td>
//                     <td>{sampleData.motherName}</td>
//                   </tr>
//                   <tr>
//                     <td>Mother’s Education</td>
//                     <td>{sampleData.motherEducation}</td>
//                   </tr>
//                   <tr>
//                     <td>Mother’s ID</td>
//                     <td>{sampleData.motherId}</td>
//                   </tr>
//                   <tr>
//                     <td>Mother’s Mobile No.</td>
//                     <td>{sampleData.motherMobileNo}</td>
//                   </tr>
//                   <tr>
//                     <td>Mother’s Occupation</td>
//                     <td>{sampleData.motherOccupation}</td>
//                   </tr>
//                   <tr>
//                     <td>Mother’s Profession</td>
//                     <td>{sampleData.motherProfession}</td>
//                   </tr>
//                   <tr>
//                     <td>Mother’s Income</td>
//                     <td>{sampleData.motherIncome}</td>
//                   </tr>
//                 </tbody>
//               </Table>
//             </Col>
//           </Row>
//         </Card.Body>
//       </Card>
//     </Container>
//   );
// };

// export default StudentDetails;
