import React, { useState, useEffect } from 'react';
import { Container, Button, Form, Alert, Card, Spinner } from 'react-bootstrap';
import axios from 'axios';
import config from '../../components/config/config';
import { logActivity } from '../ActivityLogs/ActivityLogger';

const CreateSubject = () => {
  const [subject, setSubject] = useState({
    className: '',
    subjectName: '',
    marks: 0, // Initialize marks as a number
    branchId: '' // Changed from branch to branchId
  });
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [noClasses, setNoClasses] = useState(false);
  const [userBranches, setUserBranches] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [user, setUser] = useState(null);
  const [isBranchesLoaded, setIsBranchesLoaded] = useState(false);
  const [branches, setBranches] = useState([]);

  // Fetch branches
  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await fetch(`${config.API_BASE_URL}/api/branch`);
        if (!response.ok) throw new Error('Failed to fetch branches');
        const data = await response.json();
        setBranches(data);
        setIsBranchesLoaded(true);
      } catch (error) {
        console.error('Error fetching branches:', error);
      }
    };
    fetchBranches();
  }, []);

  // Fetch user details using JWT token
  useEffect(() => {
    const fetchUser = async () => {
      try {
        if (!isBranchesLoaded) return;

        const token = localStorage.getItem('token');
        if (!token) return;

        const response = await fetch(`${config.API_BASE_URL}/api/profile`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          const errorMessage = await response.text();
          throw new Error(`Failed to fetch user data: ${response.status} - ${errorMessage}`);
        }

        const data = await response.json();
        setUser(data);

        const assignedBranch = branches.find(branch => branch._id === data.branchId);
        if (data.role === 'Admin') {
          setUserBranches(branches);
        } else {
          setUserBranches(assignedBranch ? [assignedBranch] : []);
        }

        setSelectedBranch(assignedBranch ? assignedBranch._id : null);

      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    fetchUser();
  }, [isBranchesLoaded, branches]);

  const handleBranchChange = (e) => {
    const selectedBranchId = e.target.value;
    setSelectedBranch(selectedBranchId);
    setSubject({ ...subject, branchId: selectedBranchId }); // Set branchId
  };

  // Fetch classes
  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const response = await axios.get('http://localhost:5001/api/classes');
        if (response.data.classes.length === 0) {
          setNoClasses(true);
        } else {
          setClasses(response.data.classes);
        }
        setLoading(false);
      } catch (err) {
        console.error('Error fetching classes:', err);
        setError('Failed to fetch classes. Please try again later.');
        setLoading(false);
      }
    };
    fetchClasses();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSubject({ ...subject, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!subject.className || !subject.subjectName || subject.marks <= 0 || !subject.branchId) {
      setError('Please fill in all fields correctly.'); // Validation
      return;
    }
    
    try {
      await axios.post(`${config.API_BASE_URL}/api/subjects`, subject);
      setSuccess('Subject created successfully!');
      setError(null);
      setSubject({
        className: '',
        subjectName: '',
        marks: 0, // Reset to number
        branchId: '' // Reset branchId
      });
    } catch (err) {
      console.error('Error creating subject:', err);
      setError('Failed to create subject. Please try again.');
      setSuccess(null);
    }
  };

  return (
    <Container className="my-4">
      <Form.Group className="mb-3">
        <Form.Label>Select Branch</Form.Label>
        <Form.Select value={selectedBranch || ''} onChange={handleBranchChange}>
          <option value="">Select Branch</option>
          {userBranches.length > 0 ? (
            userBranches.map((branch) => (
              <option key={branch._id} value={branch._id}>
                {branch.name}
              </option>
            ))
          ) : (
            <option disabled>No branches available</option>
          )}
        </Form.Select>
      </Form.Group>
      <Card>
        <Card.Header as="h5" className="text-center">Create Subject</Card.Header>
        <Card.Body>
          {loading ? (
            <div className="d-flex justify-content-center my-4">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : noClasses ? (
            <Alert variant="warning" className="text-center">
              No classes available. Please <a href="/classes/add">add a class</a> first.
            </Alert>
          ) : (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formClassName" className="mb-3">
                <Form.Label>Select Class</Form.Label>
                <Form.Control
                  as="select"
                  name="className"
                  value={subject.className}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select a class</option>
                  {classes.map(cls => (
                    <option key={cls._id} value={cls.name}>{cls.name}</option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="formSubjectName" className="mb-3">
                <Form.Label>Subject Name</Form.Label>
                <Form.Control
                  type="text"
                  name="subjectName"
                  value={subject.subjectName}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formMarks" className="mb-3">
                <Form.Label>Marks</Form.Label>
                <Form.Control
                  type="number"
                  name="marks"
                  value={subject.marks}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Button variant="primary" type="submit" disabled={loading}>
                {loading ? <Spinner animation="border" size="sm" /> : 'Save Subject'}
              </Button>
            </Form>
          )}

          {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
          {success && <Alert variant="success" className="mt-3">{success}</Alert>}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default CreateSubject;




// import React, { useState, useEffect } from 'react';
// import { Container, Button, Form, Alert, Card, Spinner } from 'react-bootstrap';
// import axios from 'axios';
// import config from '../../components/config/config';

// const CreateSubject = () => {
//   const [subject, setSubject] = useState({
//     className: '',
//     subjectName: '',
//     marks: ''
//   });
//   const [classes, setClasses] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [success, setSuccess] = useState(null);
//   const [noClasses, setNoClasses] = useState(false);



//   const [userBranches, setUserBranches] = useState([]); // Branches available to the user
//   const [selectedBranch, setSelectedBranch] = useState(null);
//   const [user, setUser] = useState(null);
//   const [showProfile, setShowProfile] = useState(false);
//   const [isBranchesLoaded, setIsBranchesLoaded] = useState(false);
//   const [branches, setBranches] = useState([]);

//   useEffect(() => {
//     const fetchBranches = async () => {
//       try {
//         const response = await fetch(`${config.API_BASE_URL}/api/branch`);
//         if (!response.ok) throw new Error('Failed to fetch branches');
//         const data = await response.json();
//         setBranches(data);
//         setIsBranchesLoaded(true); // Set branches loaded to true
//         console.log('Branches fetched:', data); // Debugging
//       } catch (error) {
//         console.error('Error fetching branches:', error);
//       }
//     };

//     fetchBranches();
//   }, []);

//   // Fetch user details using JWT token
//   useEffect(() => {
//     const fetchUser = async () => {
//       try {
//         if (!isBranchesLoaded) return; // Wait until branches are loaded

//         const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
//         if (!token) {
//           console.error('No token found'); // Debugging
//           // Redirect to login if token is missing
//           return;
//         }

//         const response = await fetch(`${config.API_BASE_URL}/api/profile`, {
//           headers: {
//             'Authorization': `Bearer ${token}`,
//           },
//         });

//         if (!response.ok) {
//           const errorMessage = await response.text(); // Get the error message from the response
//           throw new Error(`Failed to fetch user data: ${response.status} - ${errorMessage}`);
//         }

//         const data = await response.json();
//         setUser(data);
//         console.log('User fetched:', data); // Debugging

//         // Determine branches available to the user based on role
//         const assignedBranch = branches.find(branch => branch._id === data.branchId);
//         if (data.role === 'Admin') {
//           setUserBranches(branches); // Admin sees all branches
//         } else {
//           setUserBranches(assignedBranch ? [assignedBranch] : []); // Non-admin sees only their branch
//         }

//         // Set the selected branch to the user's branch
//         setSelectedBranch(assignedBranch);

//         console.log('User branches available:', userBranches); // Debugging
//       } catch (error) {
//         console.error('Error fetching user data:', error);
//         // Redirect to login on error
//       }
//     };

//     fetchUser();
//   }, [isBranchesLoaded, branches]);
//   const handleBranchChange = (e) => {
//     const selectedBranchId = e.target.value; // Get the branch _id from the dropdown value
//     setSelectedBranch(selectedBranchId); // Update the selected branch state
//     setSubject({ ...subject, branch: selectedBranchId }); // Set the branch _id in studentData
//   };





//   useEffect(() => {
//     // Fetch classes from the backend
//     const fetchClasses = async () => {
//       try {
//         const response = await axios.get('http://localhost:5001/api/classes');
//         if (response.data.classes.length === 0) {
//           setNoClasses(true);
//         } else {
//           setClasses(response.data.classes);
//         }
//         setLoading(false);
//       } catch (err) {
//         console.error('Error fetching classes:', err);
//         setError('Failed to fetch classes. Please try again later.');
//         setLoading(false);
//       }
//     };

//     fetchClasses();
//   }, []);

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setSubject({ ...subject, [name]: value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
    
//     if (!subject.className) {
//       setError('Please select a class.');
//       return;
//     }
    
//     try {
//       await axios.post(`${config.API_BASE_URL}/api/subjects`, subject);
//       setSuccess('Subject created successfully!');
//       setError(null);
//       setSubject({
//         className: '',
//         subjectName: '',
//         marks: ''
//       });
//     } catch (err) {
//       console.error('Error creating subject:', err);
//       setError('Failed to create subject. Please try again.');
//       setSuccess(null);
//     }
//   };



//   return (
//     <Container className="my-4">
//                 <Form.Group className="mb-3">
//   <Form.Label>Select Branch</Form.Label>
//   <Form.Select value={selectedBranch ? selectedBranch : ''} onChange={handleBranchChange}>
//     <option value="">Select Branch</option>
//     {userBranches.length > 0 ? (
//       userBranches.map((branch) => (
//         <option key={branch._id} value={branch._id}>
//           {branch.name}
//         </option>
//       ))
//     ) : (
//       <option disabled>No branches available</option>
//     )}
//   </Form.Select>
// </Form.Group>
//       <Card>
//         <Card.Header as="h5" className="text-center">Create Subject</Card.Header>
//         <Card.Body>
//           {loading ? (
//             <div className="d-flex justify-content-center my-4">
//               <Spinner animation="border" variant="primary" />
//             </div>
//           ) : noClasses ? (
//             <Alert variant="warning" className="text-center">
//               No classes available. Please <a href="/classes/add">add a class</a> first.
//             </Alert>
//           ) : (
//             <Form onSubmit={handleSubmit}>
//               <Form.Group controlId="formClassName" className="mb-3">
//                 <Form.Label>Select Class</Form.Label>
//                 <Form.Control
//                   as="select"
//                   name="className"
//                   value={subject.className}
//                   onChange={handleChange}
//                   required
//                 >
//                   <option value="">Select a class</option>
//                   {classes.map(cls => (
//                     <option key={cls._id} value={cls.name}>{cls.name}</option>
//                   ))}
//                 </Form.Control>
//               </Form.Group>

//               <Form.Group controlId="formSubjectName" className="mb-3">
//                 <Form.Label>Subject Name</Form.Label>
//                 <Form.Control
//                   type="text"
//                   name="subjectName"
//                   value={subject.subjectName}
//                   onChange={handleChange}
//                   required
//                 />
//               </Form.Group>

//               <Form.Group controlId="formMarks" className="mb-3">
//                 <Form.Label>Marks</Form.Label>
//                 <Form.Control
//                   type="number"
//                   name="marks"
//                   value={subject.marks}
//                   onChange={handleChange}
//                   required
//                 />
//               </Form.Group>

//               <Button variant="primary" type="submit" disabled={loading}>
//                 {loading ? <Spinner animation="border" size="sm" /> : 'Save Subject'}
//               </Button>
//             </Form>
//           )}

//           {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
//           {success && <Alert variant="success" className="mt-3">{success}</Alert>}
//         </Card.Body>
//       </Card>
//     </Container>
//   );
// };

// export default CreateSubject;
