import React, { useState } from 'react';
import { Button, TextField, Box, Snackbar, Alert } from '@mui/material';
import axios from 'axios';

const DeleteEmployee = () => {
  const [employeeId, setEmployeeId] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setEmployeeId(e.target.value);
  };

  const handleDelete = async (e) => {
    e.preventDefault();

    if (!employeeId) {
      setSnackbarMessage('Employee ID is required');
      setSnackbarSeverity('warning');
      setOpenSnackbar(true);
      return;
    }

    setLoading(true);

    try {
      // Make the DELETE request to the backend
      await axios.delete(`https://control.novelkoirala.com:5001/api/employees/${employeeId}`);
      
      setSnackbarMessage('Employee deleted successfully!');
      setSnackbarSeverity('success');
      setEmployeeId(''); // Clear the input field
    } catch (error) {
      console.error('Error deleting employee:', error.response?.data || error.message);
      setSnackbarMessage('Failed to delete employee. Please try again.');
      setSnackbarSeverity('error');
    } finally {
      setLoading(false);
      setOpenSnackbar(true);
    }
  };

  return (
    <div>
      <h2>Delete Employee</h2>
      <form onSubmit={handleDelete}>
        <Box sx={{ mb: 2 }}>
          <TextField
            label="Employee ID"
            variant="outlined"
            fullWidth
            name="employeeId"
            value={employeeId}
            onChange={handleChange}
            required
          />
        </Box>
        <Button type="submit" variant="contained" color="secondary" disabled={loading}>
          {loading ? 'Deleting...' : 'Delete Employee'}
        </Button>
      </form>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default DeleteEmployee;






// import React, { useState } from 'react';
// import { Button, TextField, Box } from '@mui/material';

// const DeleteEmployee = () => {
//   const [employeeId, setEmployeeId] = useState('');

//   const handleChange = (e) => {
//     setEmployeeId(e.target.value);
//   };

//   const handleDelete = (e) => {
//     e.preventDefault();
//     // Handle employee deletion logic here
//     console.log('Deleting employee with ID:', employeeId);
//   };

//   return (
//     <div>
//       <h2>Delete Employee</h2>
//       <form onSubmit={handleDelete}>
//         <Box sx={{ mb: 2 }}>
//           <TextField
//             label="Employee ID"
//             variant="outlined"
//             fullWidth
//             name="employeeId"
//             value={employeeId}
//             onChange={handleChange}
//             required
//           />
//         </Box>
//         <Button type="submit" variant="contained" color="secondary">Delete Employee</Button>
//       </form>
//     </div>
//   );
// };

// export default DeleteEmployee;
