// src/utils/activityLogger.js
import axios from 'axios';

// Utility function to log activity
export const logActivity = async (user, action, description) => {
  try {
    await axios.post('http://localhost:5001/api/activity-logs', {
      user,
      action,
      description,
    });
  } catch (error) {
    console.error('Failed to log activity:', error);
  }
};
