// GlobalFilter.jsx
import React from 'react';
import { InputGroup, Form } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';

const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
  return (
    <InputGroup>
      <InputGroup.Text><FaSearch /></InputGroup.Text>
      <Form.Control
        value={globalFilter || ''}
        onChange={(e) => setGlobalFilter(e.target.value)}
        placeholder="Search..."
      />
    </InputGroup>
  );
};

export default GlobalFilter;
