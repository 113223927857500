import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col, Card, Spinner, Alert, FormControl, InputGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Edit as EditIcon, Delete as DeleteIcon, Visibility as VisibilityIcon } from '@mui/icons-material';
import { useBranch } from '../Context/BranchContext'; // Import the useBranch hook
import 'bootstrap/dist/css/bootstrap.min.css';

const ViewClasses = () => {
  const { currentBranchId } = useBranch(); // Get the current branch ID from the context
  const [classes, setClasses] = useState([]); // Use 'classes' instead of 'students'
  const [filteredClasses, setFilteredClasses] = useState([]); // For filtered class data
  const [searchQuery, setSearchQuery] = useState(''); // State for search input
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({ show: false, message: '', type: '' });

  // Fetch classes whenever the current branch ID changes
  useEffect(() => {
    const fetchClassesByBranch = async () => {
      setLoading(true);
      console.log('Fetching classes for branch ID:', currentBranchId); // Debugging log

      // Reset alert when branch ID changes
      if (currentBranchId) {
        setAlert({ show: false, message: '', type: '' });
      }

      // If no branch ID, show an alert and return
      if (!currentBranchId) {
        setLoading(false); // Stop loading if no branch ID is available
        setAlert({ show: true, message: 'No branch selected.', type: 'warning' }); // Notify user
        return; // Exit if branch ID is not available
      }

      try {
        const response = await fetch(`http://localhost:5001/api/classes/branch/${currentBranchId}`); // Adjust the API route for classes

        // Check for response status
        if (!response.ok) {
          if (response.status === 404) {
            setAlert({ show: true, message: 'No classes found for this branch.', type: 'info' });
            setClasses([]); // Clear classes if no classes found
          } else {
            throw new Error(`HTTP error! Status: ${response.status}`); // Error handling for non-200 responses
          }
        } else {
          const data = await response.json();
          console.log('Classes data fetched:', data); // Debugging log
          setClasses(data);
          setFilteredClasses(data); // Initialize filtered classes with full data
        }
      } catch (error) {
        console.error('Error fetching classes:', error); // Log error details
        setAlert({ show: true, message: 'Failed to load class data.', type: 'danger' });
      } finally {
        setLoading(false);
      }
    };

    // Only fetch classes if the branch ID is defined
    if (currentBranchId) {
      fetchClassesByBranch(); // Call fetch function directly
    }
  }, [currentBranchId]); // Dependency on currentBranchId

  // Handle search input change and filter classes
  useEffect(() => {
    if (searchQuery.trim() !== '') {
      const filtered = classes.filter((classItem) =>
        classItem.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredClasses(filtered);
    } else {
      setFilteredClasses(classes); // If search query is empty, show all classes
    }
  }, [searchQuery, classes]); // Update when searchQuery or classes list changes

  const handleDelete = async (id) => {
    try {
      setAlert({ show: true, message: 'Deleting class...', type: 'info' });
      const response = await fetch(`http://localhost:5001/api/classes/${id}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Failed to delete class.'); // Handle potential errors during deletion
      }

      setClasses(classes.filter(classItem => classItem._id !== id));
      setFilteredClasses(filteredClasses.filter(classItem => classItem._id !== id)); // Update filtered list after deletion
      setAlert({ show: true, message: 'Class deleted successfully!', type: 'success' });
    } catch (error) {
      console.error('Error deleting class:', error); // Log error details
      setAlert({ show: true, message: 'Failed to delete class.', type: 'danger' });
    }
  };

  return (
    <Container fluid className="my-4">
      <Row className="mb-4">
        <Col>
          <h1 className="text-center mb-4" style={{ fontFamily: 'Arial, sans-serif', color: '#343a40' }}>Class Directory</h1>
        </Col>
      </Row>

      {/* Search bar to filter classes */}
      <Row className="mb-4">
        <Col>
          <InputGroup>
            <FormControl
              placeholder="Search classes by name..."
              aria-label="Search classes"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </InputGroup>
        </Col>
      </Row>

      {loading ? (
        <Row className="d-flex justify-content-center">
          <Spinner animation="border" />
        </Row>
      ) : (
        <Row>
          {filteredClasses.length > 0 ? (
            filteredClasses.map((classItem) => (
              <Col xs={12} sm={6} md={4} lg={3} key={classItem._id} className="mb-4">
                <Card>
                  <Card.Body>
                    <Card.Title className="text-primary">{classItem.name}</Card.Title>
                    <Card.Subtitle className="mb-2 text-muted">Branch: {classItem.branch}</Card.Subtitle>
                    <Card.Text>Start Date: {classItem.startDate}</Card.Text>
                    <div className="d-flex justify-content-between">
                      <Button variant="warning" as={Link} to={`/classes/edit/${classItem._id}`} className="me-2">
                        <EditIcon fontSize="small" /> Edit
                      </Button>
                      <Button variant="danger" onClick={() => handleDelete(classItem._id)} className="me-2">
                        <DeleteIcon fontSize="small" /> Delete
                      </Button>
                      <Button variant="outline-primary" as={Link} to={`/classes/details/${classItem._id}`}>
                        <VisibilityIcon fontSize="small" /> View
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))
          ) : (
            <Col className="text-center">
              <p>No classes found for this branch.</p>
            </Col>
          )}
        </Row>
      )}

      <Alert show={alert.show} variant={alert.type} onClose={() => setAlert({ ...alert, show: false })} dismissible>
        {alert.message}
      </Alert>
    </Container>
  );
};

export default ViewClasses;
