import React, { useState, useEffect } from 'react';
import { TextField, TableContainer, Paper, Snackbar } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';

const ContactUs = () => {
  const [messages, setMessages] = useState([]);
  const [search, setSearch] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const response = await axios.get('https://control.novelkoirala.com:5001/api/messages');
        setMessages(response.data);
      } catch (error) {
        console.error('Error fetching messages:', error.response?.data || error.message);
        setSnackbarMessage('Failed to load messages. Please try again.');
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      } finally {
        setLoading(false);
      }
    };

    fetchMessages();
  }, []);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://control.novelkoirala.com:5001/api/messages/${id}`);
      setMessages(messages.filter(message => message.id !== id));
      setSnackbarMessage('Message deleted successfully!');
      setSnackbarSeverity('success');
    } catch (error) {
      console.error('Error deleting message:', error.response?.data || error.message);
      setSnackbarMessage('Failed to delete message. Please try again.');
      setSnackbarSeverity('error');
    } finally {
      setOpenSnackbar(true);
    }
  };

  const filteredMessages = messages.filter(message =>
    message.name.toLowerCase().includes(search.toLowerCase()) ||
    message.email.toLowerCase().includes(search.toLowerCase())
  );

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'message', headerName: 'Message', width: 400 },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <div>
          <IconButton onClick={() => handleDelete(params.row.id)} color="error">
            <DeleteIcon />
          </IconButton>
          <IconButton color="primary">
            <EditIcon />
          </IconButton>
        </div>
      )
    },
  ];

  return (
    <div style={{ padding: '20px' }}>
      <h2>Contact Us Messages</h2>
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        value={search}
        onChange={handleSearchChange}
        sx={{ mb: 2 }}
      />
      <TableContainer component={Paper}>
        <DataGrid
          rows={filteredMessages}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
          loading={loading}
        />
      </TableContainer>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ContactUs;



// import React, { useState } from 'react';
// import { TextField, TableContainer, Paper, Snackbar } from '@mui/material';
// import { DataGrid } from '@mui/x-data-grid';
// import Alert from '@mui/material/Alert';
// import IconButton from '@mui/material/IconButton';
// import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';

// const ContactUs = () => {
//   const [messages, setMessages] = useState([
//     { id: 1, name: 'Alice Johnson', email: 'alice.johnson@example.com', message: 'I am interested in your services.' },
//     { id: 2, name: 'Bob Smith', email: 'bob.smith@example.com', message: 'Can I schedule a meeting?' },
//     { id: 3, name: 'Charlie Brown', email: 'charlie.brown@example.com', message: 'Do you offer support for your products?' },
//   ]);

//   const [search, setSearch] = useState('');
//   const [openSnackbar, setOpenSnackbar] = useState(false);
//   const [snackbarMessage, setSnackbarMessage] = useState('');

//   const handleSearchChange = (e) => {
//     setSearch(e.target.value);
//   };

//   const handleDelete = (id) => {
//     setMessages(messages.filter(message => message.id !== id));
//     setSnackbarMessage('Message deleted successfully!');
//     setOpenSnackbar(true);
//   };

//   const filteredMessages = messages.filter(message =>
//     message.name.toLowerCase().includes(search.toLowerCase()) ||
//     message.email.toLowerCase().includes(search.toLowerCase())
//   );

//   const columns = [
//     { field: 'id', headerName: 'ID', width: 90 },
//     { field: 'name', headerName: 'Name', width: 150 },
//     { field: 'email', headerName: 'Email', width: 200 },
//     { field: 'message', headerName: 'Message', width: 400 },
//     { field: 'actions', headerName: 'Actions', width: 150, renderCell: (params) => (
//       <div>
//         <IconButton onClick={() => handleDelete(params.row.id)} color="error">
//           <DeleteIcon />
//         </IconButton>
//         <IconButton color="primary">
//           <EditIcon />
//         </IconButton>
//       </div>
//     ) },
//   ];

//   return (
//     <div style={{ padding: '20px' }}>
//       <h2>Contact Us Messages</h2>
//       <TextField
//         label="Search"
//         variant="outlined"
//         fullWidth
//         value={search}
//         onChange={handleSearchChange}
//         sx={{ mb: 2 }}
//       />
//       <TableContainer component={Paper}>
//         <DataGrid
//           rows={filteredMessages}
//           columns={columns}
//           pageSize={10}
//           rowsPerPageOptions={[10]}
//           disableSelectionOnClick
//         />
//       </TableContainer>
//       <Snackbar
//         open={openSnackbar}
//         autoHideDuration={6000}
//         onClose={() => setOpenSnackbar(false)}
//       >
//         <Alert onClose={() => setOpenSnackbar(false)} severity="success">
//           {snackbarMessage}
//         </Alert>
//       </Snackbar>
//     </div>
//   );
// };

// export default ContactUs;
