import React, { useState, useEffect } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import config from '../../components/config/config';

const RoleManagement = () => {
  const [employees, setEmployees] = useState([]);
  const [branches, setBranches] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [role, setRole] = useState(""); // Role state
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [retypePassword, setRetypePassword] = useState("");

  // Fetch employee and branch data from backend API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const employeeResponse = await fetch(`${config.API_BASE_URL}/api/employees`);
        if (!employeeResponse.ok) throw new Error('Failed to fetch employees');
        const employeesData = await employeeResponse.json();
        setEmployees(employeesData);

        const branchResponse = await fetch(`${config.API_BASE_URL}/api/branch`);
        if (!branchResponse.ok) throw new Error('Failed to fetch branches');
        const branchesData = await branchResponse.json();
        setBranches(branchesData);
      } catch (error) {
        console.error("Error fetching data:", error);
        alert("Failed to fetch data from the server. Please try again later.");
      }
    };

    fetchData();
  }, []);

  // Update role based on selected employee
  useEffect(() => {
    if (selectedEmployee) {
      const employee = employees.find(emp => emp._id === selectedEmployee);
      if (employee) {
        setRole(employee.role);
      }
    }
  }, [selectedEmployee, employees]);

  const handleSave = () => {
    if (!selectedEmployee || !selectedBranch || !role || !username || !password || !retypePassword) {
      alert("Please fill all fields");
      return;
    }

    if (password !== retypePassword) {
      alert("Passwords do not match");
      return;
    }

    const newManagerData = {
      employeeId: selectedEmployee,
      branchId: selectedBranch,
      role,
      username,
      password,
      retypePassword,
    };

    fetch(`${config.API_BASE_URL}/api/create-manager`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newManagerData),
    })
      .then((response) => {
        if (!response.ok) throw new Error('Failed to create manager');
        return response.json();
      })
      .then(() => {
        alert("Manager created successfully!");
        // Clear fields after successful update
        setSelectedEmployee("");
        setSelectedBranch("");
        setRole("");
        setUsername("");
        setPassword("");
        setRetypePassword("");
      })
      .catch((error) => {
        console.error("Error creating manager:", error);
        alert("Failed to create manager. Please try again later.");
      });
  };

  return (
    <Container className="mt-5">
      <h3 className="text-center mb-4 text-primary font-weight-bold">
        Manage Employee Roles & Branches
      </h3>
      <Row className="justify-content-center">
        <Col md={8} lg={6}>
          <Form className="p-4 border rounded shadow-sm bg-light">
            {/* Employee Dropdown */}
            <Form.Group controlId="employeeSelect" className="mb-3">
              <Form.Label>Employee</Form.Label>
              <Form.Control
                as="select"
                value={selectedEmployee}
                onChange={(e) => setSelectedEmployee(e.target.value)}
              >
                <option value="">Select Employee</option>
                {employees.map((employee) => (
                  <option key={employee._id} value={employee._id}>
                    {employee.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            {/* Branch Dropdown */}
            <Form.Group controlId="branchSelect" className="mb-3">
              <Form.Label>Branch</Form.Label>
              <Form.Control
                as="select"
                value={selectedBranch}
                onChange={(e) => setSelectedBranch(e.target.value)}
              >
                <option value="">Select Branch</option>
                {branches.map((branch) => (
                  <option key={branch._id} value={branch._id}>
                    {branch.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            {/* Role Display */}
            <Form.Group controlId="roleDisplay" className="mb-3">
              <Form.Label>Role</Form.Label>
              <Form.Control
                type="text"
                value={role}
                readOnly
                placeholder="Role is auto-determined"
              />
            </Form.Group>

            {/* Username Field */}
            <Form.Group controlId="username" className="mb-3">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Enter username"
              />
            </Form.Group>

            {/* Password Field */}
            <Form.Group controlId="password" className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter password"
              />
            </Form.Group>

            {/* Retype Password Field */}
            <Form.Group controlId="retypePassword" className="mb-3">
              <Form.Label>Retype Password</Form.Label>
              <Form.Control
                type="password"
                value={retypePassword}
                onChange={(e) => setRetypePassword(e.target.value)}
                placeholder="Retype password"
              />
            </Form.Group>

            {/* Save Button */}
            <Button
              variant="primary"
              className="w-100 mt-3"
              onClick={handleSave}
            >
              Save Changes
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default RoleManagement;
