import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import axios from 'axios';

const StudentAttendance = () => {
  const [attendance, setAttendance] = useState({
    studentId: '',
    date: '',
    status: ''
  });

  const [students, setStudents] = useState([]);

  useEffect(() => {
    const fetchStudents = async () => {
      try {
        const response = await axios.get('https://control.novelkoirala.com:5001/api/students');
        setStudents(response.data);
      } catch (error) {
        console.error('Error fetching students:', error.response ? error.response.data : error.message);
      }
    };

    fetchStudents();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAttendance({ ...attendance, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://control.novelkoirala.com:5001/api/students-attendance', {
        studentId: attendance.studentId, // Use studentId here
        date: attendance.date,
        status: attendance.status
      });

      console.log(response.data);
      setAttendance({
        studentId: '', // Reset studentId after submission
        date: '',
        status: ''
      });
    } catch (error) {
      console.error('Error saving student attendance:', error.response ? error.response.data : error.message);
    }
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={8}>
          <Card>
            <Card.Body>
              <h2 className="text-center mb-4 text-primary">Student Attendance</h2>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="studentId" className="mb-3">
                  <Form.Label>Student Name</Form.Label>
                  <Form.Control
                    as="select"
                    name="studentId"
                    value={attendance.studentId}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select Student</option>
                    {students.map((student) => (
                      <option key={student._id} value={student._id}>
                        {student.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>

                <Form.Group controlId="date" className="mb-3">
                  <Form.Label>Date</Form.Label>
                  <Form.Control
                    type="date"
                    name="date"
                    value={attendance.date}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>

                <Form.Group controlId="status" className="mb-3">
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select"
                    name="status"
                    value={attendance.status}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select Status</option>
                    <option value="Present">Present</option>
                    <option value="Absent">Absent</option>
                  </Form.Control>
                </Form.Group>

                <Button variant="primary" type="submit" className="w-100">
                  Save Attendance
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default StudentAttendance;
