// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4A90E2', // Muted blue
    },
    secondary: {
      main: '#4CAF50', // Teal accent
    },
    background: {
      default: '#F5F5F5', // Light gray background
    },
    text: {
      primary: '#333333', // Dark gray text
      secondary: '#757575', // Light gray text
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF', // Card background
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Soft shadow for depth
        },
      },
    },
  },
});

export default theme;
