import React, { useState,useEffect } from 'react';
import { Container, Form, Button, Row, Col, Card,Alert } from 'react-bootstrap';
import axios from 'axios';
import config from '../../components/config/config';
import { logActivity } from '../ActivityLogs/ActivityLogger';

const AddEmployeeForm = () => {
  const [employee, setEmployee] = useState({
    employeeNumber: '',
    staffNumber: '',
    name: '',
    mobileNo: '',
    dateOfJoining: '',
    role: '',
    nationality: '',
    dateOfBirth: '',
    salary: '',
    transportationFare: '',
    religion: '',
    nationalId: '',
    entryDate: '',
    leaveDate: '',
    email: '',
    homeAddress: '',
    emergencyContactNumber: '',
    visaStatus: '',
    residenceCardNumber: '',
    visaExpiredDate: '',
    profilePicture: null,
    gender: '',
    branch: ''
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(''); // Success message state
  const [validated, setValidated] = useState(false);
  const [userBranches, setUserBranches] = useState([]); // Branches available to the user
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [branches, setBranches] = useState([]);
  const [user, setUser] = useState(null);
  const [showProfile, setShowProfile] = useState(false);
  const [isBranchesLoaded, setIsBranchesLoaded] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmployee({ ...employee, [name]: value });
  };




  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await fetch(`${config.API_BASE_URL}/api/branch`);
        if (!response.ok) throw new Error('Failed to fetch branches');
        const data = await response.json();
        setBranches(data);
        setIsBranchesLoaded(true); // Set branches loaded to true
        console.log('Branches fetched:', data); // Debugging
      } catch (error) {
        console.error('Error fetching branches:', error);
      }
    };

    fetchBranches();
  }, []);

  // Fetch user details using JWT token
  useEffect(() => {
    const fetchUser = async () => {
      try {
        if (!isBranchesLoaded) return; // Wait until branches are loaded

        const token = localStorage.getItem('token'); // Assuming the token is stored in localStorage
        if (!token) {
          console.error('No token found'); // Debugging
          // Redirect to login if token is missing
          return;
        }

        const response = await fetch(`${config.API_BASE_URL}/api/profile`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          const errorMessage = await response.text(); // Get the error message from the response
          throw new Error(`Failed to fetch user data: ${response.status} - ${errorMessage}`);
        }

        const data = await response.json();
        setUser(data);
        console.log('User fetched:', data); // Debugging

        // Determine branches available to the user based on role
        const assignedBranch = branches.find(branch => branch._id === data.branchId);
        if (data.role === 'Admin') {
          setUserBranches(branches); // Admin sees all branches
        } else {
          setUserBranches(assignedBranch ? [assignedBranch] : []); // Non-admin sees only their branch
        }

        // Set the selected branch to the user's branch
        setSelectedBranch(assignedBranch);

        console.log('User branches available:', userBranches); // Debugging
      } catch (error) {
        console.error('Error fetching user data:', error);
        // Redirect to login on error
      }
    };

    fetchUser();
  }, [isBranchesLoaded, branches]);

  const handleBranchChange = (e) => {
    const selectedBranchId = e.target.value; // Get the branch _id from the dropdown value
    setSelectedBranch(selectedBranchId); // Update the selected branch state
    setEmployee({ ...employee, branchName: selectedBranchId }); // Ensure this matches the backend schema
  };
  











  const handleFileChange = (e) => {
    setEmployee({ ...employee, profilePicture: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
  
    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      setLoading(true);
      const formData = new FormData();
      for (const key in employee) {
        formData.append(key, employee[key]);
      }
  
      try {
        const response = await axios.post(`${config.API_BASE_URL}/api/employees`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
  
        setSuccess(true);
        setSuccessMessage('Employee added successfully!');
        await logActivity(`${user.username}`, 'Add', `Added Employee: ${employee.name}`);
        setError(null);
      } catch (error) {
        setError('Failed to add employee. Please try again.');
        setSuccess(false);
      } finally {
        setLoading(false);
      }
    }
    setValidated(true);
  };
  

  return (
    <Container>
      <Card className="my-4">
        <Card.Header as="h5">Add New Employee</Card.Header>
        <Card.Body>
        {success && (
            <Alert variant="success" onClose={() => setSuccess(false)} dismissible>
              {successMessage}
            </Alert>
          )}
          {error && (
            <Alert variant="danger" onClose={() => setError(null)} dismissible>
              {error}
            </Alert>
          )}
          <Form noValidate validated={validated} onSubmit={handleSubmit} encType="multipart/form-data">
            {/* Employee Number and Staff Number */}
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="formEmployeeNumber">
                <Form.Label>Employee Number*</Form.Label>
                <Form.Control
                  type="text"
                  name="employeeNumber"
                  value={employee.employeeNumber}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">Employee number is required.</Form.Control.Feedback>
              </Form.Group>
  
              <Form.Group as={Col} md="6" controlId="formStaffNumber">
                <Form.Label>Staff Number*</Form.Label>
                <Form.Control
                  type="text"
                  name="staffNumber"
                  value={employee.staffNumber}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">Staff number is required.</Form.Control.Feedback>
              </Form.Group>
            </Row>
  
            {/* Mobile Number and Education */}
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="formMobileNo">
                <Form.Label>Mobile Number*</Form.Label>
                <Form.Control
                  type="text"
                  name="mobileNo"
                  value={employee.mobileNo}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">Mobile number is required.</Form.Control.Feedback>
              </Form.Group>
  
              <Form.Group as={Col} md="6" controlId="formEducation">
                <Form.Label>Education*</Form.Label>
                <Form.Control
                  type="text"
                  name="education"
                  value={employee.education}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">Education is required.</Form.Control.Feedback>
              </Form.Group>
            </Row>
              {/* Profile Picture Upload */}
              <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="formProfilePicture">
                <Form.Label>Profile Picture</Form.Label>
                <Form.Control
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </Form.Group>
            </Row>
  
            {/* Experience and Gender */}
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="formExperience">
                <Form.Label>Experience*</Form.Label>
                <Form.Control
                  type="text"
                  name="experience"
                  value={employee.experience}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">Experience is required.</Form.Control.Feedback>
              </Form.Group>
  
              <Form.Group as={Col} md="6" controlId="formGender">
                <Form.Label>Gender*</Form.Label>
                <Form.Control
                  as="select"
                  name="gender"
                  value={employee.gender}
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">Please select a gender.</Form.Control.Feedback>
              </Form.Group>
            </Row>
  
            {/* Employee Name and Date of Joining */}
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="formEmployeeName">
                <Form.Label>Employee Name*</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={employee.name}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">Employee name is required.</Form.Control.Feedback>
              </Form.Group>
  
              <Form.Group as={Col} md="6" controlId="formDateOfJoining">
                <Form.Label>Date of Joining*</Form.Label>
                <Form.Control
                  type="date"
                  name="dateOfJoining"
                  value={employee.dateOfJoining}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">Please select a joining date.</Form.Control.Feedback>
              </Form.Group>
            </Row>
  
            {/* Employee Role and Nationality */}
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="formRole">
                <Form.Label>Employee Role*</Form.Label>
                <Form.Control
                  type="text"
                  name="role"
                  value={employee.role}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">Role is required.</Form.Control.Feedback>
              </Form.Group>
  
              <Form.Group as={Col} md="6" controlId="formNationality">
                <Form.Label>Nationality*</Form.Label>
                <Form.Control
                  type="text"
                  name="nationality"
                  value={employee.nationality}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">Nationality is required.</Form.Control.Feedback>
              </Form.Group>
            </Row>
  
            {/* Date of Birth and Salary */}
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="formDateOfBirth">
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control
                  type="date"
                  name="dateOfBirth"
                  value={employee.dateOfBirth}
                  onChange={handleChange}
                />
              </Form.Group>
  
              <Form.Group as={Col} md="6" controlId="formSalary">
                <Form.Label>Monthly Salary*</Form.Label>
                <Form.Control
                  type="number"
                  name="salary"
                  value={employee.salary}
                  onChange={handleChange}
                  min="0"
                  required
                />
                <Form.Control.Feedback type="invalid">Please input a valid salary.</Form.Control.Feedback>
              </Form.Group>
            </Row>
  
            {/* Branch Selection */}
            <Row className="mb-3">
              <Form.Group as={Col} md="12" controlId="formBranch">
                <Form.Label>Select Branch</Form.Label>
                <Form.Select value={selectedBranch ? selectedBranch : ''} onChange={handleBranchChange} required>
                  <option value="">Select Branch</option>
                  {userBranches.length > 0 ? (
                    userBranches.map((branch) => (
                      <option key={branch._id} value={branch._id}>
                        {branch.name}
                      </option>
                    ))
                  ) : (
                    <option disabled>No branches available</option>
                  )}
                </Form.Select>
                <Form.Control.Feedback type="invalid">Please select a branch.</Form.Control.Feedback>
              </Form.Group>
            </Row>
  
            {/* Additional fields like Transportation Fare, Religion, etc. */}
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="formTransportationFare">
                <Form.Label>Transportation Fare</Form.Label>
                <Form.Control
                  type="number"
                  name="transportationFare"
                  value={employee.transportationFare}
                  onChange={handleChange}
                />
              </Form.Group>
  
              <Form.Group as={Col} md="6" controlId="formReligion">
                <Form.Label>Religion</Form.Label>
                <Form.Control
                  type="text"
                  name="religion"
                  value={employee.religion}
                  onChange={handleChange}
                />
              </Form.Group>
            </Row>
  
            {/* National ID and Entry Date */}
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="formNationalId">
                <Form.Label>National ID*</Form.Label>
                <Form.Control
                  type="text"
                  name="nationalId"
                  value={employee.nationalId}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">National ID is required.</Form.Control.Feedback>
              </Form.Group>
  
              <Form.Group as={Col} md="6" controlId="formEntryDate">
                <Form.Label>Entry Date*</Form.Label>
                <Form.Control
                  type="date"
                  name="entryDate"
                  value={employee.entryDate}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">Please select an entry date.</Form.Control.Feedback>
              </Form.Group>
            </Row>
  
            {/* Leave Date and Emergency Contact Number */}
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="formLeaveDate">
                <Form.Label>Leave Date</Form.Label>
                <Form.Control
                  type="date"
                  name="leaveDate"
                  value={employee.leaveDate}
                  onChange={handleChange}
                />
              </Form.Group>
  
              <Form.Group as={Col} md="6" controlId="formEmergencyContactNumber">
                <Form.Label>Emergency Contact Number*</Form.Label>
                <Form.Control
                  type="text"
                  name="emergencyContactNumber"
                  value={employee.emergencyContactNumber}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">Emergency contact number is required.</Form.Control.Feedback>
              </Form.Group>
            </Row>
  
            {/* Email and Home Address */}
            <Row className="mb-3">
              <Form.Group as={Col} md="6" controlId="formEmail">
                <Form.Label>Email*</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={employee.email}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">Email is required.</Form.Control.Feedback>
              </Form.Group>
  
              <Form.Group as={Col} md="6" controlId="formHomeAddress">
                <Form.Label>Home Address*</Form.Label>
                <Form.Control
                  type="text"
                  name="homeAddress"
                  value={employee.homeAddress}
                  onChange={handleChange}
                  required
                />
                <Form.Control.Feedback type="invalid">Home address is required.</Form.Control.Feedback>
              </Form.Group>
            </Row>
  
            {/* Submit Button */}
            <Button type="submit">Add Employee</Button>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
  
    
 
};

export default AddEmployeeForm;




