import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Form, Button, Container, Row, Col, Alert, Spinner } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import config from '../../components/config/config';

const EditEmployee = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [employee, setEmployee] = useState({
    employeeNumber: '',
    staffNumber: '',
    branchName: '',
    name: '',
    mobileNo: '',
    dateOfJoining: '',
    role: '',
    nationality: '',
    dateOfBirth: '',
    salary: '',
    transportationFare: '',
    religion: '',
    nationalId: '',
    entryDate: '',
    leaveDate: '',
    email: '',
    homeAddress: '',
    emergencyContactNumber: '',
    visaStatus: '',
    residenceCardNumber: '',
    visaExpiredDate: '',
    profilePicture: null,
    gender: ''
  });
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({ show: false, message: '', type: '' });
  const [file, setFile] = useState(null);

  useEffect(() => {
    const fetchEmployee = async () => {
      try {
        const response = await axios.get(`${config.API_BASE_URL}/api/employees/${id}`);
        setEmployee(response.data);
        setLoading(false);
      } catch (error) {
        setAlert({ show: true, message: 'Failed to load employee details', type: 'danger' });
        setLoading(false);
      }
    };
    fetchEmployee();
  }, [id]);

  const handleChange = (e) => {
    setEmployee({ ...employee, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formData = new FormData();
    Object.keys(employee).forEach((key) => {
      formData.append(key, employee[key]);
    });

    if (file) {
      formData.append('profilePicture', file);
    }

    try {
      await axios.put(`${config.API_BASE_URL}/api/employees/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setAlert({ show: true, message: 'Employee updated successfully!', type: 'success' });
      setLoading(false);
      navigate('/employees/view');
    } catch (error) {
      setAlert({ show: true, message: 'Failed to update employee', type: 'danger' });
      setLoading(false);
    }
  };

  return (
    <Container className="my-5">
      <h2 className="text-center mb-4">Edit Employee</h2>

      {loading ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" />
        </div>
      ) : (
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formEmployeeNumber" className="mb-3">
                <Form.Label>Employee Number</Form.Label>
                <Form.Control
                  type="text"
                  name="employeeNumber"
                  value={employee.employeeNumber}
                  onChange={handleChange}
                  required
                  className="rounded-pill"
                />
              </Form.Group>

              <Form.Group controlId="formStaffNumber" className="mb-3">
                <Form.Label>Staff Number</Form.Label>
                <Form.Control
                  type="text"
                  name="staffNumber"
                  value={employee.staffNumber}
                  onChange={handleChange}
                  required
                  className="rounded-pill"
                />
              </Form.Group>

              <Form.Group controlId="formBranchName" className="mb-3">
                <Form.Label>Branch Name</Form.Label>
                <Form.Control
                  type="text"
                  name="branchName"
                  value={employee.branchName}
                  onChange={handleChange}
                  required
                  className="rounded-pill"
                />
              </Form.Group>

              <Form.Group controlId="formName" className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={employee.name}
                  onChange={handleChange}
                  required
                  className="rounded-pill"
                />
              </Form.Group>

              <Form.Group controlId="formMobileNo" className="mb-3">
                <Form.Label>Mobile No</Form.Label>
                <Form.Control
                  type="text"
                  name="mobileNo"
                  value={employee.mobileNo}
                  onChange={handleChange}
                  required
                  className="rounded-pill"
                />
              </Form.Group>

              <Form.Group controlId="formEmail" className="mb-3">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={employee.email}
                  onChange={handleChange}
                  required
                  className="rounded-pill"
                />
              </Form.Group>

              <Form.Group controlId="formRole" className="mb-3">
                <Form.Label>Role</Form.Label>
                <Form.Control
                  type="text"
                  name="role"
                  value={employee.role}
                  onChange={handleChange}
                  required
                  className="rounded-pill"
                />
              </Form.Group>

              <Form.Group controlId="formSalary" className="mb-3">
                <Form.Label>Salary</Form.Label>
                <Form.Control
                  type="number"
                  name="salary"
                  value={employee.salary}
                  onChange={handleChange}
                  required
                  className="rounded-pill"
                />
              </Form.Group>

              <Form.Group controlId="formTransportationFare" className="mb-3">
                <Form.Label>Transportation Fare</Form.Label>
                <Form.Control
                  type="number"
                  name="transportationFare"
                  value={employee.transportationFare}
                  onChange={handleChange}
                  required
                  className="rounded-pill"
                />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group controlId="formDateOfJoining" className="mb-3">
                <Form.Label>Date of Joining</Form.Label>
                <Form.Control
                  type="date"
                  name="dateOfJoining"
                  value={employee.dateOfJoining.split('T')[0]}
                  onChange={handleChange}
                  required
                  className="rounded-pill"
                />
              </Form.Group>

              <Form.Group controlId="formDateOfBirth" className="mb-3">
                <Form.Label>Date of Birth</Form.Label>
                <Form.Control
                  type="date"
                  name="dateOfBirth"
                  value={employee.dateOfBirth.split('T')[0]}
                  onChange={handleChange}
                  required
                  className="rounded-pill"
                />
              </Form.Group>

              <Form.Group controlId="formProfilePicture" className="mb-3">
                <Form.Label>Profile Picture</Form.Label>
                <Form.Control type="file" onChange={handleFileChange} className="rounded-pill" />
              </Form.Group>

              <Form.Group controlId="formHomeAddress" className="mb-3">
                <Form.Label>Home Address</Form.Label>
                <Form.Control
                  type="text"
                  name="homeAddress"
                  value={employee.homeAddress}
                  onChange={handleChange}
                  required
                  className="rounded-pill"
                />
              </Form.Group>

              <Form.Group controlId="formEmergencyContactNumber" className="mb-3">
                <Form.Label>Emergency Contact Number</Form.Label>
                <Form.Control
                  type="text"
                  name="emergencyContactNumber"
                  value={employee.emergencyContactNumber}
                  onChange={handleChange}
                  required
                  className="rounded-pill"
                />
              </Form.Group>

              <Form.Group controlId="formVisaStatus" className="mb-3">
                <Form.Label>Visa Status</Form.Label>
                <Form.Control
                  type="text"
                  name="visaStatus"
                  value={employee.visaStatus}
                  onChange={handleChange}
                  required
                  className="rounded-pill"
                />
              </Form.Group>

              <Form.Group controlId="formResidenceCardNumber" className="mb-3">
                <Form.Label>Residence Card Number</Form.Label>
                <Form.Control
                  type="text"
                  name="residenceCardNumber"
                  value={employee.residenceCardNumber}
                  onChange={handleChange}
                  required
                  className="rounded-pill"
                />
              </Form.Group>

              <Form.Group controlId="formVisaExpiredDate" className="mb-3">
                <Form.Label>Visa Expired Date</Form.Label>
                <Form.Control
                  type="date"
                  name="visaExpiredDate"
                  value={employee.visaExpiredDate.split('T')[0]}
                  onChange={handleChange}
                  required
                  className="rounded-pill"
                />
              </Form.Group>
            </Col>
          </Row>

          <div className="d-flex justify-content-center">
            <Button variant="primary" type="submit" className="px-4 rounded-pill">
              Update Employee
            </Button>
          </div>

          {alert.show && (
            <Alert variant={alert.type} className="mt-3">
              {alert.message}
            </Alert>
          )}
        </Form>
      )}
    </Container>
  );
};

export default EditEmployee;
