import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Card, Row, Col, Alert } from 'react-bootstrap';
import axios from 'axios';
import config from '../../components/config/config';
import { logActivity } from '../ActivityLogs/ActivityLogger';

const AddBranch = ({ onBranchCreated }) => {
  const [branch, setBranch] = useState({
    name: '',
    location: '',
    contactNumber: '',
    email: '',
    managerName: '',
  });

  const [userBranches, setUserBranches] = useState([]); // Branches available to the user
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [branches, setBranches] = useState([]);
  const [user, setUser] = useState(null);
  const [showProfile, setShowProfile] = useState(false);
  const [isBranchesLoaded, setIsBranchesLoaded] = useState(false);

  const [managers, setManagers] = useState([]); // Ensure this is initialized as an array
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    const fetchManagers = async () => {
      try {
        const response = await axios.get(`${config.API_BASE_URL}/api/employees`); // Fetch all employees
        const employees = response.data;
        if (Array.isArray(employees)) {
          // Filter employees to get only managers
          const filteredManagers = employees.filter(emp => emp.role === 'manager');
          setManagers(filteredManagers); // Set the filtered managers
        } else {
          console.error('Unexpected data format:', employees);
          setManagers([]); // Handle unexpected format by resetting to an empty array
        }
      } catch (error) {
        console.error('Error fetching managers:', error);
        setManagers([]); // Handle error by resetting to an empty array
      }
    };

    fetchManagers();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBranch({
      ...branch,
      [name]: value,
    });
  };
  
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          console.error('No token found');
          return;
        }
  
        const response = await fetch(`${config.API_BASE_URL}/api/profile`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
  
        if (!response.ok) {
          const errorMessage = await response.text();
          throw new Error(`Failed to fetch user data: ${response.status} - ${errorMessage}`);
        }
  
        const data = await response.json();
        setUser(data);
  
        // If branches are not yet loaded, handle this by fetching or defaulting
        const assignedBranch = branches.length > 0 
          ? branches.find(branch => branch._id === data.branchId)
          : null;
  
        if (data.role === 'Admin') {
          setUserBranches(branches); // Admin sees all branches
        } else {
          setUserBranches(assignedBranch ? [assignedBranch] : []); // Non-admin sees their branch
        }
  
        setSelectedBranch(assignedBranch);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
  
    fetchUser();
  }, []); // No dependencies here
  
  


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${config.API_BASE_URL}/api/branch`, branch); // Replace with your endpoint
      setShowAlert(true);
      setBranch({
        name: '',
        location: '',
        contactNumber: '',
        email: '',
        managerName: '',
      });
      // Check if user exists before logging activity
    if (user && user.username) {
      await logActivity(`${user.username}`, 'Add', `Added Branch: ${branch.name}`);
    } else {
      console.warn('User data is missing, unable to log activity.');
    }
      onBranchCreated();
     
      setTimeout(() => {
        setShowAlert(false);
      }, 2000);
    } catch (error) {
      console.error('Error creating branch:', error);
    }
  };

  return (
    <Container className="my-4">
      <Row className="justify-content-center">
        <Col md={8}>
          <Card>
            <Card.Body>
              <Card.Title className="text-center">Add New Branch</Card.Title>
              {showAlert && (
                <Alert variant="success" className="text-center">
                  Branch Added Successfully!
                </Alert>
              )}
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formBranchName" className="mb-3">
                  <Form.Label>Branch Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter branch name"
                    name="name"
                    value={branch.name}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formBranchLocation" className="mb-3">
                  <Form.Label>Location</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter location"
                    name="location"
                    value={branch.location}
                    onChange={handleChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formBranchContactNumber" className="mb-3">
                  <Form.Label>Contact Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter contact number"
                    name="contactNumber"
                    value={branch.contactNumber}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="formBranchEmail" className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    name="email"
                    value={branch.email}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group controlId="formBranchManagerName" className="mb-3">
                  <Form.Label>Manager Name</Form.Label>
                  <Form.Control
                    as="select"
                    name="managerName"
                    value={branch.managerName}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select manager</option>
                    {managers.map(manager => (
                      <option key={manager._id} value={manager.name}>
                        {manager.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Button variant="primary" type="submit" className="w-100">
                  Add Branch
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AddBranch;
