import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, Table, Card } from 'react-bootstrap'; // Import Bootstrap components
import axios from 'axios'; // Import axios for making HTTP requests

const EmployeeAttendanceReport = () => {
  const [filters, setFilters] = useState({
    startDate: '',
    endDate: '',
    employeeName: '',
    status: ''
  });

  const [rows, setRows] = useState([]); // This will hold the fetched data

  useEffect(() => {
    // Fetch data based on filters
    const fetchAttendanceData = async () => {
      try {
        const response = await axios.get('https://control.novelkoirala.com:5001/api/employee-attendance', { 
          params: filters 
        });
        setRows(response.data);
      } catch (error) {
        console.error('Error fetching attendance data:', error.response ? error.response.data : error.message);
      }
    };

    fetchAttendanceData();
  }, [filters]); // Fetch data whenever filters change

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const handleSearch = () => {
    // Fetch data based on updated filters
    const fetchAttendanceData = async () => {
      try {
        const response = await axios.get('http://localhost:5001/api/employee-attendance', {
          params: filters
        });
        setRows(response.data);
      } catch (error) {
        console.error('Error fetching attendance data:', error.response ? error.response.data : error.message);
      }
    };

    fetchAttendanceData();
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-center">
        <Col md={12}>
          <Card>
            <Card.Body>
              <h2 className="text-center mb-4 text-primary">Employee Attendance Report</h2>
              <Form>
                <Row className="mb-3">
                  <Col md={3}>
                    <Form.Group controlId="employeeName">
                      <Form.Label>Employee Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="employeeName"
                        value={filters.employeeName}
                        onChange={handleChange}
                        placeholder="Enter employee name"
                      />
                    </Form.Group>
                  </Col>

                  <Col md={3}>
                    <Form.Group controlId="startDate">
                      <Form.Label>Start Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="startDate"
                        value={filters.startDate}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={3}>
                    <Form.Group controlId="endDate">
                      <Form.Label>End Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="endDate"
                        value={filters.endDate}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={3}>
                    <Form.Group controlId="status">
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        as="select"
                        name="status"
                        value={filters.status}
                        onChange={handleChange}
                      >
                        <option value="">All</option>
                        <option value="Present">Present</option>
                        <option value="Absent">Absent</option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Button variant="primary" onClick={handleSearch} className="w-100">
                  Search
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="mt-4">
        <Col md={12}>
          <Card>
            <Card.Body>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Employee Name</th>
                    <th>Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {rows.length > 0 ? (
                    rows.map((row) => (
                      <tr key={row._id}>
                        <td>{row._id}</td>
                        <td>{row.employeeId.name}</td> {/* Assuming employeeId is populated with the employee name */}
                        <td>{new Date(row.date).toLocaleDateString()}</td>
                        <td>{row.status}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="text-center">
                        No data available
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default EmployeeAttendanceReport;
