import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Table, Card, Alert, Button, Form, FormControl } from 'react-bootstrap';
import axios from 'axios';
import JobApplicationDetail from './JobApplicationDetail';

const JobApplicationsPage = () => {
  const [applications, setApplications] = useState([]);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        const response = await axios.get('https://control.novelkoirala.com:5001/applications');
        console.log(response.data); // Check the structure of the response
        setApplications(response.data);
      } catch (error) {
        setError('Failed to fetch applications');
      }
    };

    fetchApplications();
  }, []);

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const filteredApplications = applications.filter((app) =>
    app.name && app.name.toLowerCase().includes(searchTerm) // Check for app.name existence
  );

  return (
    <Container fluid className="mt-4">
      {error && <Alert variant="danger">{error}</Alert>}
      
      <Row>
        <Col>
          <h2 className="text-center text-primary">Manage Job Applications</h2>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col md={6}>
          <Form inline="true">
            <FormControl
              type="text"
              placeholder="Search by Applicant Name"
              className="mr-sm-2"
              onChange={handleSearch}
            />
          </Form>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card className="shadow-sm">
            <Card.Body>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Applicant Name</th>
                    <th>Email</th>
                    <th>Job Title</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredApplications.map((app) => (
                    <tr key={app._id}>
                      <td>{app.name}</td>
                      <td>{app.email}</td>
                      <td>{app.jobTitle}</td>
                      <td>{app.status}</td>
                      <td>
                        <Button
                          variant="primary"
                          onClick={() => setSelectedApplication(app)}
                        >
                          View Details
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {selectedApplication && (
        <JobApplicationDetail application={selectedApplication} />
      )}
    </Container>
  );
};

export default JobApplicationsPage;


// import React, { useState, useEffect } from 'react';
// import { Container, Row, Col, Table, Card, Alert, Button, Form, FormControl } from 'react-bootstrap';
// import axios from 'axios';
// import JobApplicationDetail from './JobApplicationDetail';

// const JobApplicationsPage = () => {
//   const [applications, setApplications] = useState([]);
//   const [selectedApplication, setSelectedApplication] = useState(null);
//   const [error, setError] = useState(null);
//   const [searchTerm, setSearchTerm] = useState('');

//   useEffect(() => {
//     const fetchApplications = async () => {
//       try {
//         const response = await axios.get('http://localhost:5001/applications');
//         setApplications(response.data);
//       } catch (error) {
//         setError('Failed to fetch applications');
//       }
//     };

//     fetchApplications();
//   }, []);

//   const handleSearch = (e) => {
//     setSearchTerm(e.target.value.toLowerCase());
//   };

//   const filteredApplications = applications.filter((app) =>
//     app.name.toLowerCase().includes(searchTerm)
//   );

//   return (
//     <Container fluid className="mt-4">
//       {error && <Alert variant="danger">{error}</Alert>}
      
//       <Row>
//         <Col>
//           <h2 className="text-center text-primary">Manage Job Applications</h2>
//         </Col>
//       </Row>

//       <Row className="mb-3">
//         <Col md={6}>
//           <Form inline="true">
//             <FormControl
//               type="text"
//               placeholder="Search by Applicant Name"
//               className="mr-sm-2"
//               onChange={handleSearch}
//             />
//           </Form>
//         </Col>
//       </Row>

//       <Row>
//         <Col>
//           <Card className="shadow-sm">
//             <Card.Body>
//               <Table striped bordered hover responsive>
//                 <thead>
//                   <tr>
//                     <th>Applicant Name</th>
//                     <th>Email</th>
//                     <th>Job Title</th>
//                     <th>Status</th>
//                     <th>Actions</th>
//                   </tr>
//                 </thead>
//                 <tbody>
//                   {filteredApplications.map((app) => (
//                     <tr key={app._id}>
//                       <td>{app.name}</td>
//                       <td>{app.email}</td>
//                       <td>{app.jobTitle}</td>
//                       <td>{app.status}</td>
//                       <td>
//                         <Button
//                           variant="primary"
//                           onClick={() => setSelectedApplication(app)}
//                         >
//                           View Details
//                         </Button>
//                       </td>
//                     </tr>
//                   ))}
//                 </tbody>
//               </Table>
//             </Card.Body>
//           </Card>
//         </Col>
//       </Row>

//       {selectedApplication && (
//         <JobApplicationDetail application={selectedApplication} />
//       )}
//     </Container>
//   );
// };

// export default JobApplicationsPage;
